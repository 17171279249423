import { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';

import { SET_PAGE, PAGES } from '../../../constants';
import SuggestionRecord from "./SuggestionRecord";
import DateSelection from "../other/DateSelection";
import ChartTrends from './ChartTrends';

function DashboardChiller() {

    const dispatch = useDispatch();

    const building = useSelector(root => root.data.building);
    const current_page = useSelector(root => root.core.page);

    useEffect(() => {
        if (current_page!== PAGES.MAIN_CH)
            dispatch({ type: SET_PAGE, page: PAGES.MAIN_CH });
    }, [])

    const renderContent = () => {
        return (
            <div className="py-2 px-0">
                <Row className='mb-3 mx-0'>
                    <Col lg={12}>
                        <DateSelection headerTitle='Chiller Plant'/>
                    </Col>
                </Row>
                <Row className='mx-0 d-none d-sm-none d-md-block'>
                    <Col lg={12}>
                        <SuggestionRecord platform={"web"} recordnum={8}/>
                    </Col>
                    <Col lg={12}>
                        <ChartTrends platform={"web"}/>
                    </Col>
                </Row>
                <Row className='mx-0 d-block d-sm-block d-md-none'>
                    <Col lg={12}>
                        <SuggestionRecord platform={"mobile"} recordnum={8}/>
                    </Col>
                    <Col lg={12}>
                        <ChartTrends platform={"mobile"}/>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div>
            {building && renderContent()}
            {!building && 
                <Container className='py-4'>
                    <Spinner animation="border" variant="light" size="md" />
                </Container>
            }
        </div>
    )
}

export default DashboardChiller;