import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import ApexCharts from 'apexcharts';
import { FaPercentage  as PercentIcon } from "react-icons/fa";

import "apexcharts/dist/apexcharts.css";

const  RadialBarChart = ({
    chartTitle = "",
    chartId = "",
    chartHeight = 200,
    chartMaxWidth = 80,
    divMaxHeight = 100,
    divMarginTop = 0,
    value=0,
    platform="web",
    color="black",
    colorTo="black",
    chartValueEnabled=false
}) => {

    const chartRef = useRef(null);

    const [chartObj, setChartObj] = useState(null);
    const [lastValue, setLastValue] = useState(null);

    useEffect(()=>{
        if (!value) value=0;
        if (chartRef.current.innerHTML === ""){
            renderChart();
        }
    },[])

    useEffect(()=>{
        if (lastValue !== value && lastValue !== null){
            if (chartObj !== null){
                destroyChart();
            } 
            renderChart();
        }
    },[value])

    const destroyChart = () => {
        chartObj.destroy();
        chartRef.current.innerHTML = "";
        setChartObj(null);
        setLastValue(0);
    }

    const renderChart = () => {
        let options,c = null;
        options = getChartOptions();
        c = new ApexCharts(document.querySelector("#"+chartId), options);
        c.render();
        setChartObj(c);
        setLastValue(value);
    }

    const getChartOptions = () => {
        
        let chartOptions = {
            series: [value],
            chart: {
                height: chartHeight,
                type: 'radialBar',
                offsetY: 0,
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -80,
                    endAngle: 80,
                    dataLabels: {
                        value: {
                            show: false,
                            offsetY: -18,
                            fontSize: '12px',
                            color: undefined,
                            formatter: function (val) {
                                return val + "%";
                            }
                        }
                    },
                    track: {
                        background: '#c7c4c2',
                        strokeWidth: '150%',
                        margin: 0, // margin is in pixels
                        show: true,
                    },
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                  shade: "dark",
                  type: "horizontal",
                  gradientToColors: [colorTo],
                  stops: [0, 100]
                }
            },
            stroke: {
                lineCap: "square"
            },
            colors: [color],
            labels: [''],
        };

        return chartOptions
    }

    return(
        <div style={{maxHeight: divMaxHeight+"px", marginTop: divMarginTop+"px", display: "inline-block"}}>
            {/* <div className='chart_title'>{chartTitle}</div> */}
            <div id={chartId} ref={chartRef} style={{maxWidth: chartMaxWidth+"px", position: "unset"}}></div>
            {
                chartValueEnabled &&
                <div style={{display: "block", fontSize: "x-small", marginTop: "-12px"}}>{value}<PercentIcon style={{"marginTop": "-2px"}} size={12}/></div>
            }
        </div>
    )
}

RadialBarChart.propTypes = {
    chartTitle: PropTypes.string.isRequired,
    chartId: PropTypes.string.isRequired,
    chartHeight: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    platform: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
};

export default RadialBarChart;