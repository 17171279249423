import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Modal, Button, ButtonGroup, ToggleButton} from "react-bootstrap";
import { Line } from "react-chartjs-2";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getCompareLineOption, setCompareLineColor } from "../../../utils/chartOptions";
import { axiosWithXAuth } from "../../../utils/functions";

const  TrendAdvance = ({
    showModal = false,
    setShowModal,
    title,
    yAxisLabel,
    Unit,
    apiUrl,
    name
}) => {

    const building = useSelector(root => root.data.building);

    let defaultEndDate = new Date();
    let defaultStartDate = new Date();
    defaultStartDate.setDate(defaultEndDate.getDate() -1);
    if (building.chart_from_dt!==null){
        defaultStartDate = new Date(building.chart_from_dt + " 00:00:00");
        defaultEndDate = new Date(defaultStartDate).setDate(new Date(defaultStartDate).getDate() + 1);
    }

    const btns_mode = [
        { name: 'CCMS', value: 'CCMS' },
        { name: 'AI Mode', value: 'AI' }
    ];

    const [data, setData] = useState([]);
    const [line1, setLine1] = useState([]);
    const [line2, setLine2] = useState([]);
    const [line3, setLine3] = useState([]);
    const [dateLabels, setDateLabels] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [avgCop, setAvgCop] = useState(0);
    const [avgAiCop, setAvgAiCop] = useState(0);
    const [avgBsCop, setAvgBsCop] = useState(0);
    const [avgAiPower, setAvgAiPower] = useState(0);
    const [avgBsPower, setAvgBsPower] = useState(0);
    const [maxDate, setMaxDate] = useState(null);
    const [currentMode, setCurrentMode] = useState(btns_mode[1].value);

    const handleClose = () => {
        setShowModal(false);
    }

    useEffect(() => {
        setDateRange([defaultStartDate, defaultEndDate]);
        let today = new Date();
        setMaxDate(today);
        setCurrentMode(btns_mode[1].value);
    }, [])

    useEffect(()=>{
        let start = null, end = null;
        if (startDate === null || endDate === null){
            let today = new Date();
            start = new Date().setDate(today.getDate() - 1);
            end = new Date().setDate(today.getDate());
        }
        else if (startDate !== null && endDate !== null){
            start = startDate;
            end = endDate;
        }
        let from_dt = moment(start).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(end).format("YYYY-MM-DD 23:59:59");

        if (showModal === true)
            callData(from_dt, to_dt);

    }, [dateRange])

    useEffect(() => {
        let from_dt = moment(startDate).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(endDate).format("YYYY-MM-DD 23:59:59");

        if (showModal === true)
            callData(from_dt, to_dt);
            
    }, [showModal])

    const changeMode = (chg_to_mode) => {
        let result = structuredClone(data);
        setCurrentMode(chg_to_mode);
        result = result.filter(e => e.mode === chg_to_mode);
        defineChartLines(result);
    }

    const callData = async (from_dt, to_dt) => {
        let data = {};
        if (from_dt && to_dt){
            data = {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_code": building.building_ibms_id.toLowerCase()
            }
        }
        else{
            return null;
        }

        await axiosWithXAuth({
            method: "post",
            url: apiUrl,
            data: data,
        })
            .then((response) => {
                let data = response.data;
                let result = []
                if (name==="aibs_cop_trend"){
                    data.forEach((element, index) => {
                        result.push({
                            "dt": element.dt,
                            "list1": element.ai_cop,
                            "list2": element.bs_cop,
                            "mode": "AI"
                        })
                    })
                    result = result.filter(e => e.list1 > 0 && e.list2 > 0 && e.list1 < 10 && e.list2 < 10);
                }
                else if (name==="aibs_power_trend"){
                    data.forEach((element, index) => {
                        result.push({
                            "dt": element.dt,
                            "list1": element.ai_power,
                            "list2": element.bs_power,
                            "mode": "AI"
                        })
                    })
                    result = result.filter(e => e.list1 > 0 && e.list2 > 0 && e.list1 < 500 && e.list2 < 500);
                }
                else if (name==="ap_cop_trend"){
                    data.forEach((element, index) => {
                        result.push({
                            "dt": element.dt,
                            "list1": element.predict_cop,
                            "list2": element.actual_cop,
                            "bs": element.bs_cop,
                            "mode": element.mode
                        })
                    })
                    result = result.filter(e => (e.mode==="AI" && e.list1 !== null && e.list2 != null && e.list1 < 10 && e.list2 < 10) || e.mode==="CCMS");
                }
                else if (name==="ap_tc_trend"){
                    data.forEach((element, index) => {
                        result.push({
                            "dt": element.dt,
                            "list1": element.predict_tc,
                            "list2": element.actual_tc,
                            "mode": element.mode
                        })
                    })
                    result = result.filter(e => (e.mode==="AI" && e.list1 !== null && e.list2 != null) || e.mode==="CCMS");
                }
                else if (name==="ap_power_trend"){
                    data.forEach((element, index) => {
                        result.push({
                            "dt": element.dt,
                            "list1": element.predict_power,
                            "list2": element.actual_power,
                            "bs": element.bs_power,
                            "mode": element.mode
                        })
                    })
                    result = result.filter(e => (e.mode==="AI" && e.list1 !== null && e.list2 != null) || e.mode==="CCMS");
                }

                setData(result);
                let filtered_result = result.filter(e => e.mode === currentMode);
                defineChartLines(filtered_result);
                
            })
    }

    const defineChartLines = (result) => {

        let list1 = result.map((element, index) => { return element["list1"]; });
        let list2 = result.map((element, index) => { return element["list2"]; });
        let data_labels = result.map((element, index) => { 
            let x = new Date(element["dt"]);
            // x = x.setHours(x.getHours() - 8);
            return moment(x).format('D-MMM h:mm a'); 
        });
        setLine1(list1);
        setLine2(list2);
        setDateLabels(data_labels);

        if (name==="ap_power_trend" || name==="ap_cop_trend"){
            let bs_list = result.map((element, index) => { return element["bs"]; });
            setLine3(bs_list);
        }
        if (name==="aibs_cop_trend"){
            if (list1.length>0){
                let total_ai_cop = list1.reduce((x, y) => {
                    return x + y;
                }, 10);
                setAvgAiCop(total_ai_cop/list1.length);
            }
            if (list1.length>0){
                let total_bs_cop = list2.reduce((x, y) => {
                    return x + y;
                }, 10);
                setAvgBsCop(total_bs_cop/list2.length);
            }                    
        }
        if (name==="aibs_power_trend"){
            if (list1.length>0){
                let total_ai_power = list1.reduce((x, y) => {
                    return x + y;
                    }, 1);
                setAvgAiPower(total_ai_power/list1.length);
            }
            if (list2.length>0){
                let total_bs_power = list2.reduce((x, y) => {
                    return x + y;
                    }, 1);
                setAvgBsPower(total_bs_power/list2.length);
            }
        }
    }

    const renderChart = () => {
        let datasets = [];
        if (name==="aibs_cop_trend" || name==="aibs_power_trend"){
            datasets = [
                {
                    label: "AI",
                    data: line1,
                },
                {
                    label: "Base Line",
                    data: line2,
                }
            ];
        }
        else if (name==="ap_tc_trend"){
            datasets = [
                {
                    label: "Predict",
                    data: line1,
                },
                {
                    label: "Actual",
                    data: line2,
                }
            ];
        }
        else if (name==="ap_power_trend" || name==="ap_cop_trend"){
            datasets = [
                {
                    label: "Predict",
                    data: line1,
                },
                {
                    label: "Actual",
                    data: line2,
                },
                {
                    label: "Baseline",
                    data: line3,
                }
            ];
        }

        return (
            <Line
                className='line_chart'
                data={{
                    labels: dateLabels,
                    datasets: setCompareLineColor(datasets)
                }}
                options={getCompareLineOption(yAxisLabel, Unit, "", true)}
            />
        )
    }

    const renderInputGroups = () => {
        return (
            <Row style={{float: "right", display:"contents"}}>
                <div className="mb-3 input-group input-group-sm px-0" style={{width: "auto"}}>
                    <span className="input-group-text" id="inputGroup-sizing-sm">Select Date Range</span>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        // isClearable={true}
                        withPortal
                        className='form-control date_range_input'
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        placeholderText="YYYY-MM-DD - YYYY-MM-DD" 
                        dateFormat="yyyy-MM-dd"
                        maxDate={maxDate}
                    />
                </div>
            </Row>
        )
    }

    const renderModeBtns = (chartname) => {
        return (
            <Row style={{float: "right", display:"contents"}}>
                <div className="mb-3 input-group input-group-sm px-0" style={{width: "auto"}}>
                    <span className="input-group-text" id="inputGroup-sizing-sm">Select Mode</span>
                    <ButtonGroup style={{float: "right"}}>
                        {btns_mode.map((radio, idx) => (
                            <ToggleButton
                                key={`radio-${chartname}-mode-${idx}`}
                                id={`radio-${chartname}-mode-${idx}`}
                                size="sm"
                                type="radio"
                                variant={idx % 2 ? 'outline-success' : 'outline-warning'}
                                name={`radio-${chartname}-mode`}
                                value={radio.value}
                                checked={currentMode === radio.value}
                                style={{"cursor": "pointer"}}
                                onChange={(e) => changeMode(e.currentTarget.value)}
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </div>
            </Row>
        )
    }

    const renderAvgCop = () => {
        let avg_ai_cop = avgAiCop.toFixed(1);
        let avg_bs_cop = avgBsCop.toFixed(1);
        return (
            <Row style={{float: "right", display:"contents"}}>
                <Row>
                    <Col>
                        Average AI COP:
                    </Col>
                    <Col md={6} lg={6}>
                        {avg_ai_cop}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Average BS COP:
                    </Col>
                    <Col md={6} lg={6}>
                        {avg_bs_cop}
                    </Col>
                </Row>
            </Row>
        )
    }

    const renderAvgPower = () => {
        let avg_ai_power = avgAiPower.toFixed(1);
        let avg_bs_power = avgBsPower.toFixed(1);
        return (
            <Row style={{float: "right", display:"contents"}}>
                <Row>
                    <Col>
                        Average AI Power:
                    </Col>
                    <Col md={4} lg={4}>
                        {avg_ai_power} kWh
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Average Base Line Power:
                    </Col>
                    <Col md={4} lg={4}>
                        {avg_bs_power} kWh
                    </Col>
                </Row>
            </Row>
        )
    }

    const renderContent = () =>{
        return (
            <Container className='py-1'>
                <div className='line_chart_wrap_advance'>
                    <div style={{float: "right"}}>
                    {renderInputGroups()}
                    {(name==="ap_tc_trend"||name==="ap_power_trend"||name==="ap_cop_trend") && renderModeBtns(name)}
                    {name==="aibs_cop_trend" && renderAvgCop()}
                    {name==="aibs_power_trend" && renderAvgPower()}
                    </div>
                    {renderChart()}
                </div>
            </Container>
        )
    }

    return (
        <Modal size="xl" fullscreen="lg-down" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderContent()}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

TrendAdvance.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    yAxisLabel: PropTypes.string.isRequired,
    Unit: PropTypes.string.isRequired,
    apiUrl: PropTypes.string.isRequired
};

export default TrendAdvance;