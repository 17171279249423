import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Container, Dropdown, Row, Col, Placeholder, Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";

import { getCompareLineOption, setCompareLineColor_sm } from "../../../utils/chartOptions";
import { CustomToggleMore } from "../../../utils/functions";

import TrendAdvance from './TrendAdvance';

const  SmallLineChart = ({
    title,
    titlePeriod = "",
    yAxisLabel,
    unit,
    line1=[],
    line2=[],
    line3=[],
    dateLabels=[],
    apiUrl,
    chartName,
    chartTotal,
    isRenderGlow,
    hasMore,
}) => {
    const [showChartAdvanceModal, setShowChartAdvanceModal] = useState(false);

    useEffect(() => {
        
    }, [])

    const renderChart = () => {
        let datasets = [];
        if (chartName==="aibs_cop_trend" || chartName==="aibs_power_trend"){
            datasets = [
                {
                    label: "AI",
                    data: line1,
                },
                {
                    label: "Base Line",
                    data: line2,
                }
            ];
        }
        else if (chartName === 'ap_tc_trend'){
            datasets = [
                {
                    label: "Predict",
                    data: line1,
                },
                {
                    label: "Actual",
                    data: line2,
                }
            ];
        }
        else if (chartName === 'ap_power_trend' || chartName === 'ap_cop_trend'){
            datasets = [
                {
                    label: "Predict",
                    data: line1,
                },
                {
                    label: "Actual",
                    data: line2,
                },
                {
                    label: "Baseline",
                    data: line3,
                }
            ];
        }

        return (
            <Line 
                className='line_chart'
                data={{
                    labels: dateLabels,
                    datasets: setCompareLineColor_sm(datasets)
                }}
                options={getCompareLineOption(unit, unit)}
            />
        )
    }

    const renderHide = () => {
        return (
            <Container>
                <Row className="px-2 my-3" style={{ "width": "100%" }}>
                    <Placeholder animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                </Row>
                <Row className="px-2 my-3" style={{ "width": "100%" }}>
                    <Placeholder animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                </Row>
                <Row className="px-2 my-3" style={{ "width": "100%" }}>
                    <Placeholder animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                </Row>
            </Container>
        )
    }

    const renderChartWrap = () => {
        if (!isRenderGlow)
            return renderChart();
        else 
            return renderHide();
    }

    return (
        <Container className="card_bg mt-2 mb-3 py-3">
            <div className='card_title_wrap py-2'>
                <h5 className='mb-0'><b>{title}</b></h5>
                <div style={{fontSize: "small", position: "absolute", width: "100%"}}>{titlePeriod}</div>
                {
                    hasMore &&
                    <Dropdown className='btn_show_more d-none d-sm-none d-md-block'>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" as={CustomToggleMore} >
                            Custom Toggle
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={{md: "start"}}>
                            <Dropdown.Item eventKey="1" onClick={()=>setShowChartAdvanceModal(true)}>Show More</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                }
                
            </div>
            {
                (chartTotal===3)? 
                <div className='line_chart_wrap ap_chart'>
                    {renderChartWrap()}
                </div>
                :
                <div className='line_chart_wrap'>
                    {renderChartWrap()}
                </div>
            }
            <Row className='d-sm-block d-md-none'>
                <Col>
                    <Button variant="secondary" onClick={()=>setShowChartAdvanceModal(true)}>Show More</Button>{' '}
                </Col>
            </Row>
            <TrendAdvance
                showModal={showChartAdvanceModal}
                setShowModal={setShowChartAdvanceModal}
                title={title}
                apiUrl={apiUrl}
                yAxisLabel={yAxisLabel}
                Unit={unit}
                name={chartName}
            />

        </Container>
    )
}

SmallLineChart.propTypes = {
    title: PropTypes.string.isRequired,
    yAxisLabel: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    line1: PropTypes.array.isRequired,
    line2: PropTypes.array.isRequired,
    dateLabels: PropTypes.array.isRequired,
    apiUrl: PropTypes.string.isRequired,
    chartName: PropTypes.string.isRequired,
    isRenderGlow: PropTypes.bool.isRequired,
    hasMore: PropTypes.bool.isRequired,
};

export default SmallLineChart;