import React,{ useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Table, Button, ProgressBar } from "react-bootstrap";
import moment from "moment";

import {
    TbSquareRoundedNumber1Filled as SeqIcon1,
    TbSquareRoundedNumber2Filled as SeqIcon2,
    TbSquareRoundedNumber3Filled as SeqIcon3,
    TbSquareRoundedNumber4Filled as SeqIcon4,
    TbSquareRoundedNumber5Filled as SeqIcon5,
    TbSquareRoundedNumber6Filled as SeqIcon6,
    TbSquareRoundedNumber7Filled as SeqIcon7,
    TbTemperatureCelsius as CelsiusIcon
} from "react-icons/tb";
import { FaTemperatureHalf as TempIcon} from "react-icons/fa6";
import { BsSpeedometer as SpeedIcon, BsThermometerSnow as FreeCoolIcon } from "react-icons/bs";
import { GiBrain as AIIcon } from "react-icons/gi";

import { API_URL, HARD_CODE_DATA, IS_HOSPITAL } from "../../../utils/variables";
import { axiosWithXAuth, RenderHide } from "../../../utils/functions";
import OptSequenceHistory from '../modal/OptSequenceHistory';
import OptDiagnosis from '../modal/OptDiagnosis';
import AlarmRecord from '../modal/AlarmRecord';
import PaginationComponent from '../../components/pagination/Pagination';

function SuggestionRecord({platform, recordnum}) {   

    const building = useSelector(root => root.data.building);
    const dt_range_from = useSelector(root => root.core.dt_range_from);
    const dt_range_to = useSelector(root => root.core.dt_range_to);
    
    const [loading, setLoading] = useState(true);
    const [showDiagnosisModal, setShowDiagnosisModal] = useState(false);
    const [showOptHistoryModal, setShowOptHistoryModal] = useState(false);
    const [showAlarmModal, setShowAlarmModal] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = recordnum;
    let smallfont = platform==="web"?"":"small_font";

    useEffect(() => {
        setPage(1);
        setLoading(true);
    }, [])

    useEffect(() => {
        setLoading(true);
        setPage(1);
        callOptSuggestions();
    }, [dt_range_from, dt_range_to])

    const callOptSuggestions = async () => {
        if (typeof dt_range_from !== 'string' || typeof dt_range_to !== 'string' || dt_range_from === "Invalid date" || dt_range_to === "Invalid date")
            return;
        
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/optSuggestions`,
            data: {
                "b_id": building.building_id,
                "from_dt": dt_range_from,
                "to_dt": dt_range_to,
                "b_code": building.building_ibms_id.toLowerCase()
                // "limit": 10
            }
        })
            .then((response) => {
                let data = response.data;
                let result = []
                data.forEach((element, index) => {
                    result.push({
                        "cdt": element.cdt,
                        "opt_demand": element.opt_demand,
                        "opt_st": element.opt_st,
                        "opt_pump_spd": element.opt_pump_spd,
                        "opt_sequence_list": element.opt_sequence_list,
                        "opt_pred_outtemp": element.opt_pred_outtemp,
                        "opt_pred_active_power": element.opt_pred_active_power,
                        "opt_pred_kwh": element.opt_pred_kwh,
                        "opt_pred_cop": element.opt_pred_cop,
                        "mode": element.mode,
                        "actual_power": element.actual_power,
                        "actual_cop": element.actual_cop,
                        "actual_outtemp": element.actual_outtemp,
                        "is_free_cool": element.is_free_cool
                    })
                })
                setSuggestions(result);
                setLoading(false);
            })
    }

    const renderBubble = (name, isOn) => {
        let color = isOn ? "green" : "grey";
        let iconsize = platform==="web"&&building.chillers.length<=4?35:22;
        let icon = name === "1" ? <SeqIcon1 color={color} size={iconsize} /> :
            name === "2" ? <SeqIcon2 color={color} size={iconsize} /> :
            name === "3" ? <SeqIcon3 color={color} size={iconsize} /> :
            name === "4" ? <SeqIcon4 color={color} size={iconsize} /> :
            name === "5" ? <SeqIcon5 color={color} size={iconsize} /> :
            name === "6" ? <SeqIcon6 color={color} size={iconsize} /> :
            null;
        return (
            <span key={`bubble_${name}`}>
                {icon}
            </span>
        )
    }

    const renderBubbles = (sequence, demand) => {
        demand = parseInt(demand);
        return sequence.map((element, index) => {
            let name = element;
            let isOn = index < demand ? true : false;
            return renderBubble(name, isOn);
        })
    }

    const renderSuggestion = (suggest, index) => {
        let cdt = new Date(suggest.cdt);
        // cdt = cdt.setHours(cdt.getHours() - 8);
        let opt_dt = moment(cdt).format("DD/MM h:mm a");
        let temp_sp = suggest.opt_st !== null ? suggest.opt_st.toFixed(1): null;
        let speed_sp = suggest.opt_pump_spd !== null? suggest.opt_pump_spd.toFixed(0): null;
        let default_ch_list = [];
        if ((suggest.opt_sequence_list!==null && suggest.opt_sequence_list.length > 0) || building.rt_hardcode===0){
            default_ch_list = building.chillers.map((element, index) => (index+1).toString());
        }
        else{
            default_ch_list = HARD_CODE_DATA[building.building_id].chillers.map((element, index) => (index+1).toString());
        }
        let outtemp = suggest.opt_pred_outtemp !== null? suggest.opt_pred_outtemp.toFixed(1): null;
        let tt_power = suggest.opt_pred_active_power !== null? suggest.opt_pred_active_power.toFixed(1): null;
        let cop = suggest.opt_pred_cop !== null? suggest.opt_pred_cop.toFixed(2): null;
        let actual_power = suggest.actual_power !== null? suggest.actual_power.toFixed(1): null;
        let actual_cop = suggest.actual_cop !== null? suggest.actual_cop.toFixed(2): null;
        let actual_outtemp = suggest.actual_outtemp !== null? suggest.actual_outtemp.toFixed(1): null;

        let item_num = (page-1) * itemsPerPage + index + 1;
        return (
            <tr key={`opt_sug_${index}`}>
                <td>{item_num} 
                    {
                        suggest.mode==="AI" && 
                        <>&ensp;
                        <AIIcon size={16} color="#198754" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                    {
                        suggest.is_free_cool===1 && 
                        <>&ensp;
                        <FreeCoolIcon size={16} color="#0D6EFD" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                </td>
                <td className=''>{opt_dt}</td>
                <td className='px-0'>
                    {suggest.opt_demand===0 && <>{renderBubbles(default_ch_list, 0)}</>}
                    {suggest.opt_demand>0 && renderBubbles(suggest.opt_sequence_list, suggest.opt_demand)}
                </td>
                <td>
                    {platform==="web" && temp_sp && <><TempIcon color='#0D6EFD' style={{marginTop: "-3px"}}/> {temp_sp} </>}
                    {platform==="mobile" && temp_sp && <>{temp_sp} </>}
                </td>
                <td>
                    {platform==="web" && speed_sp && 
                        <>
                            {/* <SpeedIcon color='#034f84' style={{marginTop: "-3px"}}/> {speed_sp}  */}
                            <Row className="px-0">
                                <Col xs={12} sm={2} md={2} lg={3} xl={2} className="px-0"><SpeedIcon color='#034f84' /></Col>
                                <Col xs={10} sm={8} md={8} lg={8} xl={8} className="pt-1 px-0">
                                    <ProgressBar animated now={speed_sp} label={`${speed_sp}Hz`} min={0} max={50} />
                                </Col>
                            </Row>
                        </>
                    } 
                    {platform==="mobile" && speed_sp && <>{speed_sp} </>} 
                </td>
                {!IS_HOSPITAL && <td style={{"borderLeft": "1px solid #cdd0d4"}}>{outtemp} </td>}
                {!IS_HOSPITAL && <td>{tt_power}</td>}
                {!IS_HOSPITAL && <td>{cop}</td>}
                {!IS_HOSPITAL && <td style={{"borderLeft": "1px solid #cdd0d4"}}>{actual_outtemp}</td>}
                {!IS_HOSPITAL && <td>{actual_power}</td>}
                {!IS_HOSPITAL && <td>{actual_cop}</td>}
            </tr>
        )
    }

    const renderSuggestionWrap = () => {
        if (suggestions.length > 0){

            let from_item = page===1? 1: (page-1) * itemsPerPage + 1;
            let to_item = from_item + itemsPerPage - 1;
            let suggestions_in_page = suggestions.filter(
                (element, index) => { 
                    return index+1 >= from_item && index+1 <= to_item
                });

            return suggestions_in_page.map((element, index) => {
                return renderSuggestion(element, index);
            })
        }
    }

    const renderCardContent = () => {
        return (
            <div className='card_content'>
                <Table striped border="true" hover responsive variant="light" className={`mb-0 ${smallfont}`}>
                    <thead className='small_font'>
                        <tr>
                            <th rowSpan={2}>#</th>
                            <th rowSpan={2}>Date Time</th>
                            <th rowSpan={2}>
                                {platform==="web" && "Chiller Sequence"}
                                {platform==="mobile" && "CH Seq."}
                            </th>
                            <th rowSpan={2}>
                                {platform==="web" && "Supply Temp (°C)"}
                                {platform==="mobile" && "ST (°C)"}
                            </th>
                            <th rowSpan={2}>
                                {platform==="web" && "Pump Speed (Hz)"}
                                {platform==="mobile" && "Pump Spd (Hz)"}
                            </th>
                            {!IS_HOSPITAL && <th colSpan={3} style={{"textAlign": "center", "borderLeft": "1px solid #cdd0d4"}}>Prediction</th>}
                            {!IS_HOSPITAL && <th colSpan={3} style={{"textAlign": "center", "borderLeft": "1px solid #cdd0d4"}}>Actual</th>}
                        </tr>
                        {   !IS_HOSPITAL && 
                            <tr>
                                <th style={{"borderLeft": "1px solid #cdd0d4"}}>
                                    {platform==="web" && "Outdoor Temp (°C)"}
                                    {platform==="mobile" && "Out. Temp (°C)"}
                                </th>
                                <th>Total Power (kW)</th>
                                <th>COP</th>
                                <th style={{"borderLeft": "1px solid #cdd0d4"}}>
                                    {platform==="web" && "Outdoor Temp (°C)"}
                                    {platform==="mobile" && "Out. Temp (°C)"}
                                </th>
                                <th>Total Power (kW)</th>
                                <th>COP</th>
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {renderSuggestionWrap()}
                    </tbody>
                </Table>
                <div className="myPagination myPag-b d-flex justify-content-center mt-2">
                    {
                        <PaginationComponent
                            itemsCount={suggestions.length}
                            itemsPerPage={itemsPerPage}
                            currentPage={page}
                            setCurrentPage={setPage}
                            alwaysShown={true}
                        />
                    }
                </div>
            </div>
        )
    }

    const renderContent = () => {
        return (
            <div className='card_bg mt-2 px-0'>
                <div className='card_upper d-none d-sm-none d-md-block'>
                    <div className='title_text'>Optimized Control Suggestion</div>
                    {/* <Dropdown className='btn_show_more'>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" as={CustomToggleMore} >
                            Custom Toggle
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={{md: "start"}}>
                            <Dropdown.Item eventKey="1" onClick={()=>setShowOptHistoryModal(true)}>Show More</Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={()=>setShowDiagnosisModal(true)}>Diagnosis</Dropdown.Item>
                            <Dropdown.Item eventKey="3" onClick={()=>setShowAlarmModal(true)}>Alarm Record</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown > */}
                </div>
                
                {!loading && renderCardContent()}
                {loading && RenderHide("ch_suggest", 17, 10)}

                <div className='card_bottom'>
                    <AIIcon size={14} color="#198754" style={{"marginTop": "-3px"}}/> AI mode&ensp;
                    <FreeCoolIcon size={14} color="#0D6EFD" style={{"marginTop": "-3px"}}/> Free Cooling
                </div>
                
                <Row className='d-sm-block d-md-none pb-2'>
                    <Col>
                        <Button variant="secondary" onClick={()=>setShowOptHistoryModal(true)}>Control History</Button>{' '}
                    </Col>
                    <Col>
                        <Button variant="secondary" onClick={()=>setShowDiagnosisModal(true)}> Diagnosis</Button>{' '}
                    </Col>
                </Row>
                <OptSequenceHistory
                    showModal={showOptHistoryModal}
                    setShowModal={setShowOptHistoryModal}
                    platform={platform}
                    recordnum={recordnum}
                />
                <OptDiagnosis
                    showModal={showDiagnosisModal}
                    setShowModal={setShowDiagnosisModal}
                    platform={platform}
                    recordnum={recordnum}
                />
                <AlarmRecord
                    showModal={showAlarmModal}
                    setShowModal={setShowAlarmModal}
                />
            </div>
        )
    }

    return renderContent();
}

export default SuggestionRecord;