import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import ApexCharts from 'apexcharts';
import moment from "moment";

import "apexcharts/dist/apexcharts.css";

const  SyncingChart = ({
    chartTitle = "",
    chartId = "",
    chartHeight = 200,
    yAxisLabel = "",
    unit = "",
    series=[],
    platform="web",
    lineColor=['#EDC948','#59A14F','#0D6EFD'],
    chartKey="",
    yAxisLabel2=""
}) => {

    const chartRef = useRef(null);

    const [chartObj, setChartObj] = useState(null);
    const [lastSeries, setLastSeries] = useState([]);

    useEffect(()=>{
        let options,c = null;

        if (chartRef.current.innerHTML === "" && series.length > 0){
            options = getChartOptions(series);
            c = new ApexCharts(document.querySelector("#"+chartId), options);
            c.render();
            setChartObj(c);
            setLastSeries(series);
        }
        else if (series.length > 0 && JSON.stringify(series) !== JSON.stringify(lastSeries)){
            chartObj.destroy();
            options = getChartOptions(series);
            c = new ApexCharts(document.querySelector("#"+chartId), options);
            c.render();
            setChartObj(c);
            setLastSeries(series);
        }    

    },[series])

    const getChartMin = (update_series) => {
        let min = null;
        update_series.forEach((s) => {
            let data = s['data']===undefined||s['data']===null?0:s['data'];
            data.forEach((d)=>{
                if (min === null || d[1] < min){
                    min = d[1]
                }
            })
        })

        if (min>10){
            min = Math.floor(min/10)*10;
        }
        else if (min<10 && min>-10){
            min = Math.floor(min);
        }
        else{
            min = Math.floor(min/10)*10
        }
        
        return min;
    }

    const getChartMax = (update_series) => {
        let max = 0.02;
        update_series.forEach((s) => {
            let data = s['data']===undefined||s['data']===null?0:s['data'];
            data.forEach((d)=>{
                if (d[1] > max){
                    max = d[1]
                }
            })
        })

        if (max>10){
            max = Math.ceil(max/10)*10;
        }
        else if (max<=1 && max>=-1){
            max = Math.ceil(max*100)/100;
        }
        else if (max<10 && max>-10){
            max = Math.ceil(max);
        }
        else{
            max = Math.ceil(max);
        }

        return max;
    }

    const getChartOptions = (update_series) => {
        
        let chartOptions = {
            series: update_series,
            colors: lineColor,
            chart: {
                id: chartId,
                group: 'social',
                type: 'line',
                height: chartHeight,
                toolbar: {
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: []
                    },
                    export:{
                        csv: {
                            headerCategory: 'dt',
                            // format: 'yyyy-MM-dd HH:mm',
                            dateFormatter(timestamp) {
                                return moment(timestamp).format("YYYY-MM-DD HH:mm")
                            }
                        }
                    }
                },
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                  colors: ['#ECEDEE', 'transparent'],
                  opacity: 0.5
                },
            },
            yaxis: 
            // chartKey==="chart_fafr"?
            // [{
            //     min: Math.min(getChartMin([update_series[0]]), getChartMin([update_series[1]]), getChartMin([update_series[2]]) ),
            //     max: Math.max(getChartMax([update_series[0]]), getChartMax([update_series[1]]), getChartMax([update_series[2]]) ),
            //     title: {
            //         text: 'Flow Rate' + ' (' + yAxisLabel + ')'
            //       },
            //     labels: {
            //         minWidth: 40,
            //     },
            //     tickAmount: 4,
            //     forceNiceScale: true
            // },
            // {
            //     show: false,
            //     min: Math.min(getChartMin([update_series[0]]), getChartMin([update_series[1]]) ),
            //     max: Math.max(getChartMax([update_series[0]]), getChartMax([update_series[1]]) ),
            // },
            // {
            //     min: getChartMin([update_series[2]]),
            //     max: getChartMax([update_series[2]]),
            //     title: {
            //         text: update_series[2].name + ' (' + yAxisLabel2 + ')'
            //       },
            //     labels: {
            //         minWidth: 40,
            //     },
            //     tickAmount: 4,
            //     opposite: true,
            //     forceNiceScale: true
            // }
            // ]
            // :
            {
                min: getChartMin(update_series),
                max: getChartMax(update_series),
                title: {
                    text: yAxisLabel
                  },
                labels: {
                    minWidth: 40
                },
                tickAmount: 4,
                forceNiceScale: true
            },
            xaxis: {
                type: "datetime",
                labels: {
                    datetimeUTC: false,
                    format: 'd/M hTT'
                },
                tooltip: {
                    enabled: false,
                },
            },
            tooltip: {
                shared: true,
                hideEmptySeries: false,
                x: {
                    show: true,
                    format: 'yyyy-MM-dd HH:mm',
                    formatter: undefined,
                },
                marker: {
                    show: true,
                },
                fixed: {
                    enabled: true,
                    position: 'bottomRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            legend: {
                position: platform==="web"?'right':'bottom',
                markers: {
                    height: 7,
                }
            },
            markers: {
                size: 2,
                strokeWidth: 0,
                hover: {
                    size: 5,
                }
            }
        };

        return chartOptions
    }

    return(
        <div>
            <div className='chart_title'>{chartTitle}</div>
            <div id={chartId} ref={chartRef}></div>
        </div>
    )
}

SyncingChart.propTypes = {
    chartTitle: PropTypes.string.isRequired,
    chartId: PropTypes.string.isRequired,
    chartHeight: PropTypes.number.isRequired,
    yAxisLabel: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    series: PropTypes.array.isRequired,
    platform: PropTypes.string.isRequired
};

export default SyncingChart;