import { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import SuggestionRecord from "./SuggestionRecord";
import DateSelection from "../other/DateSelection";
import ChartTrends from './ChartTrends';
import { SET_AHU, SET_PAGE, PAGES } from "../../../constants";

function DashboardAhu() {

    const params = useParams();
    const dispatch = useDispatch();

    const building = useSelector(root => root.data.building);
    const current_page = useSelector(root => root.core.page);

    useEffect(() => {
        if (current_page!==PAGES.MAIN_AHU)
            dispatch({ type: SET_PAGE, page: PAGES.MAIN_AHU });
    }, [])

    useEffect(()=>{
        if (building && building.ahu_display_names.length > 0){
            let ahu_idx = building.ahu_display_names.indexOf(params['ahuName']);
            dispatch({ type: SET_AHU, ahu_choose: ahu_idx });
        }
    },[building])

    const renderContent = () => {
        return (
            <div className="py-2 px-0">
                <Row className='mb-3 mx-0'>
                    <Col lg={12}>
                        <DateSelection headerTitle={`Air Handling Unit - ${params['ahuName']}`}/>
                    </Col>
                </Row>
                <Row className='mx-0 d-none d-sm-none d-md-block'>
                    <Col lg={12}>
                        <SuggestionRecord platform={"web"} recordnum={5} ahuname={params['ahuName']}/>
                    </Col>
                    <Col lg={12}>
                        <ChartTrends platform={"web"} ahuname={params['ahuName']}/>
                    </Col>
                </Row>
                <Row className='mx-0 d-block d-sm-block d-md-none'>
                    <Col lg={12}>
                        <SuggestionRecord platform={"mobile"} recordnum={5} ahuname={params['ahuName']}/>
                    </Col>
                    <Col lg={12}>
                        <ChartTrends platform={"mobile"} ahuname={params['ahuName']}/>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div>
            {building && renderContent()}
            {!building && 
                <Container className='py-4'>
                    <Spinner animation="border" variant="light" size="md" />
                </Container>
            }
        </div>
    )
}

export default DashboardAhu;