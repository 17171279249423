import React,{ useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Table, Button, ProgressBar } from "react-bootstrap";
import moment from "moment";
import { FaTemperatureHalf as TempIcon} from "react-icons/fa6";
import { BsThermometerSnow as FreeCoolIcon } from "react-icons/bs";
import { GiBrain as AIIcon } from "react-icons/gi";

import { API_URL } from "../../../utils/variables";
import { axiosWithXAuth, RenderHide } from "../../../utils/functions";
import OptSequenceHistory from '../modal/OptSequenceHistory';
import OptDiagnosis from '../modal/OptDiagnosis';
import AlarmRecord from '../modal/AlarmRecord';
import PaginationComponent from '../../components/pagination/Pagination';
import RadialBarChart from '../chart/RadialBarChart';

function SuggestionRecord({platform, recordnum, ahuname}) {

    const building = useSelector(root => root.data.building);
    const dt_range_from = useSelector(root => root.core.dt_range_from);
    const dt_range_to = useSelector(root => root.core.dt_range_to);
    const ahu_choose = useSelector(root => root.core.ahu_choose);
    
    const [loading, setLoading] = useState(true);
    const [showDiagnosisModal, setShowDiagnosisModal] = useState(false);
    const [showOptHistoryModal, setShowOptHistoryModal] = useState(false);
    const [showAlarmModal, setShowAlarmModal] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = recordnum;
    let smallfont = platform==="web"?"":"small_font";

    useEffect(() => {
        setPage(1);
        setLoading(true);
        callOptSuggestions();
    }, [])

    useEffect(() => {
        setLoading(true);
        setPage(1);
        if (building){
            let ahu_idx = building.ahu_display_names.indexOf(ahuname)
            if (ahu_idx === ahu_choose){
                callOptSuggestions();
            }
        }
    }, [dt_range_from, dt_range_to, ahu_choose])

    const callOptSuggestions = async () => {
        if (typeof dt_range_from !== 'string' || typeof dt_range_to !== 'string' || dt_range_from === "Invalid date" || dt_range_to === "Invalid date")
            return;
        
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/optAhuSuggestions`,
            data: {
                "b_id": building.building_id,
                "from_dt": dt_range_from,
                "to_dt": dt_range_to,
                "ahu_name": building.ahus[ahu_choose]
                // "limit": 10
            }
        })
            .then((response) => {
                let data = response.data;
                let result = []
                data.forEach((element, index) => {
                    result.push({
                        "cdt": element.cdt,
                        "name": element.name,
                        "opt_ch_st": element.opt_ch_st,
                        "opt_sat": element.opt_sat,
                        "opt_fdamp": element.opt_fdamp,
                        "opt_rdamp": element.opt_rdamp,
                        "opt_vlv": element.opt_vlv,
                        "opt_spd": element.opt_spd,
                        "opt_pred_chwot": element.opt_pred_chwot,
                        "opt_co2_rate": element.opt_co2_rate,
                        "opt_sen_space": element.opt_sen_space,
                        "opt_tt_cl": element.opt_tt_cl,
                        "opt_tt_kw": element.opt_tt_kw,
                        "mode": element.mode,
                        "actual_power": element.actual_power,
                        "actual_co2_rate": element.actual_co2_rate,
                        "actual_sen_space": element.actual_sen_space,
                        "actual_tt_cl": element.actual_tt_cl,
                        "is_free_cool": element.is_free_cool
                    })
                })

                setSuggestions(result);
                setLoading(false);
            })
    }

    const renderSuggestion = (suggest, index) => {
        let cdt = new Date(suggest.cdt);
        let opt_dt = moment(cdt).format("DD/MM h:mm a");
        let name = suggest.name.slice(0, 2);

        let sat = suggest.opt_sat !== null ? suggest.opt_sat.toFixed(1): null;
        let fdamp = suggest.opt_fdamp !== null? parseFloat(suggest.opt_fdamp.toFixed(0)): null;
        let rdamp = suggest.opt_rdamp !== null? parseFloat(suggest.opt_rdamp.toFixed(0)): null;
        let vlv = suggest.opt_vlv !== null? parseFloat(suggest.opt_vlv.toFixed(0)): null;
        let spd = suggest.opt_spd !== null? suggest.opt_spd.toFixed(0): null;
        let pre_co2_rate = suggest.opt_co2_rate !== null? suggest.opt_co2_rate.toFixed(4): null;
        let pre_sen_space = suggest.opt_sen_space !== null? suggest.opt_sen_space.toFixed(2): null;
        let pre_tt_cl = suggest.opt_tt_cl !== null? suggest.opt_tt_cl.toFixed(2): null;
        let pre_power = suggest.opt_tt_kw !== null? suggest.opt_tt_kw.toFixed(2): null;
        let actual_power = suggest.actual_power !== null? suggest.actual_power.toFixed(2): null;
        let actual_co2_rate = suggest.actual_co2_rate !== null? suggest.actual_co2_rate.toFixed(4): null;
        let actual_sen_space = suggest.actual_sen_space !== null? suggest.actual_sen_space.toFixed(2): null;
        let actual_tt_cl = suggest.actual_tt_cl !== null? suggest.actual_tt_cl.toFixed(2): null;

        let item_num = (page-1) * itemsPerPage + index + 1;
        return (
            <tr key={`ahu_sug_${index}`}>
                <td>{item_num}
                    {
                        suggest.mode==="AI" && 
                        <>&ensp;
                        <AIIcon size={16} color="#198754" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                    {
                        suggest.is_free_cool===1 && 
                        <>&ensp;
                        <FreeCoolIcon size={16} color="#0D6EFD" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                </td>
                <td>{opt_dt}</td>
                {/* <td>{name}</td> */}
                <td>{sat && <><TempIcon color='#0D6EFD' style={{marginTop: "-3px"}} /> {sat}</>}</td>
                <td className='px-0 py-0'>
                    <RadialBarChart 
                        chartTitle='RA Damper'
                        chartId={`rdamp_${index}_${platform}`}
                        chartHeight={110}
                        divMaxHeight={150}
                        divMarginTop={-10}
                        value={rdamp}
                        platform={platform}
                        color="#008000"
                        colorTo="#006b00"
                        chartValueEnabled={true}
                    />
                </td>
                <td className='px-0 py-0'>
                    <RadialBarChart 
                        chartTitle='FA Damper'
                        chartId={`fdamp_${index}_${platform}`}
                        chartHeight={110}
                        divMaxHeight={150}
                        divMarginTop={-10}
                        value={fdamp}
                        platform={platform}
                        color="#e65c00"
                        colorTo="#c75000"
                        chartValueEnabled={true}
                    />
                </td>
                <td className='px-0 py-0'>
                    <RadialBarChart 
                        chartTitle='Valve'
                        chartId={`vlv_${index}_${platform}`}
                        chartHeight={110}
                        divMaxHeight={150}
                        divMarginTop={-10}
                        value={vlv}
                        platform={platform}
                        color="#035996"
                        colorTo="#023e69"
                        chartValueEnabled={true}
                    />
                </td>
                <td>
                    <Row className="px-0">
                        {/* <Col xs={12} sm={2} md={2} lg={3} xl={2} className="px-0"><FanIcon color='#034f84' /></Col> */}
                        <Col xs={10} sm={10} md={10} lg={10} xl={10} className="pt-1 px-0 text-center">
                            <ProgressBar animated now={spd} label={`${spd}Hz`} min={0} max={50} />
                        </Col>
                    </Row>
                
                </td>
                <td style={{"borderLeft": "1px solid #cdd0d4"}}>{pre_co2_rate} </td>
                <td>{pre_power}</td>
                <td>{pre_tt_cl}</td>
                <td>{pre_sen_space}</td>
                <td style={{"borderLeft": "1px solid #cdd0d4"}}>{actual_co2_rate} </td>
                <td>{actual_power}</td>
                <td>{actual_tt_cl}</td>
                <td>{actual_sen_space}</td>
            </tr>
        )
    }

    const renderSuggestionWrap = () => {
        if (suggestions.length > 0){

            let from_item = page===1? 1: (page-1) * itemsPerPage + 1;
            let to_item = from_item + itemsPerPage - 1;
            let suggestions_in_page = suggestions.filter(
                (element, index) => { 
                    return index+1 >= from_item && index+1 <= to_item
                });

            return suggestions_in_page.map((element, index) => {
                return renderSuggestion(element, index);
            })
        }
    }

    const renderCardContent = () => {
        return (
            <div className='card_content'>
                <Table striped border="true" hover responsive variant="light" className={`mb-0 ${smallfont}`} style={{overflow: "hidden"}}>
                    <thead className='small_font'>
                        <tr>
                            <th rowSpan={2}>#</th>
                            <th rowSpan={2}>Date Time</th>
                            {/* <th rowSpan={2}>Floor</th> */}
                            <th rowSpan={2}>Supply Air Temp (°C)</th>
                            <th rowSpan={2}>Return Air Damper (%)</th>
                            <th rowSpan={2}>Fresh Air Damper (%)</th>
                            <th rowSpan={2}>CHW Valve (%)</th>
                            <th rowSpan={2}>Fan Speed (Hz)</th>
                            <th colSpan={4} style={{"textAlign": "center", "borderLeft": "1px solid #cdd0d4"}}>Prediction</th>
                            <th colSpan={4} style={{"textAlign": "center", "borderLeft": "1px solid #cdd0d4"}}>Actual</th>
                        </tr>
                        <tr>
                            <th style={{"borderLeft": "1px solid #cdd0d4"}}>CO<sub>2</sub> Generation Rate (L/s)</th>
                            <th>Total Power (kW)</th>
                            <th>Total Coil Cooling Demand (kW)</th>
                            <th>Sensible Space Cooling Demand (kW)</th>
                            <th style={{"borderLeft": "1px solid #cdd0d4"}}>CO<sub>2</sub> Generation Rate (L/s)</th>
                            <th>Total Power (kW)</th>
                            <th>Total Coil Cooling Demand (kW)</th>
                            <th>Sensible Space Cooling Demand (kW)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderSuggestionWrap()}
                    </tbody>
                </Table>
                <div className="myPagination myPag-b d-flex justify-content-center mt-2">
                    {
                        <PaginationComponent
                            itemsCount={suggestions.length}
                            itemsPerPage={itemsPerPage}
                            currentPage={page}
                            setCurrentPage={setPage}
                            alwaysShown={true}
                        />
                    }
                </div>
            </div>
        )
    }

    const renderContent = () => {
        return (
            <div className='card_bg mt-2 px-0'>
                <div className='card_upper d-none d-sm-none d-md-block'>
                    <div className='title_text'>Optimized Control Suggestion</div>
                    {/* <Dropdown className='btn_show_more'>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" as={CustomToggleMore} >
                            Custom Toggle
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={{md: "start"}}>
                            <Dropdown.Item eventKey="1" onClick={()=>setShowOptHistoryModal(true)}>Show More</Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={()=>setShowDiagnosisModal(true)}>Diagnosis</Dropdown.Item>
                            <Dropdown.Item eventKey="3" onClick={()=>setShowAlarmModal(true)}>Alarm Record</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown > */}
                </div>
                
                {!loading && renderCardContent()}
                {loading && RenderHide("ch_suggest", 17, 10)}

                <div className='card_bottom'>
                    <AIIcon size={14} color="#198754" style={{"marginTop": "-3px"}}/> AI mode&ensp;
                    <FreeCoolIcon size={14} color="#0D6EFD" style={{"marginTop": "-3px"}}/> Free Cooling
                </div>
                
                <Row className='d-sm-block d-md-none pb-2'>
                    <Col>
                        <Button variant="secondary" onClick={()=>setShowOptHistoryModal(true)}>Control History</Button>{' '}
                    </Col>
                    <Col>
                        <Button variant="secondary" onClick={()=>setShowDiagnosisModal(true)}> Diagnosis</Button>{' '}
                    </Col>
                </Row>
                <OptSequenceHistory
                    showModal={showOptHistoryModal}
                    setShowModal={setShowOptHistoryModal}
                    platform={platform}
                    recordnum={recordnum}
                />
                <OptDiagnosis
                    showModal={showDiagnosisModal}
                    setShowModal={setShowDiagnosisModal}
                    platform={platform}
                    recordnum={recordnum}
                />
                <AlarmRecord
                    showModal={showAlarmModal}
                    setShowModal={setShowAlarmModal}
                />
            </div>
        )
    }

    return renderContent();
}

export default SuggestionRecord;