import { useState, useEffect } from 'react';
import { Container, ButtonGroup, ToggleButton } from "react-bootstrap";
import { useSelector } from 'react-redux';

import { axiosWithXAuth } from "../../../utils/functions";
import { API_URL } from "../../../utils/variables"
import SyncingChart from '../chart/SyncingChart';

function ChartTrends({platform, ahuname}) {

    const building = useSelector(root => root.data.building);
    const dt_range_from = useSelector(root => root.core.dt_range_from);
    const dt_range_to = useSelector(root => root.core.dt_range_to);
    const ahu_choose = useSelector(root => root.core.ahu_choose);

    const btns_mode = [
        { name: 'CCMS', value: 'CCMS' },
        { name: 'AI Mode', value: 'AI' }
    ];

    const [dataList, setDataList] = useState([]);

    const [chartSeriesCO2, setChartSeriesCO2] = useState([]);
    const [chartSeriesKW, setChartSeriesKW] = useState([]);
    const [chartSeriesSPACE, setChartSeriesSPACE] = useState([]);
    const [chartSeriesTTCL, setChartSeriesTTCL] = useState([]);
    const [chartSeriesVent, setChartSeriesVent] = useState([]);
    const [chartSeriesSafr, setChartSeriesSafr] = useState([]);
    const [chartSeriesFafr, setChartSeriesFafr] = useState([]);

    const [currentMode, setCurrentMode] = useState(btns_mode[1].value);
    

    useEffect(()=>{
        if (dt_range_to!=='Invalid date' && building){
            let ahu_idx = building.ahu_display_names.indexOf(ahuname)
            if (ahu_idx === ahu_choose){
                callData(dt_range_from, dt_range_to);
            }
        }
    }, [dt_range_from, dt_range_to, ahu_choose])

    const callData = async (from_dt, to_dt) => {

        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/getAhuCompare`,
            data: {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_id": building.building_id,
                "ahu_name": building.ahus[ahu_choose]
            },
        })
            .then((response) => {
                let data = response.data;
                setDataList(data);
                massageData(data, currentMode);
            })
    }

    const massageData = (data_o, filter_mode) => {
        let data=[];

        data_o.forEach((element, index) => {
            data.push({
                "dt": element.dt,
                "bs_power": element.bs_power,
                "bs_fafwr": element.bs_fafwr===null?0:element.bs_fafwr,
                "bs_safwr": element.bs_safwr===null?0:element.bs_safwr,
                "bs_vent": element.bs_vent===null?0:element.bs_vent,
                "actual_power": element.actual_power,
                "actual_co2_rate": element.actual_co2_rate,
                "actual_sen_space": element.actual_sen_space,
                "actual_tt_cl": element.actual_tt_cl,
                "actual_safwr": element.actual_safwr,
                "actual_fafwr": element.actual_fafwr,
                "actual_fads": element.actual_fads,
                "actual_vent": element.actual_vent,
                "predict_power": element.predict_power,
                "predict_co2_rate": element.predict_co2_rate,
                "predict_sen_space": element.predict_sen_space,
                "predict_tt_cl": element.predict_tt_cl,
                "mode": element.mode,
                "predict_safwr": element.predict_safwr,
                "predict_fafwr": element.predict_fafwr
            })
        })
        data = data.filter(e => e.mode === filter_mode);
        data = data.filter(e => 
            e.actual_power !== null
            && e.actual_co2_rate !== null
            && e.actual_sen_space !== null
            && e.actual_tt_cl !== null
            && (
                filter_mode === "CCMS"
                || (
                    filter_mode === "AI" 
                    && e.predict_power !== null
                    && e.predict_co2_rate !== null
                    && e.predict_sen_space !== null
                    && e.predict_tt_cl !== null
                    )
            )
        );

        let sycing_co2_series = [
            {
                name: "Predict",
                data: data.map((element, index) => { 
                        return [element["dt"], element["predict_co2_rate"]]; 
                    })
            },
            {
                name: "Actual",
                data: data.map((element, index) => { 
                        return [element["dt"], element["actual_co2_rate"]]; 
                    })
            }
        ];
        let sycing_power_series = [
            {
                name: "Predict",
                data: data.map((element, index) => { 
                        return [element["dt"], element["predict_power"]]; 
                    })
            },
            {
                name: "Actual",
                data: data.map((element, index) => { 
                        return [element["dt"], element["actual_power"]]; 
                    })
            },
            {
                name: "Baseline",
                data: data.map((element, index) => { 
                        return [element["dt"], element["bs_power"]]; 
                    })
            }
        ];
        let sycing_sen_space_series = [
            {
                name: "Predict",
                data: data.map((element, index) => { 
                        return [element["dt"], element["predict_sen_space"]]; 
                    })
            },
            {
                name: "Actual",
                data: data.map((element, index) => { 
                        return [element["dt"], element["actual_sen_space"]]; 
                    })
            }
        ];
        let sycing_tt_cl_series = [
            {
                name: "Predict",
                data: data.map((element, index) => { 
                        return [element["dt"], element["predict_tt_cl"]]; 
                    })
            },
            {
                name: "Actual",
                data: data.map((element, index) => { 
                        return [element["dt"], element["actual_tt_cl"]]; 
                    })
            }
        ];
        let sycing_vent_series = [
            {
                name: "Actual",
                data: data.map((element, index) => { 
                        return [element["dt"], element["actual_vent"]]; 
                    })
            },
            {
                name: "Baseline",
                data: data.map((element, index) => { 
                        return [element["dt"], element["bs_vent"]]; 
                    })
            }
        ];
        let sycing_safr_series = [
            // {
            //     name: "Predict",
            //     data: data.map((element, index) => { 
            //             return [element["dt"], element["predict_safwr"]]; 
            //         })
            // },
            {
                name: "Actual",
                data: data.map((element, index) => { 
                        return [element["dt"], element["actual_safwr"]]; 
                    })
            },
            {
                name: "Baseline",
                data: data.map((element, index) => { 
                        return [element["dt"], element["bs_safwr"]]; 
                    })
            }
        ];

        let sycing_fafr_series = [
            {
                name: "Actual",
                data: data.map((element, index) => { 
                        return [element["dt"], element["actual_fafwr"]]; 
                    })
            },
            {
                name: "Baseline",
                data: data.map((element, index) => { 
                        return [element["dt"], element["bs_fafwr"]]; 
                    })
            },
            // {
            //     name: "Actual damper position",
            //     data: data.map((element, index) => { 
            //             return [element["dt"], element["actual_fads"]]; 
            //         })
            // }
        ];

        setChartSeriesCO2(sycing_co2_series);
        setChartSeriesKW(sycing_power_series);
        setChartSeriesSPACE(sycing_sen_space_series);
        setChartSeriesTTCL(sycing_tt_cl_series);
        setChartSeriesVent(sycing_vent_series);
        setChartSeriesSafr(sycing_safr_series);
        setChartSeriesFafr(sycing_fafr_series);
    }

    const renderModeBtns = (chartname) => {
        return (
            <div style={{float: "right", marginTop: "-1.5rem"}}>
                <div className="mb-3 input-group input-group-sm px-0" style={{width: "auto"}}>
                    <span className="input-group-text" id="inputGroup-sizing-sm">Select Mode</span>
                    <ButtonGroup style={{float: "right"}}>
                        {btns_mode.map((radio, idx) => (
                            <ToggleButton
                                key={`radio-${chartname}-${platform}-mode-${idx}`}
                                id={`radio-${chartname}-${platform}-mode-${idx}`}
                                size="sm"
                                type="radio"
                                variant={idx % 2 ? 'outline-success' : 'outline-warning'}
                                name={`radio-${chartname}-${platform}-mode`}
                                value={radio.value}
                                checked={currentMode === radio.value}
                                style={{"cursor": "pointer"}}
                                onChange={(e) => changeMode(e.currentTarget.value)}
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </div>
            </div>
        )
    }

    const changeMode = (chg_to_mode) => {
        let result = structuredClone(dataList);
        setCurrentMode(chg_to_mode);
        massageData(result, chg_to_mode);
    }

    const renderContent = () => {

        return (
            <div className='card_bg mt-2 px-0'>
                <div className='card_upper d-none d-sm-none d-md-block'>
                    <div className='title_text'>Chart</div>
                    {renderModeBtns("ahu_page")}
                </div>

                <div className='card_content'>
                    <SyncingChart
                        chartTitle='Total Coil Cooling Demand'
                        chartId={`chart_ahu_coil_${ahu_choose}_${platform}`}
                        chartHeight={250}
                        yAxisLabel='kW'
                        unit='kW'
                        series={chartSeriesTTCL}
                        platform={platform}
                    />
                    <SyncingChart
                        chartTitle='Sensible Space Cooling Demand'
                        chartId={`chart_ahu_space_${ahu_choose}_${platform}`}
                        chartHeight={250}
                        yAxisLabel='kW'
                        unit='kW'
                        series={chartSeriesSPACE}
                        platform={platform}
                    />
                    <SyncingChart
                        chartTitle='Ventilation Load'
                        chartId={`chart_ahu_vent_${ahu_choose}_${platform}`}
                        chartHeight={250}
                        yAxisLabel='kW'
                        unit='kW'
                        series={chartSeriesVent}
                        platform={platform}
                        lineColor={['#59A14F','#0D6EFD']}
                    />
                    <SyncingChart
                        chartTitle='CO2 Generation Rate'
                        chartId={`chart_ahu_co2_${ahu_choose}_${platform}`}
                        chartHeight={250}
                        yAxisLabel='L/s'
                        unit='L/s'
                        series={chartSeriesCO2}
                        platform={platform}
                    />
                    <SyncingChart                        
                        chartTitle='Fresh Air Flow Rate'
                        chartId={`chart_ahu_fafr_${ahu_choose}_${platform}`}
                        chartHeight={250}
                        yAxisLabel='L/s'
                        unit='L/s'
                        series={chartSeriesFafr}
                        lineColor={['#59A14F','#0D6EFD','#e65c00']}
                        platform={platform}
                        chartKey='chart_fafr'
                        yAxisLabel2='%'
                    /> 
                    <SyncingChart
                        chartTitle='Supply Air Flow Rate'
                        chartId={`chart_ahu_safr_${ahu_choose}_${platform}`}
                        chartHeight={250}
                        yAxisLabel='L/s'
                        unit='L/s'
                        series={chartSeriesSafr}
                        platform={platform}
                        lineColor={['#59A14F','#0D6EFD']}
                    />        
                    <SyncingChart
                        chartTitle='Power Consumption'
                        chartId={`chart_ahu_kw_${ahu_choose}_${platform}`}
                        chartHeight={250}
                        yAxisLabel='kW'
                        unit='kW'
                        series={chartSeriesKW}
                        platform={platform}
                    />        
                </div>

                {/* <div className='card_bottom'>
                </div> */}
            
            </div>
        )
    }

    return renderContent()
}

export default ChartTrends;