export const CLUSTERS = [
  {
    enName: "HKW",
    tcName: "香港西",
  },
  {
    enName: "HKE",
    tcName: "香港東",
  },
  {
    enName: "KW",
    tcName: "九龍西",
  },
  {
    enName: "KE",
    tcName: "九龍東",
  },
  {
    enName: "KC",
    tcName: "九龍中",
  },
  {
    enName: "NTW",
    tcName: "新界西",
  },
  {
    enName: "NTE",
    tcName: "新界東",
  },
];

export const PAGES = {
  MAIN: "DASH_MAIN",
  MAIN_CH: "DASH_CH",
  MAIN_AHU: "DASH_AHU",
  ADMIN_OPER: "ADMIN_OPER",
  ADMIN_CH: "ADMIN_CH",
  ADMIN_FC: "ADMIN_FC",
  ADMIN_AHU: "ADMIN_AHU"
};
