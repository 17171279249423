import {Spinner} from "react-bootstrap";

function LoadingPage(){
    return (
        <div className="d-flex align-items-center justify-content-center" style={{height: "100%"}}>
            <div><Spinner animation="border" variant="light"/></div>
        </div>
    )
}

export default LoadingPage;