import { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner, Accordion } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { RiMoneyDollarBoxFill as MoneyIcon, RiAlarmWarningFill as ALARM_ICON } from "react-icons/ri";
import { ImPowerCord as PowerIcon } from "react-icons/im";
import { RxLapTimer as TimerIcon } from "react-icons/rx";
import { WiSmoke as SmokeIcon } from "react-icons/wi";
import { MdSavings as SaveIcon } from "react-icons/md";
import { AiOutlineAreaChart as ChartIcon } from "react-icons/ai";
import { GiBrain as AIIcon } from "react-icons/gi";
import moment from 'moment';

import { 
    API_URL,
    HARD_CODE_DATA
 } from "../../../utils/variables"
import { axiosWithXAuth, numberWithCommas } from "../../../utils/functions"
import { SET_PAGE, PAGES } from '../../../constants';

import '../css.scss';
import HeaderBage from '../../components/header/HeaderBage';
import RealTimeStatus from './RealTimeStatus';
import OptSequence from './OptSequence';
import KPI from './KPI';
import AlertPrompt from './AlertPrompt';
import SmallCharts from './SmallCharts';
import AHUSuggestion from './AHUSuggestion';

function DashboardMain() {
    const dispatch = useDispatch();

    const [ttEnergySave, setTTEnergySave] = useState(0);
    const [ttCostSave, setTTCostSave] = useState(0);
    const [ttCo2Save, setTTCo2Save] = useState(0);
    const [ttRun, setTTRun] = useState(0);
    const [periodDate, setPeriodDate] = useState(new Date());
    const [ttOrigPower, setTTOrigPower] = useState(0);
    const [ttAiPower, setTTAiPower] = useState(0);
    const [powerPct, setPowerPct] = useState(0);

    // var { building, rtData, current_page } = useSelector(
    //     ({ core, data, content }) => ({
    //         building: data.building,
    //         rtData: data.realtimeData,
    //         current_page: core.page
    //     })
    // )

    const building = useSelector(root => root.data.building);
    const rtData = useSelector(root => root.data.realtimeData);
    const current_page = useSelector(root => root.core.page);

    useEffect(() => {
        if (current_page!==PAGES.MAIN)
            dispatch({ type: SET_PAGE, page: PAGES.MAIN });
    }, [])

    useEffect(() => {
        

        if (building){
            let b_code = building.building_ibms_id.toLowerCase();
            let th_rtData = rtData[b_code];

            if (th_rtData!==undefined){
                updateKPI(th_rtData);
                updateAIrun(th_rtData);
            }
        }
    }, [rtData, building]);

    const updateKPI = (th_rtData) => {
        if (th_rtData.saving===undefined) return false;
        
        let data = building.kpi_duration===1? th_rtData.saving.current_mth: th_rtData.saving.total;

        if (data===undefined||data===null) return false;

        setTTOrigPower(data.original_energy);
        setTTAiPower(data.ai_energy);
        setTTEnergySave(data.energy_save);
        setTTCo2Save(data.co2_save);
        setTTCostSave(data.cost_save);

        if (data.original_energy!==0 && data.ai_energy!==0 )
            setPowerPct(data.energy_save/data.original_energy*100);
    }

    const updateAIrun = (th_rtData) => {
        if (th_rtData.ai['run']===undefined||th_rtData.ai['run']===null) return false;

        setTTRun(th_rtData.ai['run']);
    }

    const getSaving = async () => {
        let savingType = HARD_CODE_DATA[building.building_id]['saving_type'];
        let today = new Date(); 
        let start = today;
        let end = today;
        start = new Date(today.getFullYear(),today.getMonth(),1)
        end = new Date().setDate(today.getDate() - 1);
        // if (today.getMonth() === 0){
        //     start = new Date(today.getFullYear()-1,11,1) // the month is 0-indexed
        //     end = new Date(today.getFullYear()-1,11,31)
        // }
        // else{
        //     start = new Date(today.getFullYear(),today.getMonth()-1,1)
        //     end = new Date(today.getFullYear(), today.getMonth(), 0);
        // }
        setPeriodDate(start);

        let from_dt = moment(start).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(end).format("YYYY-MM-DD 23:59:59");
        if (building.kpi_duration===2 && building.kpi_from_dt!==null){
            from_dt = building.kpi_from_dt + " 00:00:00";
            to_dt = moment(today).format("YYYY-MM-DD 23:59:59");
        }

        let data = {};
        if (from_dt && to_dt){
            data = {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_id": building.building_id,
                "b_code": building.building_ibms_id.toLowerCase()
            };
            // data = {
            //     "from_dt": "2023-08-01 00:00:00",
            //     "to_dt": "2023-08-31 00:00:00"
            // }
        }

        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/saving`,
            data: data
        })
            .then((response) => {
                let data = response.data;
                let total_energy = 0;
                let total_cost = 0;
                let total_co2 = 0;
                let total_original_power = 0;
                let total_ai_power = 0;
                let result = [];

                if (savingType==="AI"){
                    result = data.map(({energy_saving,cost_saving,co2_saving, bs_kwh, ai_kwh, dt, is_ai}) => 
                    ({
                        dt, 
                        energy_saving: energy_saving, 
                        cost_saving: cost_saving, 
                        co2_saving: co2_saving, 
                        original_power: bs_kwh,
                        ai_power: ai_kwh,
                        is_ai: is_ai
                    }));     
                    let tot_energy_list = result.map((element, index) => { return element.original_power});
                    let tot_energy = tot_energy_list.reduce((x, y) => {
                        return x + y;
                        }, 0);
                    setTTOrigPower(parseFloat(tot_energy.toFixed(0)));
                    result = result.filter(el=>el.is_ai===1);
                    
                }else{
                    result = data.map(({pred_energy_saving,pred_cost_saving,pred_co2_saving,ai_kwh,pred_kwh,dt, is_ai}) => 
                    ({
                        dt, 
                        energy_saving: pred_energy_saving, 
                        cost_saving: pred_cost_saving, 
                        co2_saving:pred_co2_saving,
                        original_power: ai_kwh,
                        ai_power: pred_kwh,
                        is_ai: is_ai
                    }));        
                    
                    let tot_energy_list = result.map((element, index) => { return element.original_power});
                    let tot_energy = tot_energy_list.reduce((x, y) => {
                        return x + y;
                        }, 0);
                    setTTOrigPower(parseFloat(tot_energy.toFixed(0)));
                    result = result.filter(el=>el.is_ai===0);
                }

                result.forEach(el => {
                    // if (new Date(el.dt).getMonth() == today.getMonth()){
                    total_energy += el.energy_saving;
                    total_cost = el.cost_saving;
                    total_co2 += el.co2_saving;
                    total_original_power += el.original_power;
                    total_ai_power += el.ai_power;
                    // }
                })
                setTTEnergySave(parseFloat(total_energy.toFixed(0)));
                setTTCostSave(total_cost);
                setTTCo2Save(total_co2);
                // setTTOrigPower(parseFloat(total_original_power.toFixed(0)));
                setTTAiPower(parseFloat(total_ai_power.toFixed(0)));
            })
            .catch((err) => {

            })
    }

    const renderContent = () => {
        let desc_run = `AI optimization has been run for`;
        let desc_energy, desc_cost, desc_co2= "";
        let tt_energy_save = ttEnergySave?numberWithCommas(ttEnergySave, 0):"---";
        let energy_pct = powerPct?numberWithCommas(powerPct, 2):"---";
        let tt_cost_save = ttCostSave?numberWithCommas(ttCostSave, 0):"---";
        let tt_co2_save = ttCo2Save?numberWithCommas(ttCo2Save):"---";
        let tt_run = ttRun?numberWithCommas(ttRun, 0):0;

        if (building.kpi_duration===1){
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                ];
            let month_name = monthNames[periodDate.getMonth()];
            let year = periodDate.getFullYear()
            desc_energy = `Total energy saving in ${month_name} ${year} is`;
            desc_cost = `Total cost saving in ${month_name} ${year} is`;
            desc_co2 = `Total CO2 saving in ${month_name} ${year} is`;
        }
        else{
            desc_energy = `As of today, ${numberWithCommas(ttOrigPower)} - ${numberWithCommas(ttAiPower)} =`;
            desc_cost = `As of today, the total cost saving is`;
            desc_co2 = `As of today, the total CO2 saving is`;
        }

        if (building.ai_run!==null){
            tt_run = numberWithCommas(building.ai_run, 0)
        }

        let col_width = building.kpi_widgets.length===4?3:4;
        let energy_kpi_value = `${tt_energy_save} kWh (${energy_pct}%)`;
        let cost_kpi_value = `$ ${tt_cost_save}`;
        let co2_kpi_value = `${tt_co2_save} CO₂(ton)`;
        let run_kpi_value = `${tt_run} hours`;
        return (
            <>
                <div className='pt-2 px-0 d-none d-sm-none d-md-block'>
                    <Row className='mx-0'>
                        <Col md={12} lg={12}>
                            <AlertPrompt platform="web" recordnum={10}/>
                        </Col>
                    </Row>
                    <Row className='mb-3 mx-0'>
                        {
                        building.kpi_widgets.includes("kpi_energy_saving") && 
                        <Col md={12} lg={col_width}>
                            <KPI
                                platform="web"
                                name="kpi_energy_saving"
                                title="Total Energy Saving"
                                desc={desc_energy}
                                value={energy_kpi_value}
                                bageColor="#622569"
                                bageIcon={<PowerIcon/>}/>
                        </Col>
                        }
                        {
                        building.kpi_widgets.includes("kpi_cost_saving") && 
                        <Col md={12} lg={col_width}>
                            <KPI
                                platform="web"
                                name="kpi_cost_saving"
                                title="Total Cost Saving"
                                desc={desc_cost}
                                value={cost_kpi_value}
                                bageColor="#223A5C"
                                bageIcon={<MoneyIcon size={20}/>}/>
                        </Col>
                        }
                        {
                        building.kpi_widgets.includes("kpi_co2_saving") && 
                        <Col md={12} lg={col_width}>
                            <KPI
                                platform="web"
                                name="kpi_co2_saving"
                                title="Total CO2 Saving"
                                desc={desc_co2}
                                value={co2_kpi_value}
                                bageColor="#a34c00"
                                bageIcon={<SmokeIcon size={25}/>}/>
                        </Col>
                        }
                        {
                        building.kpi_widgets.includes("kpi_program_run") && 
                        <Col md={12} lg={col_width}>
                            <KPI
                                platform="web"
                                name="kpi_program_run"
                                title="AI Running Duration"
                                desc={desc_run}
                                value={run_kpi_value}
                                bageColor="#405d27"
                                bageIcon={<TimerIcon/>}/>
                        </Col>
                        }
                    </Row>
                    <Row  className='mb-3 mx-0'>
                        <Col md={12} lg={3}>
                            <RealTimeStatus platform="web"/>
                        </Col>
                        <Col md={12} lg={6}>
                            <Row className='px-2 mb-3'>
                                <OptSequence platform="web" recordnum={building.show_ahu_page?3:10}/>
                            </Row>
                            {   building.show_ahu_page===1 && 
                                <Row className='px-2'>
                                    <AHUSuggestion platform="web" recordnum={6}/>
                                </Row>
                            }
                        </Col>
                        <Col md={12} lg={3}>
                            <SmallCharts/>
                        </Col>
                    </Row>
                </div>
                <div className='d-block d-sm-block d-md-none'>
                    <Accordion defaultActiveKey="0" flush className='mobile_accordion'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><ALARM_ICON size={20} color='#212529' className='me-1' style={{marginTop: '-2px'}}/> <b>Alarm / Real Time Status</b></Accordion.Header>
                            <Accordion.Body>
                                <AlertPrompt platform="mobile" recordnum={4}/>
                                <RealTimeStatus platform="mobile"/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><SaveIcon size={20} color='#212529' className='me-1' style={{marginTop: '-2px'}}/> <b>Accumulated Saving</b></Accordion.Header>
                            <Accordion.Body>
                                <Row className='mb-3'>
                                    {
                                    building.kpi_widgets.includes("kpi_energy_saving") && 
                                    <Col md={12} lg={col_width}>
                                        <KPI
                                            platform="mobile"
                                            name="kpi_energy_saving"
                                            title="Total Energy Saving"
                                            desc={desc_energy}
                                            value={energy_kpi_value}
                                            bageColor="#622569"
                                            bageIcon={<PowerIcon/>}/>
                                    </Col>
                                    }
                                    {
                                    building.kpi_widgets.includes("kpi_cost_saving") && 
                                    <Col md={12} lg={col_width}>
                                        <KPI
                                            platform="mobile"
                                            name="kpi_cost_saving"
                                            title="Total Cost Saving"
                                            desc={desc_cost}
                                            value={cost_kpi_value}
                                            bageColor="#223A5C"
                                            bageIcon={<MoneyIcon size={20}/>}/>
                                    </Col>
                                    }
                                    {
                                    building.kpi_widgets.includes("kpi_co2_saving") && 
                                    <Col md={12} lg={col_width}>
                                        <KPI
                                            platform="mobile"
                                            name="kpi_co2_saving"
                                            title="Total CO2 Saving"
                                            desc={desc_co2}
                                            value={co2_kpi_value}
                                            bageColor="#a34c00"
                                            bageIcon={<SmokeIcon size={25}/>}/>
                                    </Col>
                                    }
                                    {
                                    building.kpi_widgets.includes("kpi_program_run") && 
                                    <Col md={12} lg={col_width}>
                                        <KPI
                                            platform="mobile"
                                            name="kpi_program_run"
                                            title="AI Running Duration"
                                            desc={desc_run}
                                            value={run_kpi_value}
                                            bageColor="#405d27"
                                            bageIcon={<TimerIcon/>}/>
                                    </Col>
                                    }
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><AIIcon size={19} color='#212529' className='me-1' style={{marginTop: '-2px'}}/> <b>Chiller Control Suggestion</b></Accordion.Header>
                            <Accordion.Body>
                                <OptSequence platform="mobile" recordnum={4}/>
                            </Accordion.Body>
                        </Accordion.Item>
                        {   building.show_ahu_page===1 && 
                            <Accordion.Item eventKey="3">
                                <Accordion.Header><AIIcon size={19} color='#212529' className='me-1' style={{marginTop: '-2px'}}/> <b>AHU Control Suggestion</b></Accordion.Header>
                                <Accordion.Body>
                                    <AHUSuggestion platform="mobile" recordnum={6}/>
                                </Accordion.Body>
                            </Accordion.Item>
                        }
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><ChartIcon size={20} color='#212529' className='me-1' style={{marginTop: '-2px'}}/> <b>Charts</b></Accordion.Header>
                            <Accordion.Body>
                                <SmallCharts/>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </>
        )
    }

    return (
        <div style={{minHeight: "83vh"}}>
            {building && renderContent()}
            {!building && 
                <Container className='py-4'>
                    <Spinner animation="border" variant="light" size="md" />
                </Container>
            }
        </div>
    )
}

export default DashboardMain;