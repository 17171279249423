import { useState } from 'react';
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";
import PropTypes from "prop-types";

import { CustomToggleMore } from "../../../utils/functions"
import KPIChartAdvance from '../chart/KPIChartAdvance';

const  KPI = ({
    platform = "web",
    name = "",
    title = "test",
    desc = "",
    value = "",
    bageColor = "black",
    bageIcon = null
}) => {

    const [showModal, setShowModal] = useState(false);

    return (
        <div>
            <Container className="card_bg kpi_card mt-2">
                {   platform==="mobile" &&
                    <Row>
                        <h6 className="kpi_bage mobile_kpi_bage py-2 my-0"  style={{"backgroundColor": bageColor, "position": "relative"}}>
                            <span className="ms-4" style={{"position":"absolute", "left": "0"}}>{bageIcon}</span>
                            <b>{title}</b>
                            {
                                (name === "kpi_cost_saving" || name=="kpi_energy_saving") &&
                                <span className="me-3" style={{"position":"absolute", "right": "0", marginTop: "-3px"}}>
                                </span>
                            }
                        </h6>
                    </Row>
                }
                <Row className="py-3">
                    {
                        value===""?
                        <Col className="px-0">
                            {desc}
                        </Col>
                        :
                        <>
                            <Col md={8} className="px-0">
                                {desc}
                            </Col>
                            <Col md={4} className="px-0">
                                <b>{value}</b>
                            </Col>
                        </>
                    }
                    
                </Row>
                { platform==="mobile" && (name === "kpi_cost_saving" || name=="kpi_energy_saving") &&
                    <Row className='pb-3'>
                        <hr />
                        <Col>
                            <Button variant="secondary" onClick={()=>setShowModal(true)}>Show More</Button>{' '}
                        </Col>
                    </Row>
                }
                {
                    platform==="web" &&
                    <Row>
                        <h6 className="kpi_bage py-2 my-0"  style={{"backgroundColor": bageColor, "position": "relative"}}>
                            <span className="ms-4" style={{"position":"absolute", "left": "0"}}>{bageIcon}</span>
                            <b>{title}</b>
                            {
                                (name === "kpi_cost_saving" || name=="kpi_energy_saving") &&
                                <span className="me-3" style={{"position":"absolute", "right": "0", marginTop: "-3px"}}>
                                    <Dropdown className='btn_show_more'>
                                        <Dropdown.Toggle id="dropdown-button-dark-example1" as={CustomToggleMore} >
                                            Custom Toggle
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align={{md: "start"}}>
                                            <Dropdown.Item eventKey="1" onClick={()=>setShowModal(true)}>Show More</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown >
                                </span>
                            }
                        </h6>
                    </Row>
                }
            </Container>
            <KPIChartAdvance
                platform={platform}
                name={name}
                color={bageColor}
                setShowModal={setShowModal}
                showModal={showModal}/>
        </div>
    )
}

KPI.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    bageColor: PropTypes.string.isRequired,
    bageIcon: PropTypes.any,
};

export default KPI;