import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import logger from "redux-logger";
import rootReducer from "./reducers";
import { configureStore } from '@reduxjs/toolkit';

const persistConfig = {
    key: "root",
    storage,
    whitelist: []
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const preloadedState = window.__PRELOADED_STATE__;

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(logger),
    // preloadedState: preloadedState
  })

const persistor = persistStore(store);

export { store, persistor };