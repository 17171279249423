import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Modal, Button, Table } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";

import PaginationComponent from '../../components/pagination/Pagination';
import { API_URL } from "../../../utils/variables";
import { axiosWithXAuth } from "../../../utils/functions";

const  ModeHistory = ({
    showModal = false,
    setShowModal,
    platform = "web",
}) => {

    const handleClose = () => setShowModal(false);

    const building = useSelector(root => root.data.building);

    const [modeRecords, setModeRecords] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        setPage(1);
        if (showModal){
            callMode();
        }
    }, [showModal])

    useEffect(() => {
        if (page<1)
            setPage(1);
    }, [page])

    const callMode = async () => {
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/modeHistory`,
            data: {
                b_code: building.building_ibms_id.toLowerCase(),
                b_id: building.building_id
            }
        })
        .then((response) => {
            let data = response.data;
            setModeRecords(data)
        })
        .catch((err) => {
        })
    }

    const renderRow = (row, index) => {
        let cdt = new Date(row.dt);
        cdt = cdt.setHours(cdt.getHours() - 8);
        let dt = moment(cdt).format("YYYY-MM-DD h:mm a");
        // let dt = row.dt;
        let mode = row.mode;
        let item_num = (page-1) * itemsPerPage + index + 1;
        return (
            <tr key={`mode_h_${index}`}>
                <td>{item_num}</td>
                <td>{dt}</td>
                <td>{mode}</td>
            </tr>
        )
    }

    const renderRecordsWrap = (records) => {
        if (records.length > 0){

            let from_item = page===1? 1: (page-1) * itemsPerPage + 1;
            let to_item = from_item + itemsPerPage - 1;
            let records_in_page = records.filter(
                (element, index) => { 
                    return index+1 >= from_item && index+1 <= to_item
                });

            return records_in_page.map((element, index) => {
                return renderRow(element, index);
            })
        }
    }

    const renderContent = () =>{
        let smallfont = platform==="web"?"":"small_font";
        return (
            <Container className={`py-1 ${smallfont}`}>
                <Table striped border="true" hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Datetime</th>
                            <th>Mode</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRecordsWrap(modeRecords)}
                    </tbody>
                </Table>
                <div className="myPagination myPag-b d-flex justify-content-center">
                    {
                        <PaginationComponent
                            itemsCount={modeRecords.length}
                            itemsPerPage={itemsPerPage}
                            currentPage={page}
                            setCurrentPage={setPage}
                            alwaysShown={false}
                        />
                    }
                </div>
            </Container>
        )
    }

    return (
        <Modal size="lg" fullscreen="lg-down" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Operation Mode History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderContent()}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

ModeHistory.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    platform: PropTypes.string.isRequired
};

export default ModeHistory;