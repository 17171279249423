import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { API_URL} from "../../../utils/variables"
import { axiosWithXAuth } from "../../../utils/functions"
import SmallLineChart from '../chart/SmallLineChart';
import '../css.scss';

function SmallCharts() {

    const building = useSelector(root => root.data.building);
    const rtData = useSelector(root => root.data.realtimeData);

    const [predictPower, setPredictPower] = useState([]);
    const [predictTc, setPredictTc] = useState([]);
    const [predictCop, setPredictCop] = useState([]);
    const [actualPower, setActualPower] = useState([]);
    const [actualTc, setActualTc] = useState([]);
    const [actualCop, setActualCop] = useState([]);
    const [bsPower, setBsPower] = useState([]);
    const [bsCop, setBsCop] = useState([]);
    const [aiPower, setAiPower] = useState([]);
    const [aiCop, setAiCop] = useState([]);
    const [bs2Power, setBs2Power] = useState([]);
    const [bs2Cop, setBs2Cop] = useState([]);
    const [dateLabels, setDateLabels] = useState([]);
    const [dateLabels2, setDateLabels2] = useState([]);
    const [isRenderGlow, setIsRenderGlow] = useState(true);
    const [isRenderGlow2, setIsRenderGlow2] = useState(true);
    const [titlePeriod, setTitlePeriod] = useState("");

    useEffect(() => {
        let today = new Date();
        let start = new Date().setDate(today.getDate() - 1);
        let end = new Date().setDate(today.getDate());
        let from_dt = moment(start).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(end).format("YYYY-MM-DD 23:59:59");

        if (building.chart_from_dt!==null){
            from_dt = building.chart_from_dt + " 00:00:00";
            to_dt = moment(new Date(from_dt).setDate(new Date(from_dt).getDate() + 1)).format("YYYY-MM-DD 23:59:59");
        }

        let period_start = moment(new Date(from_dt)).format("MM/DD");
        let period_end = moment(new Date(to_dt)).format("MM/DD");
        
        setTitlePeriod(period_start+'-'+period_end);
        // callPredictActual(from_dt, to_dt);
        // callAiBs(from_dt, to_dt);
    }, []);

    useEffect(() => {
        let b_code = building.building_ibms_id.toLowerCase();
        let th_rtData = rtData[b_code];

        if (th_rtData!==undefined && building.building_ibms_id!==undefined){
            if (building.small_charts.includes('ap_tc_trend'))
                // massageDataPredAct(structuredClone(th_rtData.charts_pred_act));
                massageDataPredAct(JSON.parse(JSON.stringify(th_rtData.charts_pred_act)));

            if (building.small_charts.includes('aibs_cop_trend'))
                // massageDataAiBs(structuredClone(th_rtData.charts_ai_bs));
                massageDataAiBs(JSON.parse(JSON.stringify(th_rtData.charts_ai_bs)));
        }
    },[rtData,building])

    const callPredictActual = async (from_dt, to_dt) => {
        let data = {};
        if (from_dt && to_dt){
            data = {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_code": building.building_ibms_id.toLowerCase()
            }
        }

        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/getActualPredictCompare`,
            data: data
        })
            .then((response) => {
                let data = response.data;
                massageDataPredAct(structuredClone(data));
            })
    }

    const callAiBs = async (from_dt, to_dt) => {
        let data = {};
        if (from_dt && to_dt){
            data = {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_code": building.building_ibms_id.toLowerCase()
            }
        }

        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/getAiBsCompare`,
            data: data
        })
            .then((response) => {
                let data = response.data;
                massageDataAiBs(structuredClone(data));
            })
    }

    const massageDataPredAct = (data_o) => {
        if (data_o.length<=0) return false;

        let data = [];

        data_o.forEach((element, index) => {
            data.push({
                "dt": element.dt,
                "predict_power": element.predict_power,
                "predict_tc": element.predict_tc,
                "predict_cop": element.predict_cop,
                "actual_power": element.actual_power,
                "actual_tc": element.actual_tc,
                "actual_cop": element.actual_cop,
                "bs_power": element.bs_power,
                "bs_cop": element.bs_cop,
                "mode": element.mode
            })
        })

        data = data.filter(e => 
            e.predict_power !== null
            && e.predict_tc !== null
            && e.predict_cop !== null
            && e.actual_power !== null
            && e.actual_tc !== null
            && e.actual_cop !== null
            // && e.bs_power !== null
            // && e.bs_cop !== null
            && e.mode === 'AI'
            && e.actual_cop < 10
            && e.predict_cop < 10
            );

        let predict_power = data.map((element, index) => { return element["predict_power"]; });
        let predict_tc = data.map((element, index) => { return element["predict_tc"]; });
        let predict_cop = data.map((element, index) => { return element["predict_cop"]; });
        let actual_power = data.map((element, index) => { return element["actual_power"]; });
        let actual_tc = data.map((element, index) => { return element["actual_tc"]; });
        let actual_cop = data.map((element, index) => { return element["actual_cop"]; });
        let bs_power = data.map((element, index) => { return element["bs_power"]; });
        let bs_cop = data.map((element, index) => { return element["bs_cop"]; });

        let data_labels = data.map((element, index) => { 
            let x = new Date(element["dt"]);
            // x = x.setHours(x.getHours() - 8);
            return moment(x).format('MM-DD h:mm a'); 
        });
        setPredictPower(predict_power);
        setPredictTc(predict_tc);
        setPredictCop(predict_cop);
        setActualPower(actual_power);
        setActualTc(actual_tc);
        setActualCop(actual_cop);
        setBsPower(bs_power);
        setBsCop(bs_cop);
        setDateLabels(data_labels);
        setIsRenderGlow(false);
    }

    const massageDataAiBs = (data_o) => {
        if (data_o.length<=0) return false;

        let data = [];

        data_o.forEach((element, index) => {
            data.push({
                "dt": element.dt,
                "ai_power": element.ai_power,
                "ai_cop": element.ai_cop,
                "bs_power": element.bs_power,
                "bs_cop": element.bs_cop
            })
        })
        data = data.filter(e => 
            e.ai_power !== null
            && e.ai_cop !== null
            && e.bs_power !== null
            && e.bs_cop !== null
            );

        let ai_power = data.map((element, index) => { return element["ai_power"]; });
        let ai_cop = data.map((element, index) => { return element["ai_cop"]; });
        let bs_power = data.map((element, index) => { return element["bs_power"]; });
        let bs_cop = data.map((element, index) => { return element["bs_cop"]; });

        let data_labels = data.map((element, index) => { 
            let x = new Date(element["dt"]);
            // x = x.setHours(x.getHours() - 8);
            return moment(x).format('MM-DD h:mm a'); 
        });
        setAiPower(ai_power);
        setAiCop(ai_cop);
        setBs2Power(bs_power);
        setBs2Cop(bs_cop);
        setDateLabels2(data_labels);
        setIsRenderGlow2(false);
    }

    const renderContent = () => {
        let chart_total = building.small_charts.length;
     
        return (
            <div>
                { building.small_charts.includes('ap_tc_trend') &&
                    <SmallLineChart
                        title="Total Cooling Load"
                        titlePeriod={titlePeriod}
                        yAxisLabel="kW"
                        unit="kW"
                        line1={predictTc}
                        line2={actualTc}
                        dateLabels={dateLabels}
                        apiUrl={API_URL+"/api/getActualPredictCompare"}
                        chartName="ap_tc_trend"
                        chartTotal={chart_total}
                        isRenderGlow={isRenderGlow}
                        hasMore={true}
                    />
                }
                { building.small_charts.includes('ap_power_trend') &&
                    <SmallLineChart
                        title="Power Consumption"
                        titlePeriod={titlePeriod}
                        yAxisLabel="kW"
                        unit="kW"
                        line1={predictPower}
                        line2={actualPower}
                        line3={bsPower}
                        dateLabels={dateLabels}
                        apiUrl={API_URL+"/api/getActualPredictCompare"}
                        chartName="ap_power_trend"
                        chartTotal={chart_total}
                        isRenderGlow={isRenderGlow}
                        hasMore={true}
                    />
                }
                { building.small_charts.includes('ap_cop_trend') &&
                    <SmallLineChart
                        title="COP"
                        titlePeriod={titlePeriod}
                        yAxisLabel=""
                        unit=""
                        line1={predictCop}
                        line2={actualCop}
                        line3={bsCop}
                        dateLabels={dateLabels}
                        apiUrl={API_URL+"/api/getActualPredictCompare"}
                        chartName="ap_cop_trend"
                        chartTotal={chart_total}
                        isRenderGlow={isRenderGlow}
                        hasMore={true}
                    />
                }
                { building.small_charts.includes('aibs_cop_trend') &&
                    <SmallLineChart
                        title="AI Optimization / Base Line - COP"
                        titlePeriod={titlePeriod}
                        yAxisLabel=""
                        unit=""
                        line1={aiCop}
                        line2={bs2Cop}
                        dateLabels={dateLabels2}
                        apiUrl={API_URL+"/api/getAiBsCompare"}
                        chartName="aibs_cop_trend"
                        chartTotal={chart_total}
                        isRenderGlow={isRenderGlow2}
                        hasMore={true}
                    />
                }
                { building.small_charts.includes('aibs_power_trend') &&
                    <SmallLineChart
                        title="AI Optimization / Base Line - Power Consumption"
                        titlePeriod={titlePeriod}
                        yAxisLabel="kWh"
                        unit="kWh"
                        line1={aiPower}
                        line2={bs2Power}
                        dateLabels={dateLabels2}
                        apiUrl={API_URL+"/api/getAiBsCompare"}
                        chartName="aibs_power_trend"
                        chartTotal={chart_total}
                        isRenderGlow={isRenderGlow2}
                        hasMore={true}
                    />
                }
            </div>
        )
    }

    return (
        <div>
            {building && renderContent()}
        </div>
    )
}

export default SmallCharts;