import { Container, Row, Badge } from "react-bootstrap";
import { BsExclamationCircleFill as InfoIcon } from "react-icons/bs";

import SequenceBox from './SequenceBox';

export default function OperationSetting(){

    const list_operation = [
        { name: "Monday", param_key: "mon" },
        { name: "Tuesday", param_key: "tue" },
        { name: "Wednesday", param_key: "wed" },
        { name: "Thursday", param_key: "thu" },
        { name: "Friday", param_key: "fri" },
        { name: "Saturday", param_key: "sat" },
        { name: "Sunday", param_key: "sun" }
    ]

    return (
        <>
            <div className='config_header'>Chiller Operation Settings</div>
            <div className='pb-3'><InfoIcon className='me-2'/>Drag and drop <Badge className='dd_item dd_item_sm'>Chiller 1</Badge> to decide the chiller sequence when operation start. (From top to bottom)</div>
            <Row className='d-flex justify-content-center'>
            {
                list_operation.map(el => {
                    return <SequenceBox key={`${el.param_key}_seq_box`} day_name={el.name} param_key={el.param_key}/>
                })
            }
            </Row>
        </>
    )
}