
import { Outlet } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import withAppLoader from "../../../hoc/withAppLoader";
import TopNavbar from "../navbar/TopNavbar";
// import HeaderBage from "./HeaderBage";
import HeaderBage from "../header/HeaderBage";
import MainLeftNavbar from "../navbar/MainLeftNavbar";

function DashboardLayout () {

    return (
        <div style={{zIndex: "1", position: "relative"}}>
            <TopNavbar/>
            <div className="align-items-center justify-content-center" style={{ height: "100%" }}>
                <div className='dashboard-bg scroll_list'>
                    <Row>
                        <HeaderBage />
                    </Row>
                    <Row>
                        <Col className='main-left-menu px-0 d-none d-xl-block'>
                            <MainLeftNavbar/>
                        </Col>
                        <Col className='dashboard-main-content px-3 px-3'>
                            <Outlet/>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default withAppLoader(DashboardLayout,{});