import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Badge } from "react-bootstrap";
import axios from "axios";

import LoadingPage from "../loadingpage/index";
import { API_URL } from "../../utils/variables";
import EMSD_ICON from "../../assets/img/logo/emsd-logo.png";
import './css.scss';

function LoginAdmin(){
    const navigate = useNavigate();

    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [replyMsg,setReplyMsg] = useState('');
    const [incorrectCredential,setIncorrectCredential] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // console.log(JSON.stringify(document.location.href))
        let idx = document.location.href.indexOf("token=");
        // if (idx!==-1){
        //     ccepLogin();
        // }
        // else{
        //     setIsLoading(false);
        // }
        setIsLoading(false);
    }, []);

    const ccepLogin = () => {
        axios({
            method: "get",
            url: `${API_URL}/api/cceplogin`,
        })
        .then((response) => {
            if (typeof response.headers.xauth !== 'undefined') {
                setIncorrectCredential(200);
                window.localStorage.setItem("xauth", response.headers.xauth);
                // window.location.href = PATH_PREFIX+"/dashboard/main";
                navigate('/dashboard/main'); 
            }
        })
        .catch((error) => {
            setIsLoading(false);
            if (error.response){
                console.log(error.response.data)
                console.log(error.response.status)
                // logger.error(error.response.headers)
                setReplyMsg(error.response.data.message);
            }
            else {
                console.log(`${JSON.stringify(error)}`)
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        axios({
            method: "post",
            url: `${API_URL}/api/loginAdmin`,
            data: {
                username,
                password
            }
        })
        .then((response) => {
            if (typeof response.headers.xauth !== 'undefined') {
                setIncorrectCredential(200);
                window.localStorage.setItem("xauth", response.headers.xauth);
                // window.location.href = PATH_PREFIX+"/dashboard/main";
                navigate('/admin/main'); 
            }
        })
        .catch((error) => {
            setIncorrectCredential(error.response.status);
            if (error.response.data.message !== null){
                setReplyMsg(error.response.data.message)
            }
            else {
                setReplyMsg("Login fails.")
            }
        })
    };

    if (isLoading){
        return (
            <LoadingPage/>
        )
    }
    else{
        return (
            <div className="d-flex align-items-center justify-content-center" style={{height: "100%"}}>
                <Container>
                    <Row>
                        <Col sm={1} md={3}></Col>
                        <Col sm={10} md={6} className="login_form admin_only">
                            <Row className='login_text'>
                                <Col>
                                    <h2>Optimization Dashboard</h2>
                                    <h4><Badge bg="danger">Admin Only</Badge></h4>
                                    {/* {document.location.href} */}
                                </Col>
                            </Row>
                            <Form className="px-4 py-4" onSubmit={handleSubmit}>
                                <Col xs={12} sm={8} md={8} className="mx-auto my-2">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className='login_text'>Username</Form.Label>
                                        <Form.Control type="text" placeholder="Post name" onChange={event => setUsername(event.target.value)}/>
                                        {/* <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                        </Form.Text> */}
                                    </Form.Group>
                                </Col>

                                <Col xs={12} sm={8} md={8} className="mx-auto my-2">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label className='login_text'>Password</Form.Label>
                                        <Form.Control type="password" placeholder="LN password" onChange={event => setPassword(event.target.value)}/>
                                    </Form.Group>
                                </Col>

                                
                                <Col xs={12} sm={8} md={8} className="mx-auto mt-4 d-grid gap-2">
                                    {(incorrectCredential!==200) && 
                                        <div className="field">
                                            <span style={{color: '#990033'}}>{replyMsg}</span>
                                        </div>
                                    }
                                </Col>

                                <Col xs={12} sm={8} md={8} className="mx-auto mt-4 d-grid gap-2">
                                    <Button variant="primary" type="submit" >
                                        Login
                                    </Button>
                                </Col>
                            </Form>
                            <Row>
                                <Col xs={12} sm={8} md={8} className="mx-auto mt-4">
                                    <img src={EMSD_ICON} className="emsd_logo"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={1} md={3}></Col>
                    </Row>
                </Container>
            </div>        
        )
    }
}

export default LoginAdmin;