import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap";
import { TbCalendarStats as CalendarIcon } from 'react-icons/tb';
import moment from "moment";

function HeaderBage() {

    const building = useSelector(root => root.data.building);

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    useEffect(() => {
        if (building && building.building_id){
            if (building.start_time !== null)
                setStartTime(moment(building.start_time).format("h:mm a"));

            if (building.end_time !== null)
                setEndTime(moment(building.end_time).format("h:mm a"));
        }
    }, [building])

    const renderContent = () => {
        return (
            <>
            <Container className='header_bage py-3 float-left d-none d-sm-none d-md-block'>
                <Row className='float-left px-5'>
                    <Col className='text-left'>
                        <h4 className='.d-block .d-sm-none py-0 my-0'>{building ? building.building_name_en : ""}</h4>
                    </Col>
                    {
                    building.building_id<1000 &&
                    <Col xs={4} className='px-0'>
                        <Row className='text-right pe-3'>
                            <div>
                                <CalendarIcon style={{marginTop: "-4px"}} size={18}/>
                                <div style={{display: "inline"}}> {startTime} - {endTime}</div>
                            </div>
                        </Row>
                    </Col>
                    }
                </Row>
            </Container>
            <Container className='header_bage py-3 d-sm-block d-md-none'>
                <Row >
                    <Col className='text-center'>
                        <h4 className='.d-block .d-sm-none py-0 my-0'>{building ? building.building_name_en : ""}</h4>
                        {
                            building.building_id<1000 &&
                            <div>
                                <CalendarIcon style={{marginTop: "-4px"}} size={18}/>
                                <div style={{display: "inline"}}> {startTime} - {endTime}</div>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
            </>
        )
    }

    if (building!== null)
        return building && renderContent()
    else{
        return (<></>)
    }
}

export default HeaderBage;