import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Badge, Button, Spinner, Form, FloatingLabel } from "react-bootstrap";
import { List, arrayMove } from 'react-movable';

import { axiosWithXAuth } from '../../../utils/functions';
import { API_URL } from '../../../utils/variables';

export default function SequenceBox({day_name, param_key}){

    const building = useSelector(root => root.data.building);

    const list_operations = [ 
        { id: 1, name: 'CH 1'}, 
        { id: 2, name: 'CH 2'}, 
        { id: 3, name: 'CH 3'}
    ];
    let default_operate = 1;
    let default_st = 8;
    let default_hz = 38;
    let default_ch_must = 0;

    const [oldSeq, setOldSeq] = useState(Array(list_operations.map(e=>e.id)))
    const [oldOperate, setOldOperate] = useState(default_operate);
    const [oldChwst, setOldChwst] = useState(default_st);
    const [oldSpd, setOldSpd] = useState(default_hz);
    const [oldChMust, setOldChMust] = useState(default_ch_must);
    
    const [items, setItems] = useState(list_operations);
    const [inputSeq, setInputSeq] = useState(Array(list_operations.map(e=>e.id)))
    const [inputOperate, setInputOperate] = useState(default_operate);
    const [inputChwst, setInputChwst] = useState(default_st);
    const [inputSpd, setInputSpd] = useState(default_hz);
    const [inputChMust, setInputChMust] = useState(default_ch_must);
    const [hasUpdated, setIsUpdated] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    useEffect(()=>{
        if (building){
            callSettings();
        }
    }, [building])

    useEffect(()=>{
        let new_seq = Array(items.map(e=>e.id));
        setInputSeq(new_seq)
        checkHasUpdated(new_seq, inputChwst, inputSpd, inputChMust, inputOperate);
    }, [items])

    const callSettings = async() => {
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/settingsOperation`,
            data: {
                "b_id": building.building_id,
                "weekday": param_key
            }
        })
        .then((response) => {
            let res = response.data;
            if (res.order){
                let items = res.order[0].map((ch_num, idx)=> { return {id:ch_num, name:"CH" +ch_num.toString()} } )
                setItems(items);
                setOldSeq(Array(items.map(e=>e.id)));
                setInputSeq(Array(items.map(e=>e.id)));
            }
            if (res.ch_must && res.ch_must!==null){
                setOldChMust(res.ch_must);
                setInputChMust(res.ch_must);

            }
            else{
                setOldChMust(default_ch_must);
                setInputChMust(default_ch_must);
            }
            setOldChwst(res.st);
            setInputChwst(res.st);

            setOldSpd(res.pump_spd);
            setInputSpd(res.pump_spd);

            setOldOperate(res.operate);
            setInputOperate(res.operate);

            setIsUpdated(false);
        })
        .catch((err)=>{
            if (building.chillers){
                let ch_list = building.chillers.map((ch_name, idx)=>{ return {id: idx+1, name: ch_name} });
                setItems(ch_list);
                setOldSeq(Array(ch_list.map(e=>e.id)));
                setInputSeq(Array(ch_list.map(e=>e.id)));
            }
        })
    }

    const checkHasUpdated = (new_seq, new_chwst, new_spd, new_ch_must, new_operate) => {
        if (JSON.stringify(new_seq)===JSON.stringify(oldSeq) 
            && parseInt(new_operate)===parseInt(oldOperate)
            && parseFloat(new_chwst)===parseFloat(oldChwst)
            && parseFloat(new_spd)===parseFloat(oldSpd)
            && parseInt(new_ch_must)===parseInt(oldChMust)){
            setIsUpdated(false);
        }
        else{
            setIsUpdated(true);
        }
    }

    const onChangeChwst = (new_val) => {
        setInputChwst(new_val);
        checkHasUpdated(inputSeq, new_val, inputSpd, inputChMust, inputOperate);
    }

    const onChangeSpd = (new_val) => {
        setInputSpd(new_val);
        checkHasUpdated(inputSeq, inputChwst, new_val, inputChMust, inputOperate);
    }

    const onChangeChMust = (new_val) => {
        setInputChMust(new_val);
        checkHasUpdated(inputSeq, inputChwst, inputSpd, new_val, inputOperate);
    }

    const onChangeOperate = (new_val) => {
        setInputOperate(new_val);
        checkHasUpdated(inputSeq, inputChwst, inputSpd, inputChMust, new_val);
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true){
            event.preventDefault();
            event.stopPropagation();
            setIsSaveLoading(true);
            
            await axiosWithXAuth({
                method: "post",
                url: `${API_URL}/api/updateSettingsOperation`,
                data: {
                    "b_id": building.building_id,
                    "cfg_day": param_key,
                    "cfg_content": {
                        "seq": inputSeq[0],
                        "st": parseFloat(inputChwst),
                        "pump_spd": parseInt(inputSpd),
                        "ch_must": parseInt(inputChMust),
                        "operate": parseInt(inputOperate)
                    }
                }
            })
            .then((response) => {
                let res = response.data;

                form.classList.remove("was-validated");
                form.reset();
                callSettings();

                setTimeout(()=>{
                    setIsSaveLoading(false);
                }, 2000);
            })
            .catch((err) => {
                form.classList.remove("was-validated");
                form.reset();
                callSettings();
                setIsSaveLoading(false);
            })
        }
      };

    const renderChillerOperation = () => {
        return (
            <Col className='color_container seq_box my-2 mx-2'>
                <Form validated={validated} onSubmit={handleSubmit}>
                    <Row className="color_grid">
                        <div className='title'> {day_name}</div>
                    </Row>
                    <Row className='py-2 line_grid'><div className=''></div></Row>
                    <Row className='py-1 pb-4'>
                        <List
                            values={items}
                            onChange={({ oldIndex, newIndex }) =>
                                setItems(arrayMove(items, oldIndex, newIndex))
                            }
                            renderList={({ children, props }) => <div style={{display: "inline-block"}} {...props}>{children}</div>}
                            renderItem={({ value, props }) => <div key={`${param_key}_${value.name}_dditem`} ><Badge className='dd_item' {...props}>{value.name}</Badge></div> }
                        />
                    </Row>
                    <Row>
                        <Form.Group controlId={`${param_key}_validationCustom01`}>
                            <FloatingLabel controlId={`${param_key}_floatingInput01`} label="Chiller Sequence" className="mb-3" >
                                <Form.Control 
                                        disabled 
                                        type="text" 
                                        value={inputSeq} />
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId={`${param_key}_validationCustom02`}>
                            <FloatingLabel controlId={`${param_key}_floatingInput02`} label="Number of chillers in operation" className="mb-3" >
                                <Form.Control 
                                    disabled={isSaveLoading}
                                    required 
                                    type="number" 
                                    value={inputOperate || 1} 
                                    min={1} 
                                    max={list_operations.length} 
                                    step={1} 
                                    onChange={(e)=>onChangeOperate(e.target.value)}/>
                                {/* <Form.Control.Feedback type="invalid">
                                    Invalid
                                </Form.Control.Feedback> */}
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId={`${param_key}_validationCustom03`}>
                            <FloatingLabel controlId={`${param_key}_floatingInput03`} label="Supply Temp" className="mb-3" >
                                <Form.Control 
                                    disabled={isSaveLoading}
                                    required 
                                    type="number" 
                                    value={inputChwst || 7} 
                                    min={7} 
                                    max={9} 
                                    step={0.1} 
                                    onChange={(e)=>onChangeChwst(e.target.value)}/>
                                {/* <Form.Control.Feedback type="invalid">
                                    Invalid
                                </Form.Control.Feedback> */}
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId={`${param_key}_validationCustom04`}>
                            <FloatingLabel controlId={`${param_key}_floatingInput04`} label="Pump Speed" className="mb-3" >
                                <Form.Control 
                                    disabled={isSaveLoading}
                                    required 
                                    type="number" 
                                    value={inputSpd || 50} 
                                    min={38} 
                                    max={50} 
                                    step={1} 
                                    onChange={(e)=>onChangeSpd(e.target.value)}/>
                                {/* <Form.Control.Feedback type="invalid">
                                    Invalid
                                </Form.Control.Feedback> */}
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId={`${param_key}_validationCustom05`}>
                                <FloatingLabel controlId={`${param_key}_floatingInput05`} label="Chiller should always be turned on" className="mb-3" >
                                    <Form.Select onChange={(e)=>{onChangeChMust(e.target.value)}} value={inputChMust} disabled={isSaveLoading}>
                                        <option value={0}>No chillers is compulsory</option>
                                        {
                                            building.chillers.map((ch_name, idx)=>{
                                                return <option key={`selection_${idx+1}`} value={idx+1} >{ch_name}</option>
                                            })
                                        }
                                    </Form.Select>
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center'>
                        {hasUpdated && !isSaveLoading && <Button variant="primary" type="submit">Save</Button>}
                        {!hasUpdated && !isSaveLoading && <Button variant="secondary" disabled>Save</Button>}
                        {isSaveLoading && 
                            <Button variant="secondary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span className="visually-hidden">Loading...</span>
                            </Button>}
                    </Row>
                </Form>
            </Col>
            
        )
    }

    return (
        <>
            {building && renderChillerOperation()}
        </>
    )
}