import { Outlet } from "react-router-dom";
import { Badge } from "react-bootstrap";

import withAdminAppLoader from "../../../hoc/withAdminAppLoader";

function AdminLayout(){
    return (
        <>
            <h4 style={{"position": "absolute"}}><Badge bg="danger">Admin Only</Badge></h4>
            <div className="admin_layout scroll_list" style={{position: "relative"}}>
                <Outlet/>
            </div>
        </>
    )
}

export default withAdminAppLoader(AdminLayout,{});

