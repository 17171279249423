import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Modal, Button, Table } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";

import { API_URL } from  "../../../utils/variables";
import { axiosWithXAuth } from "../../../utils/functions";
import PaginationComponent from '../../components/pagination/Pagination';

const  OptDiagnosis = ({
    showModal = false,
    setShowModal,
    platform = "web",
    recordnum = 10
}) => {

    const handleClose = () => setShowModal(false);

    const building = useSelector(root => root.data.building);

    const [diagRecords, setDiagRecords] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = recordnum;

    useEffect(() => {
        if (showModal){
            setPage(1);
            callOptDiagnosis();
        }
    }, [showModal])

    useEffect(() => {
        if (page<1)
            setPage(1);
    }, [page])

    const callOptDiagnosis = () => {
        
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/optDiagnosis`,
            data: {b_id: building.building_id}
        })
            .then((response) => {
                let data = response.data;
                let result = []
                data.forEach((element, index) => {
                    result.push({
                        "cdt": element.cdt,
                        "diagnosis_msg": element.diagnosis_msg,
                    })
                })
                setDiagRecords(result);
            })
    }



    const renderRecord = (record, index) => {
        let cdt = new Date(record.cdt);
        cdt = cdt.setHours(cdt.getHours() - 8);
        let record_dt = moment(cdt).format("YYYY-MM-DD h:mm a");
        let message = record.diagnosis_msg;
        let item_num = (page-1) * itemsPerPage + index + 1;
        return (
            <tr key={`opt_diag_${index}`}>
                <td>{item_num}</td>
                <td>{record_dt}</td>
                <td>{message}</td>
            </tr>
        )
    }

    const renderRecordWrap = () => {
        if (diagRecords.length > 0){

            let from_item = page===1? 1: (page-1) * itemsPerPage + 1;
            let to_item = from_item + itemsPerPage - 1;
            let diag_in_page = diagRecords.filter(
                (element, index) => { 
                    return index+1 >= from_item && index+1 <= to_item
                });

            return diag_in_page.map((element, index) => {
                return renderRecord(element, index);
            })
        }
    }

    const renderContent = () =>{
        let smallfont = platform==="web"?"":"small_font";
        return (
            <Container className='py-1' id='opt_diagnosis_table'>
                <Table striped border="true" hover responsive className={`${smallfont}`}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Datetime</th>
                            <th>Logs</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRecordWrap()}
                    </tbody>
                </Table>
                <div className="myPagination myPag-b d-flex justify-content-center">
                    {
                        <PaginationComponent
                            itemsCount={diagRecords.length}
                            itemsPerPage={itemsPerPage}
                            currentPage={page}
                            setCurrentPage={setPage}
                            alwaysShown={true}
                        />
                    }
                </div>
            </Container>
        )
    }

    return (
        <Modal size="xl" fullscreen="lg-down" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Optimized Control History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderContent()}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

OptDiagnosis.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
};

export default OptDiagnosis;