import { Chart, registerables, Tooltip, Colors } from "chart.js";
import { numberWithCommas } from "./functions";
import moment from "moment";

export const chartRegister = () => {
  Chart.register(...registerables);
  Chart.register(Colors);
};

chartRegister();

////////////////////////////////////////////////////////////////////////
//Constants
////////////////////////////////////////////////////////////////////////
const font_color = "#555";

const font_size_x_tick = 15;
const font_size_y_tick = 15;
const font_size_y_title = 15;
const font_size_legend = 15; 
const font_size_tooltip = 15; 
const font_size_tooltip_title = 15; 

const line_width = 2;
const line_width_sm = 1.5;
const line_pt_radius = 2;
const line_pt_radius_sm = 1;

const box_size_legend = 10; 

const padding_tooltip = 15;
const padding_tooltip_box = 15;

const max_ticks_limit = 8;
const tooltip_titleMarginBottom = 10;

////////////////////////////////////////////////////////////////////////
//ChartColor
////////////////////////////////////////////////////////////////////////

const colorset_compare = ["#EDC948","#59A14F","#0D6EFD"];
const color_mono_blue = ["#3969D0", "#5A82D8", "#8BA7E4", "#407BE8", "#0C4EC3", "#0A40A0"];
const color_mono_orange = ["#CC5800", "#FF6F00", "#FF9747"];

export const setCompareLineColor = (dataset) => {
  if (dataset.length <= 0) return [];

  return dataset.map((set, i) => {
    let label = set["label"];
    let color = colorset_compare[i];
    return {
      ...set,
      backgroundColor: color,
      borderColor: color,
      borderWidth: line_width,
      fill: false,
      pointRadius: line_pt_radius,
      pointStyle: "circle",
    };
  });
};

export const setCompareLineColor_sm = (dataset) => {
  if (dataset.length <= 0) return [];

  return dataset.map((set, i) => {
    let label = set["label"];
    let color = colorset_compare[i];
    return {
      ...set,
      backgroundColor: color,
      borderColor: color,
      borderWidth: line_width_sm,
      fill: false,
      pointRadius: line_pt_radius_sm,
      pointStyle: "circle",
    };
  });
};

export const setBarLineColor = (dataset, use_default_color) => {
  if (dataset.length <= 0) return [];
  let num_bar = -1;
  let num_line = -1;

  let tmp = dataset.map((set, i) => {
    if (set["type"] === "bar") {
      num_bar++;
      if (use_default_color)
        return {
          ...set,
          backgroundColor: color_mono_blue[num_bar],
          borderWidth: 0,
          order: 2,
        };
      else
        return {
          ...set,
          borderWidth: 0,
          order: 2,
        };
    } else {
      num_line++;
      if (use_default_color)
        return {
          ...set,
          borderWidth: 4,
          borderColor: color_mono_orange[num_line],
          backgroundColor: color_mono_orange[num_line],
          fill: false,
          order: 1,
          pointRadius: 2,
          pointStyle: "circle",
          borderWidth: 2,
          pointBackgroundColor: color_mono_orange[num_line],
        };
      else
        return {
          ...set,
          borderWidth: 4,
          fill: false,
          order: 1,
          pointRadius: 2,
          pointStyle: "circle",
          borderWidth: 2,
        };
    }
  });

  return tmp;
};

////////////////////////////////////////////////////////////////////////
//ChartOption
////////////////////////////////////////////////////////////////////////
Tooltip.positioners.bottom = function (items) {
  const pos = Tooltip.positioners.average(items);

  // Happens when nothing is found
  if (pos === false) {
    return false;
  }

  const chart = this.chart;

  return {
    x: pos.x,
    y: chart.chartArea.bottom,
    xAlign: "center",
    yAlign: "bottom",
  };
};

export const getCompareLineOption = (y_label="", y_unit="", x_label="", x_display=false) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: x_display,
        labels: x_label,
        ticks: {
          color: font_color,
          font: {
            size: font_size_x_tick ,
          },
        },
      },
      y: {
        display: true,
        type: "linear",
        title: {
          display: true,
          text: y_label,
          color: font_color,
          font: {
            size: font_size_y_title ,
          },
        },
        ticks: {
          color: font_color,
          font: {
            size: font_size_y_tick,
          },
          callback: function (value, index, values) {
            return numberWithCommas(value, 0);
          },
          maxTicksLimit: max_ticks_limit,
          precision: 0,
        },
        beginAtZero: false,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          color: font_color,
          font: {
            size: font_size_legend,
          },
          boxWidth: box_size_legend,
          boxHeight: box_size_legend,
        },
      },
      tooltip: {
        mode: "nearest",
        axis: "x",
        position: "average",
        intersect: false,
        // yAlign: "center",
        padding: padding_tooltip,
        boxPadding: padding_tooltip_box,
        titleFont: {
          size: font_size_tooltip_title,
        },
        bodyFont: {
          size: font_size_tooltip,
        },
        titleMarginBottom: tooltip_titleMarginBottom,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            var currentValue = context.raw;
            var value = numberWithCommas(currentValue, 1);

            return `${label}: ${value} ${y_unit} `;
          },
        },
      },
    },
  };
};

export const getBarLineOption = (
  unit,
  decimal_places,
  y_title_left,
  y_title_right,
  arrayHidden
) => {
  let cust_y_title_left = {
    display: false,
  };
  if (y_title_left) {
    cust_y_title_left = {
      display: true,
      text: y_title_left,
      color: font_color,
      font: {
        size: font_size_y_title
      },
    };
  }
  let cust_y_title_right = {
    display: false,
  };
  if (y_title_right) {
    cust_y_title_right = {
      display: true,
      text: y_title_right,
      color: font_color,
      font: {
        size: font_size_y_title
      },
    };
  }
  return {
    maintainAspectRatio: false,
    responsive: true,
    tension: 0.2,
    scales: {
      x: {
        ticks: {
          color: font_color,
          font: {
            size: font_size_x_tick
          },
        },
      },
      "y-axis-1": {
        display: true,
        position: "left",
        title: cust_y_title_left,
        ticks: {
          color: font_color,
          font: {
            size: font_size_y_tick,
          },
          callback: function (label, index, labels) {
            var currentValue = numberWithCommas(label, 0);
            return `${currentValue}`;
          },
          autoSkip: true,
          maxTicksLimit: max_ticks_limit,
          precision: 0,
        },
      },
      "y-axis-2": {
        display: true,
        position: "right",
        title: cust_y_title_right,
        ticks: {
          color: font_color,
          font: {
            size: font_size_y_tick,
          },
          callback: function (label, index, labels) {
            var currentValue = numberWithCommas(label, 0);
            return `${currentValue}`;
          },
          autoSkip: true,
          maxTicksLimit: max_ticks_limit,
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "center",
        labels: {
          color: font_color,
          font: {
            size: font_size_legend
          },
          boxWidth: box_size_legend,
          boxHeight: box_size_legend,
          filter: function (item, chart) {
            if (!arrayHidden) return true;
            return !(arrayHidden.indexOf(item.text) > -1);
          },
        },
      },
      tooltip: {
        mode: "index",
        position: "bottom",
        intersect: false,
        yAlign: "center",
        padding: padding_tooltip,
        boxPadding: padding_tooltip_box,
        titleFont: {
          size: font_size_tooltip_title,
        },
        bodyFont: {
          size: font_size_tooltip,
        },
        titleMarginBottom: tooltip_titleMarginBottom,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";

            if (arrayHidden && arrayHidden.indexOf(label) > -1) return "";

            var currentValue = context.raw;
            var value = numberWithCommas(currentValue, decimal_places);
            let unit_c = context.dataset.unit;
            if (unit_c) return `${label}: ${value} ${unit_c}`;
            else return `${label}: ${value} ${unit}`;
          },
        },
      },
    },
  };
};

export const getBarOption = (
  unit,
  decimal_places,
  y_title,
  arrayHidden
) => {
  let cust_y_title = {
    display: false,
  };
  if (y_title) {
    cust_y_title = {
      display: true,
      text: y_title,
      color: font_color,
      font: {
        size: font_size_y_title
      },
    };
  }
  return {
    maintainAspectRatio: false,
    responsive: true,
    tension: 0.2,
    scales: {
      x: {
        ticks: {
          color: font_color,
          font: {
            size: font_size_x_tick
          },
        },
        stacked: true,
      },
      y: {
        title: cust_y_title,
        ticks: {
          color: font_color,
          font: {
            size: font_size_y_tick
          },
          callback: function (label, index, labels) {
            var value = numberWithCommas(label, 0);
            return value;
          },
          maxTicksLimit: max_ticks_limit,
          precision: 0,
        },
        beginAtZero: true,
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "center",
        labels: {
          color: font_color,
          font: {
            size: font_size_legend
          },
          boxWidth: box_size_legend,
          boxHeight: box_size_legend,
          filter: function (item, chart) {
            if (!arrayHidden) return true;
            return !(arrayHidden.indexOf(item.text) > -1);
          },
        },
      },
      tooltip: {
        mode: "index",
        position: "bottom",
        intersect: false,
        yAlign: "center",
        padding: padding_tooltip,
        boxPadding: padding_tooltip_box,
        titleFont: {
          size: font_size_tooltip_title,
        },
        bodyFont: {
          size: font_size_tooltip,
        },
        titleMarginBottom: tooltip_titleMarginBottom,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";

            if (arrayHidden && arrayHidden.indexOf(label) > -1) return "";

            var currentValue = context.raw;
            var value = numberWithCommas(currentValue, decimal_places);
            let unit_c = context.dataset.unit;
            if (unit_c) return `${label}: ${value} ${unit_c}`;
            else return `${label}: ${value} ${unit}`;
          },
        },
      },
    },
  };
};