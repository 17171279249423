import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, ProgressBar, Placeholder, Dropdown, Button, Nav, Badge } from "react-bootstrap";
import { FaPowerOff as StatusIcon, FaPercentage  as PercentIcon } from "react-icons/fa";
import { BsSnow2 as CoolLoadIcon, BsSpeedometer as SpeedIcon, BsThermometerHalf as TempIcon, BsDropletHalf as HumidIcon, BsCSquareFill as Co2Icon } from "react-icons/bs";
import { IoWater as WaterDropIcon, IoHandLeftOutline as AmsIcon } from "react-icons/io5";
import { TbTemperatureCelsius as CelsiusIcon } from "react-icons/tb";
import { FiWind as AirIcon } from "react-icons/fi";
import { BiWater as FlowIcon } from "react-icons/bi";
import { PiFanFill as FanIcon, PiDot as DotIcon } from "react-icons/pi";
import { GiValve as VlvIcon } from "react-icons/gi";

import { LiaWindowMaximize as DamperIcon } from "react-icons/lia";
import { PiArrowFatLineRightFill as RightArrorIcon } from "react-icons/pi";
import moment from "moment";

import { HARD_CODE_DATA, IS_HOSPITAL } from "../../../utils/variables"
import { CustomToggleMore } from "../../../utils/functions"

import ModeHistory from '../modal/ModeHistory';
import CustomProgressBar from '../chart/CustomProgressBar';

function RealTimeStatus({platform}) {

    const building = useSelector(root => root.data.building);
    const rtData = useSelector(root => root.data.realtimeData);

    const btns_mode = [
        { name: 'CCMS', value: 'CCMS' },
        { name: 'AI Mode', value: 'AI mode' },
        { name: 'AI/CCMS', value: 'AI/CCMS' }
    ];
    let default_chillers = ['CH1','CH2','CH3'];
    let default_pumps = ['CHWP1','CHWP2','CHWP3'];
    let default_ahus = ['AHU1','AHU2','AHU3'];
    let default_paus = ['PAU1','PAU2','PAU3'];
    const [section, setSection] = useState("link-chiller");
    const [current_mode_chiller, setCurrentModeChiller] = useState(null);
    const [current_mode_ahu, setCurrentModeAhu] = useState(null);
    const [obj_chillers, setObjChillers] = useState(null);
    const [pumps, setPumps] = useState([]);
    const [obj_pumps, setObjPumps] = useState(null);
    const [ahus, setAhus] = useState([]);
    const [obj_ahus, setObjAhus] = useState(null);
    const [paus, setPaus] = useState([]);
    const [obj_paus, setObjPaus] = useState(null);
    const [showModeHistoryModal, setShowModeHistoryModal] = useState(false);
    const [main_st_rt, setMainStRt] = useState(null);
    const [data_dt, setDataDt] = useState("---");
    const [out_temp, setOutTemp] = useState("---");
    const [out_humid, setOutHumid] = useState("---");

    useEffect(() => {
        if (building.rt_hardcode===1){
            dataProcess(HARD_CODE_DATA[building.building_id].realtime, new Date());
            setCurrentModeChiller("AI mode");
            setPumps(HARD_CODE_DATA[building.building_id].pumps);
        }
    }, [])

    useEffect(() => {
        let b_code = building.building_ibms_id.toLowerCase();
        let th_rtData = rtData[b_code];

        if (building.rt_hardcode===0 && th_rtData!==undefined){
            dataProcess(th_rtData, rtData.dt);
        }
    }, [rtData,building]);

    const dataProcess = (rtd, update_dt) => {
        if (rtd.ai === undefined)
            return false;

        let mode_chiller = rtd.ai.mode.mode_chiller;
        let mode_ahu = rtd.ai.mode.mode_ahu;
        let mode_history = rtd.mode_history;
        let dt = moment(update_dt).format("YYYY-MM-DD h:mm a");
        
        let outtemp = rtd.outdoor.temp===null? "---": rtd.outdoor.temp.toFixed(1);
        let outhumid = rtd.outdoor.humid===null? "---": rtd.outdoor.humid.toFixed(1);

        let ch_names = [];
        for (const [ch_name, chiller_obj] of Object.entries(rtd.chillers)) {
            ch_names.push(ch_name);
        }
        let pumps_names = [];
        for (const [pump_name, pump_obj] of Object.entries(rtd.pumps)) {
            pumps_names.push(pump_name);
        }

        let ahus_names = [];
        for (const [ahu_name, ahu_obj] of Object.entries(rtd.ahus)) {
            ahus_names.push(ahu_name);
        }

        let paus_names = [];
        for (const [pau_name, pau_obj] of Object.entries(rtd.paus)) {
            paus_names.push(pau_name);
        }

        setCurrentModeChiller(mode_chiller);
        setCurrentModeAhu(mode_ahu);
        // setModeRecords(mode_history);
        setObjChillers(rtd.chillers);
        setPumps(pumps_names);
        setObjPumps(rtd.pumps);
        setAhus(ahus_names);
        setObjAhus(rtd.ahus);
        setPaus(paus_names);
        setObjPaus(rtd.paus);
        setMainStRt([rtd.plant.st, rtd.plant.rt]);
        setDataDt(dt);
        setOutTemp(outtemp);
        setOutHumid(outhumid);
    }

    const sectionSelect = (section_name) => {
        setSection(section_name);
    };

    const renderTH = (sub_title) => {
        return (
            <Row className='mb-1'>
                <b>{sub_title}</b>
            </Row>
        )
    }

    const renderOperationMode = () => {
        return (
            <Container className='px-0'>
                {/* <ButtonGroup>
                    {btns_mode.map((radio, idx) => (
                        <ToggleButton
                            key={`radio-operation-mode-${platform}-${idx}`}
                            id={`radio-operation-mode-${platform}-${idx}`}
                            size="lg"
                            type="radio"
                            variant={idx % 2 ? 'outline-success' : 'outline-warning'}
                            name={`radio-operation-mode-${platform}`}
                            value={radio.value}
                            checked={current_mode_chiller === radio.value}
                            style={{"cursor": "initial"}}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup> */}
                {
                !IS_HOSPITAL &&
                <>
                <Row className='mt-0 mb-1'>
                    <Col xs={8} sm={8} md={8} lg={8}>
                        <TempIcon size={20} color="#034F84" style={{"marginTop": "-3px"}} className="me-1"/>
                        Outdoor Temp
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                        {out_temp} <CelsiusIcon style={{"marginTop": "-2px"}}/>
                    </Col>
                </Row>
                
                <Row className='mb-0'>
                    <Col xs={8} sm={8} md={8} lg={8}>
                        <HumidIcon size={17} color="#034F84" style={{"marginTop": "-3px"}} className="me-1"/>
                        Outdoor Humidity
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                        {out_humid} <PercentIcon style={{"marginTop": "-2px"}} size={12}/>
                    </Col>
                </Row>
                </>
                }
            </Container>
        )
    }

    const renderPlant = () => {
        let mainst = main_st_rt[0]? main_st_rt[0].toFixed(2): "---";
        let mainrt = main_st_rt[1]? main_st_rt[1].toFixed(2): "---";
        return (
            <>
            <Row className='mt-2 mb-1'>
                <Col xs={8} sm={8} md={8} lg={8}>
                    <WaterDropIcon size={20} color="#0D6EFD" style={{"marginTop": "-3px"}} className="me-1"/>
                    Main Supply Temp
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                    {mainst} <CelsiusIcon style={{"marginTop": "-2px"}}/>
                </Col>
            </Row>
            <Row className='mb-1'>
                <Col xs={8} sm={8} md={8} lg={8}>
                    <WaterDropIcon size={20} color="#008000" style={{"marginTop": "-3px"}} className="me-1"/>
                    Main Return Temp
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                    {mainrt} <CelsiusIcon style={{"marginTop": "-2px"}}/>
                </Col>
            </Row>
            </>
        )
    }

    const renderHide = (key) => {
        return (
            <Row key={`${key}`} className="py-1 mx-0" style={{ "width": "100%" }}>
                <Placeholder animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>
            </Row>
        )
    }

    const renderPlantWrap = () => {
        if (main_st_rt)
            return renderPlant();
        else 
            return (
                <>
                {renderHide("plant_hide_1")}
                {renderHide("plant_hide_2")}
                </>
            )
    }

    const renderChiller = (chiller, i) => {
        let status = false;
        let status_color = 'red';
        let coolload = 0;
        let st_val = "-";
        let ams_color = 'white';

        if (obj_chillers && obj_chillers[chiller]) {
            status = obj_chillers[chiller].status === 'active' ? true : false;
            status_color = status ? 'green' : 'red';
            coolload = obj_chillers[chiller].cool_load_ratio?obj_chillers[chiller].cool_load_ratio.toFixed(0):"";
            coolload = coolload > 100 ? 100 : coolload < 0 ? 0: coolload;
            st_val = obj_chillers[chiller].st===null?"-":obj_chillers[chiller].st.toFixed(1);
            ams_color = obj_chillers[chiller].ams==="manual"? 'red': obj_chillers[chiller].ams==="auto"? 'grey': 'white';
        }

        return (
            <Row key={`ch_status_${i}`} className="chiller_wrap py-1 mx-0" style={{ "width": "100%" }}>
                <Col xs={3} sm={2} md={2} lg={3} xl={2} className="px-0">
                    {chiller}
                </Col>
                <Col xs={3} sm={2} md={3} lg={3} xl={2} className="px-0">
                    <StatusIcon color={status_color} />&ensp;
                    {building.rt_manual_bypass && <AmsIcon color={ams_color} />}
                </Col>
                <Col xs={6} sm={2} md={3} lg={6} xl={3} className="px-0 text-left">
                    <WaterDropIcon size={18} color="#0D6EFD" style={{"marginTop": "-3px"}} className=""/> 
                    {st_val}
                    <CelsiusIcon/>
                </Col>
                <Col xs={12} sm={6} md={4} lg={12} xl={5} className="px-0">
                    <Row className="ps-2">
                        <Col xs={3} sm={2} md={2} lg={2} xl={2} className="px-0"><CoolLoadIcon color='#034f84' style={{marginTop: "-3px"}}/></Col>
                        <Col xs={8} sm={8} md={8} lg={9} xl={8} className="pt-1 px-0">
                            <ProgressBar animated now={coolload} label={`${coolload}%`} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    const renderChillerWrap = () => {
        let ch_list = building.chillers? building.chillers: [];
        if (building.rt_hardcode===1){
            ch_list = HARD_CODE_DATA[building.building_id].chillers;
        }
        if (ch_list.length > 0) {
            return ch_list.map((element, index) => {
                return renderChiller(element, index)
            })
        }
        else {
            return default_chillers.map((element, index) => {
                return renderHide(`${element}_hide_${index}`)
            })
        }
    }

    const renderSetpoint = (chiller, i) => {
        let name = chiller;
        let st_val = "-";

        if (obj_chillers && obj_chillers[chiller]) {
            st_val = obj_chillers[chiller].st===null?"-":obj_chillers[chiller].st.toFixed(1);
        }

        return (
            <Col key={`ch_status_${i}`} className="chiller_wrap py-1 mx-0">
                {name}
                <WaterDropIcon size={18} color="#0D6EFD" style={{"marginTop": "-3px"}} className=""/> 
                <br className='d-block d-sm-block d-md-none'/>
                {st_val}
                <CelsiusIcon/>
            </Col>
        )
    }

    const renderPump = (pump, i) => {
        let status = false;
        let status_color = 'red';
        let speed = 0;
        let speed_min = building.pump_spd_min;
        let speed_max = building.pump_spd_max;
        let speed_text = "";
        let ams_color = "white"

        if (obj_pumps && obj_pumps[pump]) {
            status = obj_pumps[pump].status === 'active' ? true : false;
            status_color = status ? 'green' : 'red';
            speed = obj_pumps[pump].speed;
            speed_text = obj_pumps[pump].speed? obj_pumps[pump].speed.toFixed(0):"";
            ams_color = obj_pumps[pump].ams==="manual"? 'red': obj_pumps[pump].ams==="auto"? 'grey': 'white';
        }

        return (
            <Row key={`pump_status_${i}`} className="pump_wrap py-1 mx-0" style={{ "width": "100%" }}>
                <Col xs={3} sm={3} md={3} lg={6} xl={3} className="px-0">
                    {pump}
                </Col>
                <Col xs={3} sm={1} md={3} lg={6}  xl={2} className="px-0">
                    <StatusIcon color={status_color} />&ensp;
                    {building.rt_manual_bypass && <AmsIcon color={ams_color} />}
                </Col>
                <Col xs={6} sm={8} md={6} lg={12} xl={7} className="px-0">
                    <Row className="px-2">
                        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="px-0"><SpeedIcon color='#034f84' /></Col>
                        <Col xs={9} sm={9} md={8} lg={9} xl={9} className="pt-1 px-0">
                            <ProgressBar animated now={speed} label={`${speed_text}Hz`} min={`${speed_min}`} max={`${speed_max}`} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    const renderPumpWrap = () => {
        if (pumps.length > 0) {
            return pumps.map((element, index) => {
                return renderPump(element, index)
            })
        }
        else {
            return default_pumps.map((element, index) => {
                return renderHide(`${element}_hide_${index}`)
            })
        }
    }

    const renderAhu = (ahu, i) => {

        let status = false;
        let status_color = 'red';
        let speed = 0;
        let speed_min = building.pump_spd_min;
        let speed_max = building.pump_spd_max;
        let speed_text = "";
        let sa_temp_val = "-";
        let ra_temp_val = "-";
        let fa_temp_val = "-";
        let co2_val = "-";
        let chwfr_val = "-";
        let chwit_val = "-";
        let chwot_val = "-";
        let sa_fr_val = "-";
        let ra_fr_val = "-";
        let fa_fr_val = "-";
        let fads = 0;
        let fads_text = "";
        let rads = 0;
        let rads_text = "";
        let vlvs = 0;
        let vlvs_text = "";
        let mode = "-";

        if (obj_ahus && obj_ahus[ahu]) {
            status = obj_ahus[ahu].kw > 0 ? true : false;
            status_color = status ? 'green' : 'red';
            speed = obj_ahus[ahu].fan_spd;
            speed_text = speed? speed.toFixed(0):"";
            sa_temp_val = obj_ahus[ahu].sa_temp===null?"-":obj_ahus[ahu].sa_temp.toFixed(1);
            ra_temp_val = obj_ahus[ahu].ra_temp===null?"-":obj_ahus[ahu].ra_temp.toFixed(1);
            fa_temp_val = obj_ahus[ahu].fa_temp===null?"-":obj_ahus[ahu].fa_temp.toFixed(1);
            co2_val = obj_ahus[ahu].co2===null?"-":obj_ahus[ahu].co2.toFixed(0);
            sa_fr_val = obj_ahus[ahu].sa_fr===null?"-":obj_ahus[ahu].sa_fr.toFixed(0);
            ra_fr_val = obj_ahus[ahu].ra_fr===null?"-":obj_ahus[ahu].ra_fr.toFixed(0);
            fa_fr_val = obj_ahus[ahu].fa_fr===null?"-":obj_ahus[ahu].fa_fr.toFixed(0);
            chwfr_val = obj_ahus[ahu].chwfr===null?"-":obj_ahus[ahu].chwfr.toFixed(1);
            chwit_val = obj_ahus[ahu].chwit===null?"-":obj_ahus[ahu].chwit.toFixed(1);
            chwot_val = obj_ahus[ahu].chwot===null?"-":obj_ahus[ahu].chwot.toFixed(1);
            fads = obj_ahus[ahu].fads;
            fads_text = fads? fads.toFixed(0):"";
            rads = obj_ahus[ahu].rads;
            rads_text = rads? rads.toFixed(0):"";
            vlvs = obj_ahus[ahu].vlvs;
            vlvs_text = vlvs? vlvs.toFixed(0):"";
            mode = obj_ahus[ahu].mode;
        }

        return (
            <Row key={`ahu_status_${i}`} className="ahu_wrap pt-2 pb-2 mx-0" style={{ "width": "100%" }}>
                <Col xs={1} sm={2} md={1} lg={1} xl={1} className="px-0 my-auto">
                    <Row>
                        <Col>{ahu}</Col>
                    </Row>
                    <Row>
                        <Col><StatusIcon color={status_color} /></Col>
                    </Row>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1} className="pe-0 ps-1">
                    <div className="d-flex" style={{height: "100%"}}>
                        <div className="vr"></div>
                    </div>
                </Col>
                <Col xs={10} sm={9} md={9} lg={9} xl={9} className="px-0" style={{fontSize: "15px"}}>
                    <Row>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} className="px-0">
                            <Row className="px-0">
                                <Col xs={2} sm={2} md={2} lg={3} xl={2} className="px-0"><FanIcon color='#034f84' /></Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} className="pt-1 px-0">
                                    <ProgressBar animated now={speed} label={`${speed_text}Hz`} min={`${speed_min}`} max={`${speed_max}`} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="px-0 text-left">
                            {
                                mode===btns_mode[0]['value'] && 
                                <h6 className='mb-0'><Badge bg="warning" pill style={{fontSize: "xx-small"}}>{btns_mode[0]['name']}</Badge></h6>
                            }
                            {
                                mode===btns_mode[1]['value'] && 
                                <h6 className='mb-0'><Badge bg="success" pill style={{fontSize: "xx-small"}}>{btns_mode[1]['name']}</Badge></h6>
                            }
                            {
                                mode!==btns_mode[0]['value'] && 
                                mode!==btns_mode[1]['value'] && 
                                <h6 className='mb-0'><Badge pill className='ai_ccms' style={{fontSize: "xx-small"}}>{btns_mode[2]['name']}</Badge></h6>
                            }
                        </Col>
                    </Row>

                    <Row className="pt-1">
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} className="px-0 text-left">
                            <AirIcon size={18} color="#0D6EFD" style={{"marginTop": "-3px"}} className=""/>&thinsp;
                            {sa_temp_val}<CelsiusIcon/><DotIcon/>{sa_fr_val} <span style={{fontSize: "x-small"}}><b>L/s</b></span>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="px-0 text-left">
                            <Co2Icon size={15} color="#b9936c" style={{"marginTop": "-3px"}} className=""/>&thinsp;
                            {co2_val} 
                        </Col>
                    </Row>

                    <Row className="pt-1">
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} className="px-0 text-left">
                            <AirIcon size={18} color="#008000" style={{"marginTop": "-3px"}} className=""/>&thinsp;
                            {ra_temp_val}<CelsiusIcon/><DotIcon/>{ra_fr_val} <span style={{fontSize: "x-small"}}><b>L/s</b></span>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="px-0 text-left">
                            <DamperIcon color="#008000" style={{"marginTop": "-3px"}} size={18}/> 
                            <CustomProgressBar 
                                propName={`${ahu}_rdamp_rt`}
                                value={rads}
                                platform={platform}
                                color="#008000"
                            />
                            <div style={{display: "contents", fontSize: "smaller"}}>{rads_text}<PercentIcon size={10}/></div>
                        </Col>
                    </Row>
                    <Row className="pt-1">
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} className="px-0 text-left">
                            <AirIcon size={18} color="#e65c00" style={{"marginTop": "-3px"}} className=""/>&thinsp;
                            {fa_temp_val}<CelsiusIcon/><DotIcon/>{fa_fr_val} <span style={{fontSize: "x-small"}}><b>L/s</b></span>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="px-0 text-left">
                            <DamperIcon color="#e65c00" style={{"marginTop": "-3px"}} size={18}/> 
                            <CustomProgressBar 
                                propName={`${ahu}_fdamp_rt`}
                                value={fads}
                                platform={platform}
                                color="#e65c00"
                            />
                            <div style={{display: "contents", fontSize: "smaller"}}>{fads_text}<PercentIcon size={10}/></div>
                        </Col>
                    </Row>

                    <Row className="pt-1">
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} className="px-0 text-left">
                            <FlowIcon size={18} color="#034f84" style={{"marginTop": "-3px"}} className=""/>&thinsp;
                            {chwit_val}<CelsiusIcon/><RightArrorIcon style={{"marginTop": "-3px"}} />{chwot_val}<CelsiusIcon/>
                            <DotIcon/>{chwfr_val}<span style={{fontSize: "x-small"}}><b>L/s</b></span>&thinsp;
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="px-0 text-left">
                            <VlvIcon color="#034f84" style={{"marginTop": "-3px"}}/>&thinsp;
                            <CustomProgressBar 
                                propName={`${ahu}_vlv_rt`}
                                value={vlvs}
                                platform={platform}
                                color="#034f84"
                            />
                            <div style={{display: "contents", fontSize: "smaller"}}>{vlvs_text}<PercentIcon size={10}/></div>
                        </Col>
                    </Row>                    
                </Col>
            </Row>
        )
    }

    const renderAhuWrap = () => {
        if (ahus.length > 0) {
            return ahus.map((element, index) => {
                return renderAhu(element, index)
            })
        }
        else {
            return default_ahus.map((element, index) => {
                return renderHide(`${element}_hide_${index}`)
            })
        }
    }

    const renderPau = (pau, i) => {

        let status = false;
        let status_color = 'red';
        let sa_temp_val = "-";
        let chwfr_val = "-";

        if (obj_paus && obj_paus[pau]) {
            status = obj_paus[pau].kw > 0 ? true : false;
            status_color = status ? 'green' : 'red';
            sa_temp_val = obj_paus[pau].sa_temp===null?"-":obj_paus[pau].sa_temp.toFixed(1);
            chwfr_val = obj_paus[pau].chwfr===null?"-":obj_paus[pau].chwfr.toFixed(1);
        }

        return (
            <Row key={`pau_status_${i}`} className="pau_wrap pt-2 pb-1 mx-0" style={{ "width": "100%" }}>
                <Col xs={2} sm={2} md={2} lg={2} xl={2} className="px-0 my-auto">
                    <Row>
                        <Col>{pau}</Col>
                    </Row>
                    <Row>
                        <Col><StatusIcon color={status_color} /></Col>
                    </Row>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1} className="px-0">
                    <div className="d-flex" style={{height: "100%"}}>
                        <div className="vr"></div>
                    </div>
                </Col>
                <Col xs={9} sm={9} md={9} lg={9} xl={9} className="px-0">
                    <Row className="pt-2">
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} className="px-0 text-center">
                            <AirIcon size={18} color="#0D6EFD" style={{"marginTop": "-3px"}} className=""/>&thinsp;
                            {sa_temp_val}
                            <CelsiusIcon/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} className="px-0 text-left">
                            <FlowIcon size={18} color="#034f84" style={{"marginTop": "-3px"}} className=""/>&thinsp;
                            {chwfr_val} <span style={{fontSize: "x-small"}}><b>L/s</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    const renderPauWrap = () => {
        if (paus.length > 0) {
            return paus.map((element, index) => {
                return renderPau(element, index)
            })
        }
        else {
            return default_paus.map((element, index) => {
                return renderHide(`${element}_hide_${index}`)
            })
        }
    }

    const renderChillerSection = () => {
        return (
            <div className='pt-2 pb-0 px-2'>
                <Row>
                    <Col>
                        {renderTH("Chiller Plant")}
                        {renderPlantWrap()}
                    </Col>
                </Row>
                <hr className='mx-2'/>
                <Row>
                    <Col>
                        {platform==="mobile" && renderTH("Chiller Supply Temp / Cool. Load")}
                        {platform==="web" && renderTH("Chiller Supply Temp / Cooling Load")}
                        {renderChillerWrap()}
                        {
                            building.rt_manual_bypass &&
                            <div className='mt-2' style={{fontSize: "small"}}>
                                Manual Bypass &ensp;
                                <AmsIcon size={14} color="red" style={{"marginTop": "-3px"}}/> Enable&ensp;
                                <AmsIcon size={14} color="grey" style={{"marginTop": "-3px"}}/> Disable
                            </div>
                        }
                    </Col>
                </Row>
                <hr className='mx-2'/>
                <Row>
                    <Col>
                        {renderTH("Chilled Water Pump Speed")}
                        {renderPumpWrap()}
                        {
                            building.rt_manual_bypass &&
                            <div className='mt-2' style={{fontSize: "small"}}>
                                Manual Bypass &ensp;
                                <AmsIcon size={14} color="red" style={{"marginTop": "-3px"}}/> Enable&ensp;
                                <AmsIcon size={14} color="grey" style={{"marginTop": "-3px"}}/> Disable
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        )
    }

    const renderAHUSection = () => {
        return (
            <div className='pt-2 pb-0 px-2'>
                <Row>
                    <Col>
                        {/* {renderTH("Air Handling Unit")} */}
                        {renderAhuWrap()}
                        <hr/>
                        <div className='mt-3' style={{fontSize: "small"}}>
                            <AirIcon size={18} color="#0D6EFD" style={{"marginTop": "-3px"}} className=""/> Supply Air&ensp;
                            <AirIcon size={18} color="#008000" style={{"marginTop": "-3px"}} className=""/> Return Air&ensp;
                            <AirIcon size={18} color="#e65c00" style={{"marginTop": "-3px"}} className=""/> Fresh Air&ensp;
                            <FanIcon size={16} color="#034f84" style={{"marginTop": "-3px"}} className=""/> Fan&ensp;
                        </div>
                        <div className='mt-1' style={{fontSize: "small"}}>
                            <FlowIcon size={18} color="#034f84" style={{"marginTop": "-3px"}} className=""/> Chilled Water&ensp;
                            <Co2Icon size={15} color="#b9936c" style={{"marginTop": "-3px"}} className=""/> CO<sub>2</sub>&ensp;
                            <DamperIcon size={18} color="#034f84" style={{"marginTop": "-3px"}} className=""/> Damper&ensp;
                            <VlvIcon size={16} color="#034f84" style={{"marginTop": "-3px"}} className=""/> Valve&ensp;
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const renderPAUSection = () => {
        return (
            <div className='pt-2 pb-0 px-2'>
                <Row>
                    <Col>
                        {renderTH("Primary Air Handling Unit")}
                        {renderPauWrap()}
                        <hr/>
                        <div className='mt-3' style={{fontSize: "small"}}>
                            <AirIcon size={18} color="#0D6EFD" style={{"marginTop": "-3px"}} className=""/> Supply Air&ensp;
                            <FlowIcon size={18} color="#034f84" style={{"marginTop": "-3px"}} className=""/> Chilled Water Flow Rate&ensp;
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <Container className='card_bg mt-2 py-3 px-0'>
            <div className='card_title_wrap pt-2 pb-1 px-2'>
                <h5 className='mb-0'><b>Real Time Status</b></h5>
                <div style={{fontSize: "xx-small", color: "grey"}}>{data_dt}</div>
                <Dropdown className='btn_show_more d-none d-sm-none d-md-block px-2'>
                    <Dropdown.Toggle id="dropdown-button-dark-example1" as={CustomToggleMore} >
                        Custom Toggle
                    </Dropdown.Toggle>
                    <Dropdown.Menu align={{md: "start"}}>
                        <Dropdown.Item eventKey="1" onClick={()=>setShowModeHistoryModal(true)}>Show More</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown >
            </div>
            <hr className='mt-0'/>
            <Row>
                {renderOperationMode()}
            </Row>
            
            <div className='container_tab mb-2 mt-3'>
                <Nav fill variant="tabs" defaultActiveKey="link-chiller" onSelect={sectionSelect}>
                    <Nav.Item>
                        <Nav.Link eventKey="link-chiller">
                            Chiller
                            {
                                current_mode_chiller===btns_mode[0]['value'] && 
                                <h6 className='mb-0'><Badge bg="warning" pill >{btns_mode[0]['name']}</Badge></h6>
                            }
                            {
                                current_mode_chiller===btns_mode[1]['value'] && 
                                <h6 className='mb-0'><Badge bg="success" pill >{btns_mode[1]['name']}</Badge></h6>
                            }
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-ahu">
                            AHU
                            {
                                current_mode_ahu===btns_mode[0]['value'] && 
                                <h6 className='mb-0'><Badge bg="warning" pill >{btns_mode[0]['name']}</Badge></h6>
                            }
                            {
                                current_mode_ahu===btns_mode[1]['value'] && 
                                <h6 className='mb-0'><Badge bg="success" pill >{btns_mode[1]['name']}</Badge></h6>
                            }
                            {
                                current_mode_ahu!==null &&
                                current_mode_ahu!==btns_mode[0]['value'] && 
                                current_mode_ahu!==btns_mode[1]['value'] && 
                                <h6 className='mb-0'><Badge pill className='ai_ccms'>{btns_mode[2]['name']}</Badge></h6>
                            }
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-pau" style={{height: "101%"}}>PAU</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

            {section==="link-chiller" && renderChillerSection()}
            {section==="link-ahu" && renderAHUSection()}
            {section==="link-pau" && renderPAUSection()}

            <hr className='d-sm-block d-md-none'/>
            <Row className='d-sm-block d-md-none'>
                <Col>
                    <Button variant="secondary" onClick={()=>setShowModeHistoryModal(true)}>Mode History</Button>{' '}
                </Col>
            </Row>
            <ModeHistory
                setShowModal={setShowModeHistoryModal}
                showModal={showModeHistoryModal}
                platform={platform}/>
        </Container>
    )
}

export default RealTimeStatus;