import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Modal, Button, Table } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";

import {
    TbSquareRoundedNumber1Filled as SeqIcon1,
    TbSquareRoundedNumber2Filled as SeqIcon2,
    TbSquareRoundedNumber3Filled as SeqIcon3,
    TbSquareRoundedNumber4Filled as SeqIcon4,
    TbSquareRoundedNumber5Filled as SeqIcon5,
    TbSquareRoundedNumber6Filled as SeqIcon6,
    TbSquareRoundedNumber7Filled as SeqIcon7,
    TbTemperatureCelsius as CelsiusIcon
} from "react-icons/tb";
import { FaTemperatureHalf as TempIcon } from "react-icons/fa6";
import { BsSpeedometer as SpeedIcon, BsThermometerSnow as FreeCoolIcon } from "react-icons/bs";
import { GiBrain as AIIcon } from "react-icons/gi";

import { API_URL, IS_HOSPITAL, HARD_CODE_DATA } from "../../../utils/variables";
import PaginationComponent from '../../components/pagination/Pagination';
import { axiosWithXAuth } from "../../../utils/functions";

const  OptSequenceHistory = ({
    showModal = false,
    setShowModal,
    platform = "web",
    recordnum = 10
}) => {

    const handleClose = () => setShowModal(false);

    const building = useSelector(root => root.data.building);

    const [suggestions, setSuggestions] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = recordnum;

    useEffect(() => {
        setPage(1);
        if (showModal){
            callOptSuggestions();
        }
    }, [showModal])

    useEffect(() => {
        if (page<1)
            setPage(1);
    }, [page])

    const callOptSuggestions = async () => {
        
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/optSuggestions`,
            data: {
                "b_id": building.building_id,
                "b_code": building.building_ibms_id.toLowerCase()
            }
        })
        .then((response) => {
            let data = response.data;
            let result = []
            data.forEach((element, index) => {
                result.push({
                    "cdt": element.cdt,
                    "opt_demand": element.opt_demand,
                    "opt_st": element.opt_st,
                    "opt_pump_spd": element.opt_pump_spd,
                    "opt_sequence_list": element.opt_sequence_list,
                    "opt_pred_outtemp": element.opt_pred_outtemp,
                    "opt_pred_active_power": element.opt_pred_active_power,
                    "opt_pred_kwh": element.opt_pred_kwh,
                    "opt_pred_cop": element.opt_pred_cop,
                    "mode": element.mode,
                    "actual_power": element.actual_power,
                    "actual_cop": element.actual_cop,
                    "is_free_cool": element.is_free_cool
                })
            })
            setSuggestions(result);
        })
    }

    const renderBubble = (name, isOn) => {
        let color = isOn ? "green" : "grey";
        let iconsize = platform==="web"?35:22;
        let icon = name === "1" ? <SeqIcon1 color={color} size={iconsize} /> :
            name === "2" ? <SeqIcon2 color={color} size={iconsize} /> :
            name === "3" ? <SeqIcon3 color={color} size={iconsize} /> :
            name === "4" ? <SeqIcon4 color={color} size={iconsize} /> :
            name === "5" ? <SeqIcon5 color={color} size={iconsize} /> :
            name === "6" ? <SeqIcon6 color={color} size={iconsize} /> :
            null;
        return (
            <span key={`bubble_${name}`}>
                {icon}
            </span>
        )
    }

    const renderBubbles = (sequence, demand) => {
        demand = parseInt(demand);
        return sequence.map((element, index) => {
            let name = element;
            let isOn = index < demand ? true : false;
            return renderBubble(name, isOn);
        })
    }

    const renderSuggestion = (suggest, index) => {
        let cdt = new Date(suggest.cdt);
        // cdt = cdt.setHours(cdt.getHours() - 8);
        let opt_dt = moment(cdt).format("YYYY-MM-DD h:mm a");
        let temp_sp = suggest.opt_st !== null ? suggest.opt_st.toFixed(1): null;
        let speed_sp = suggest.opt_pump_spd !== null? suggest.opt_pump_spd.toFixed(0): null;
        let default_ch_list = [];
        if ((suggest.opt_sequence_list!==null && suggest.opt_sequence_list.length > 0) || building.rt_hardcode===0){
            default_ch_list = building.chillers.map((element, index) => (index+1).toString());
        }
        else{
            default_ch_list = HARD_CODE_DATA[building.building_id].chillers.map((element, index) => (index+1).toString());
        }
        let outtemp = suggest.opt_pred_outtemp !== null? suggest.opt_pred_outtemp.toFixed(1): null;
        // let active_power = suggest.opt_pred_active_power !== null? suggest.opt_pred_active_power.toFixed(0): null;
        let tt_power = suggest.opt_pred_active_power !== null? suggest.opt_pred_active_power.toFixed(1): null;
        let cop = suggest.opt_pred_cop !== null? suggest.opt_pred_cop.toFixed(2): null;

        let actual_power = suggest.actual_power !== null? suggest.actual_power.toFixed(1): null;
        let actual_cop = suggest.actual_cop !== null? suggest.actual_cop.toFixed(2): null;

        let item_num = (page-1) * itemsPerPage + index + 1;
        return (
            <tr key={`opt_sug_${index}`}>
                <td>{item_num}
                    {
                        suggest.mode==="AI" && 
                        <>&ensp;
                        <AIIcon size={16} color="#198754" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                    {
                        suggest.is_free_cool===1 && 
                        <>&ensp;
                        <FreeCoolIcon size={16} color="#0D6EFD" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                </td>
                <td>{opt_dt}</td>
                <td>
                    {suggest.opt_demand===0 && <>{renderBubbles(default_ch_list, 0)}</>}
                    {suggest.opt_demand>0 && renderBubbles(suggest.opt_sequence_list, suggest.opt_demand)}
                </td>
                <td>
                    {platform==="web" && temp_sp && <><TempIcon color='#0D6EFD' /> {temp_sp} </>}
                    {platform==="mobile" && temp_sp && <>{temp_sp} </>}
                </td>
                <td>
                    {platform==="web" && speed_sp && <><SpeedIcon color='#034f84' /> {speed_sp} </>} 
                    {platform==="mobile" && speed_sp && <>{speed_sp} </>} 
                </td>
                {!IS_HOSPITAL && <td style={{"borderLeft": "1px solid #cdd0d4"}}>{outtemp} </td>}
                {!IS_HOSPITAL && <td>{tt_power}</td>}
                {!IS_HOSPITAL && <td>{cop}</td>}
                {!IS_HOSPITAL && <td style={{"borderLeft": "1px solid #cdd0d4"}}>{actual_power}</td>}
                {!IS_HOSPITAL && <td>{actual_cop}</td>}
            </tr>
        )
    }

    const renderSuggestionWrap = () => {
        if (suggestions.length > 0){

            let from_item = page===1? 1: (page-1) * itemsPerPage + 1;
            let to_item = from_item + itemsPerPage - 1;
            let suggestions_in_page = suggestions.filter(
                (element, index) => { 
                    return index+1 >= from_item && index+1 <= to_item
                });

            return suggestions_in_page.map((element, index) => {
                return renderSuggestion(element, index);
            })
        }
    }

    const renderContent = () =>{
        let smallfont = platform==="web"?"":"small_font";
        return (
            <Container className='py-1'>
                <Table striped border="true" hover responsive className={`mb-0 ${smallfont}`}>
                    <thead>
                        <tr>
                            <th rowSpan={2}>#</th>
                            <th rowSpan={2}>Datetime</th>
                            <th rowSpan={2}>
                                {platform==="web" && "Chiller Sequence"}
                                {platform==="mobile" && "CH Seq."}
                            </th>
                            <th rowSpan={2}>
                                {platform==="web" && "Supply Temp (°C)"}
                                {platform==="mobile" && "ST (°C)"}
                            </th>
                            <th rowSpan={2}>
                                {platform==="web" && "Pump Speed (Hz)"}
                                {platform==="mobile" && "Pump Spd (Hz)"}
                            </th>
                            {!IS_HOSPITAL && <th colSpan={3} style={{"textAlign": "center", "borderLeft": "1px solid #cdd0d4"}}>Prediction</th>}
                            {!IS_HOSPITAL && <th colSpan={2} style={{"textAlign": "center", "borderLeft": "1px solid #cdd0d4"}}>Actual</th>}
                        </tr>
                        {   !IS_HOSPITAL && 
                            <tr>
                                <th style={{"borderLeft": "1px solid #cdd0d4"}}>
                                    {platform==="web" && "Outdoor Temp (°C)"}
                                    {platform==="mobile" && "Out. Temp (°C)"}
                                </th>
                                <th>Total Power (kW)</th>
                                <th>COP</th>
                                <th style={{"borderLeft": "1px solid #cdd0d4"}}>Total Power (kW)</th>
                                <th>COP</th>
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {renderSuggestionWrap()}
                    </tbody>
                </Table>
                <div className='text-left mb-2' style={{fontSize: "small"}}>
                    <AIIcon size={14} color="#198754" style={{"marginTop": "-3px"}}/> AI mode&ensp;
                    <FreeCoolIcon size={14} color="#0D6EFD" style={{"marginTop": "-3px"}}/> Free Cooling
                </div>
                <div className="myPagination myPag-b d-flex justify-content-center">
                    {
                        <PaginationComponent
                            itemsCount={suggestions.length}
                            itemsPerPage={itemsPerPage}
                            currentPage={page}
                            setCurrentPage={setPage}
                            alwaysShown={false}
                        />
                    }
                </div>
            </Container>
        )
    }

    return (
        <Modal size="xl" fullscreen="lg-down" show={showModal} onHide={handleClose} id="opt_ctrl_history">
            <Modal.Header closeButton>
            <Modal.Title>Optimized Control History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderContent()}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

OptSequenceHistory.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
};

export default OptSequenceHistory;