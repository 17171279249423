import moment from "moment";
import { dataConstant, PAGES } from "../constants";

const initState = {
  lang: "en",
  page: PAGES.MAIN,
  dt_range_from: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
  dt_range_to: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
  ahu_choose: 0
};

const coreReducer = (state = initState, action) => {
  switch (action.type) {
    case dataConstant.type.SET_PAGE:
            return {
                ...state,
                page: action.page
            };
    case dataConstant.type.SET_DT_RANGE:
      return {
          ...state,
          dt_range_from: action.dt_range[0],
          dt_range_to: action.dt_range[1]
      };
    case dataConstant.type.SET_AHU:
      return {
          ...state,
          ahu_choose: action.ahu_choose
      };
    default:
      return state;
  }
};

export default coreReducer;
