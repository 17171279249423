import { useState, useEffect } from 'react';
import { Container, Row, ButtonGroup, ToggleButton } from "react-bootstrap";
import { useSelector } from 'react-redux';

import { axiosWithXAuth } from "../../../utils/functions";
import { API_URL } from "../../../utils/variables"
import SyncingChart from '../chart/SyncingChart';

function ChartTrends({platform}) {

    const building = useSelector(root => root.data.building);
    const dt_range_from = useSelector(root => root.core.dt_range_from);
    const dt_range_to = useSelector(root => root.core.dt_range_to);

    const btns_mode = [
        { name: 'CCMS', value: 'CCMS' },
        { name: 'AI Mode', value: 'AI' }
    ];

    const [dataList, setDataList] = useState([]);

    const [chartSeriesTC, setChartSeriesTC] = useState([]);
    const [chartSeriesKW, setChartSeriesKW] = useState([]);
    const [chartSeriesCOP, setChartSeriesCOP] = useState([]);
    const [currentMode, setCurrentMode] = useState(btns_mode[1].value);
   

    useEffect(()=>{
        if (dt_range_to!=='Invalid date'){
            callData(dt_range_from, dt_range_to);
        }
    }, [dt_range_from, dt_range_to])

    const callData = async (from_dt, to_dt) => {

        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/getActualPredictCompare`,
            data: {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_code": building.building_ibms_id.toLowerCase()
            },
        })
            .then((response) => {
                let data = response.data;
                setDataList(data);
                massageData(data, btns_mode[1].value);
            })
    }

    const massageData = (data_o, filter_mode) => {
        let data=[];

        data_o.forEach((element, index) => {
            data.push({
                "dt": element.dt,
                "predict_power": element.predict_power,
                "predict_tc": element.predict_tc,
                "predict_cop": element.predict_cop,
                "actual_power": element.actual_power,
                "actual_tc": element.actual_tc,
                "actual_cop": element.actual_cop,
                "bs_power": element.bs_power,
                "bs_cop": element.bs_cop,
                "mode": element.mode
            })
        })
        data = data.filter(e => e.mode === filter_mode);

        data = data.filter(e => 
            e.actual_tc !== null
            && e.actual_power !== null
            && e.bs_power !== null
            && e.actual_cop !== null
            && e.bs_cop !== null
            && e.actual_cop < 10
            && (
                filter_mode === "CCMS"
                || (
                    filter_mode === "AI" 
                    && e.predict_tc !== null
                    && e.predict_power !== null
                    && e.predict_cop !== null
                    )
            )
        );

        let sycing_tc_series = [
            {
                name: "Predict TC",
                data: data.map((element, index) => { 
                        return [element["dt"], element["predict_tc"]]; 
                    })
            },
            {
                name: "Actual TC",
                data: data.map((element, index) => { 
                        return [element["dt"], element["actual_tc"]]; 
                    })
            }
        ];
        let sycing_power_series = [
            {
                name: "Predict Power",
                data: data.map((element, index) => { 
                        return [element["dt"], element["predict_power"]]; 
                    })
            },
            {
                name: "Actual Power",
                data: data.map((element, index) => { 
                        return [element["dt"], element["actual_power"]]; 
                    })
            },
            {
                name: "Baseline Power",
                data: data.map((element, index) => { 
                        return [element["dt"], element["bs_power"]]; 
                    })
            }
        ];
        let sycing_cop_series = [
            {
                name: "Predict COP",
                data: data.map((element, index) => { 
                        return [element["dt"], element["predict_cop"]]; 
                    })
            },
            {
                name: "Actual COP",
                data: data.map((element, index) => { 
                        return [element["dt"], element["actual_cop"]]; 
                    })
            },
            {
                name: "Baseline COP",
                data: data.map((element, index) => { 
                        return [element["dt"], element["bs_cop"]]; 
                    })
            }
        ];

        setChartSeriesTC(sycing_tc_series);
        setChartSeriesKW(sycing_power_series);
        setChartSeriesCOP(sycing_cop_series);

    }

    const renderModeBtns = (chartname) => {
        return (
            <div style={{float: "right", marginTop: "-1.5rem"}}>
                <div className="mb-3 input-group input-group-sm px-0" style={{width: "auto"}}>
                    <span className="input-group-text" id="inputGroup-sizing-sm">Select Mode</span>
                    <ButtonGroup style={{float: "right"}}>
                        {btns_mode.map((radio, idx) => (
                            <ToggleButton
                                key={`radio-${chartname}-${platform}-mode-${idx}`}
                                id={`radio-${chartname}-${platform}-mode-${idx}`}
                                size="sm"
                                type="radio"
                                variant={idx % 2 ? 'outline-success' : 'outline-warning'}
                                name={`radio-${chartname}-${platform}-mode`}
                                value={radio.value}
                                checked={currentMode === radio.value}
                                style={{"cursor": "pointer"}}
                                onChange={(e) => changeMode(e.currentTarget.value)}
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </div>
            </div>
        )
    }

    const changeMode = (chg_to_mode) => {
        let result = structuredClone(dataList);
        setCurrentMode(chg_to_mode);
        massageData(result, chg_to_mode);
    }

    const renderContent = () => {

        return (
            <div className='card_bg mt-2 px-0'>
                <div className='card_upper d-none d-sm-none d-md-block'>
                    <div className='title_text'>Chart</div>
                    {renderModeBtns("chiller_page")}
                </div>

                <div className='card_content'>
                    <SyncingChart
                        chartTitle='Total Cooling Load'
                        chartId={`chart_ch_tc_${platform}`}
                        chartHeight={250}
                        yAxisLabel='kW'
                        unit='kW'
                        series={chartSeriesTC}
                        platform={platform}
                    />
                    <SyncingChart
                        chartTitle='Power Consumption'
                        chartId={`chart_ch_kw_${platform}`}
                        chartHeight={250}
                        yAxisLabel='kW'
                        unit='kW'
                        series={chartSeriesKW}
                        platform={platform}
                    />
                    <SyncingChart
                        chartTitle='COP'
                        chartId={`chart_ch_cop_${platform}`}
                        chartHeight={250}
                        yAxisLabel=''
                        unit=''
                        series={chartSeriesCOP}
                        platform={platform}
                    />
                </div>

                {/* <div className='card_bottom'>
                </div> */}
            
            </div>
        )
    }

    return renderContent()
}

export default ChartTrends;