import { useState, useEffect, forwardRef, useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Spinner, Button, ButtonGroup, DropdownButton, Dropdown} from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaGenderless as ItemIcon } from "react-icons/fa";
import { BsFillCalendarWeekFill as CalendarIcon } from "react-icons/bs";

import { SET_DT_RANGE, PAGES, SET_AHU } from "../../../constants";
import LogoChillerGrey from '../../..//assets/img/navbar/chiller_grey.png';
import LogoAhuGrey from '../../..//assets/img/navbar/ahu_grey.png';

const DateSelection = ({
    headerTitle = "",
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ref_dt_range = useRef(null);

    const building = useSelector(root => root.data.building);
    const dt_range_from = useSelector(root => root.core.dt_range_from);
    const dt_range_to = useSelector(root => root.core.dt_range_to);
    const current_page = useSelector(root => root.core.page);
    const ahu_choose = useSelector(root => root.core.ahu_choose);

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [ahuIdxSelected, setAhuIdxSelected] = useState(0);

    const max_days = 30;

    useEffect(()=>{
        setAhuIdxSelected(ahu_choose);
    }, [ahu_choose])

    useEffect(()=>{
        if ((dt_range_from !== startDate || dt_range_to !== endDate) && dt_range_from !== 'Invalid date' && dt_range_to !== 'Invalid date'){
            let from_dt = new Date(dt_range_from);
            let to_dt = new Date(dt_range_to);
            setDateRange([from_dt, to_dt]);
        }
    }, [dt_range_to])

    useEffect(()=>{
        if ((dt_range_from !== startDate || dt_range_to !== endDate) && 
            endDate !== 'Invalid date' && endDate !== null && 
            startDate !== 'Invalid date' && startDate !== null){
            let from_dt = moment(startDate).format("YYYY-MM-DD 00:00:00");
            let to_dt = moment(endDate).format("YYYY-MM-DD 23:59:59");
            dispatch({ type: SET_DT_RANGE, dt_range: [from_dt, to_dt] });
        }
    }, [endDate])

    const onClickAhu = (idx) => {
        navigate('/dashboard/ahu/'+building.ahu_display_names[idx]); 
        setAhuIdxSelected(idx);
        dispatch({ type: SET_AHU, ahu_choose: idx });
    }

    const renderAHUbtn = (ahu, idx, platform) => {
        let style_class = idx===ahu_choose? "selected":"";
        if (platform === "web")
            return (
                <Col xs={1} md={1} lg={1} className={`equip_btn ${style_class}`} onClick={()=>{onClickAhu(idx)}}>
                    <div>
                        {/* <ItemIcon style={{marginTop: "-2px", marginLeft: "-10px"}} className='me-2'/> */}
                        {building.ahu_display_names[idx]}
                    </div>
                </Col>
        )
        else {
            return (
                <Dropdown.Item key={`ahu_dropdown_${idx}`} eventKey={idx} onClick={()=>{onClickAhu(idx)}}>{building.ahu_display_names[idx]}</Dropdown.Item>
            )
        }
    }

    const renderAHUlist = (platform) => {
        if (!building.ahus || current_page!==PAGES.MAIN_AHU)
            return (<div></div>)

        return building.ahus.map((e, idx)=>{
            return renderAHUbtn(e, idx, platform)
        })
    }

    const renderContent = () => {
        return (
            <div className='card_bg'>
                <div className='page_header mt-2 equip_page'>
                    <Row >
                        <Col xs={12} sm={12} md={12} lg={5} xl={6} className='text-left'>
                                {/* <div className='title_icon px-3'>
                                    {current_page===PAGES.MAIN_CH && <img src={LogoChillerGrey} height={"30px"} style={{marginTop: "-5px"}}/>}
                                    {current_page===PAGES.MAIN_AHU && <img src={LogoAhuGrey} height={"32px"} style={{marginTop: "-5px"}}/>}
                                </div> */}
                                <div className='title_text'>
                                    {headerTitle}
                                </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={6}>
                            <Row>
                                <Col>
                                    <div className='input_wrap pe-2'>
                                        <div className="input-group input-group-sm">
                                            <span className="input-group-text" id="inputGroup-sizing-sm">Select Date Range</span>
                                            <DatePicker
                                                ref={ref_dt_range}
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(dates) => {
                                                    setDateRange(dates);
                                                    if (dates[0]!==null && dates[1]===null){
                                                        let maxDt = new Date(dates[0]);
                                                        maxDt.setDate(dates[0].getDate() + max_days);
                                                        setMaxDate(maxDt);
                                                    }
                                                    else{
                                                        setMaxDate(null);
                                                    }
                                                }}
                                                // isClearable={true}
                                                withPortal
                                                className='form-control date_range_input'
                                                aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                placeholderText="YYYY-MM-DD - YYYY-MM-DD" 
                                                dateFormat="yyyy-MM-dd"
                                                maxDate={maxDate}
                                                minDate={minDate}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                {
                                current_page===PAGES.MAIN_AHU &&
                                <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <ButtonGroup className='header_btn_gp'>
                                        <DropdownButton as={ButtonGroup} title={building.ahu_display_names[ahuIdxSelected]} id="ahu-dropdown">
                                            {
                                                renderAHUlist("mobile")
                                            }
                                        </DropdownButton>
                                    </ButtonGroup>
                                </Col>
                                }
                            </Row>
                        </Col>
                        
                    </Row>
                    {/* { current_page===PAGES.MAIN_AHU && 
                    <>
                        <Row className='equip_btn_wrap d-none d-sm-none d-md-block'>
                            {renderAHUlist("web")}
                        </Row>
                        <Row className='equip_btn_wrap text-left'>
                            
                        </Row>
                    </>
                    } */}
                </div>
            </div>
        )
    }
    return (
        <div>
            {building && renderContent()}
            {!building && 
                <Container className='py-4'>
                    <Spinner animation="border" variant="light" size="md" />
                </Container>
            }
        </div>
    )
}

DateSelection.propTypes = {
    headerTitle: PropTypes.string.isRequired,
};

export default DateSelection;