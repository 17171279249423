import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Modal, Table, Button, ButtonGroup, ToggleButton} from "react-bootstrap";
import { Line } from "react-chartjs-2";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { API_URL, HARD_CODE_DATA } from "../../../utils/variables"
import { getBarOption, getBarLineOption, setBarLineColor } from "../../../utils/chartOptions";
import { axiosWithXAuth, numberWithCommas } from "../../../utils/functions";
import { useRef } from 'react';

Date.prototype.getWeek = function () {
    let currentDate = this;
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));
    
    var weekNumber = Math.ceil(days / 7);
    return weekNumber;
};

const  KPIChartAdvance = ({
    platform = "web",
    name = "",
    color = "",
    showModal = false,
    setShowModal
}) => {
    const ref = useRef();

    const building = useSelector(root => root.data.building);

    const [data, setData] = useState([]);
    const [line1, setLine1] = useState([]);
    const [line2, setLine2] = useState([]);
    const [bar, setBar] = useState([]);
    const [bar2, setBar2] = useState([]);
    const [dateLabels, setDateLabels] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [radioValue, setRadioValue] = useState('day');
    const [sumCost, setSumCost] = useState(0);
    const [sumSavedEnergyTot, setSumSavedEnergyTot] = useState(0);
    const [sumSavedEnergyAI, setSumSavedEnergyAI] = useState(0);
    const [sumSavedEnergyFC, setSumSavedEnergyFC] = useState(0);
    const [energySavePctTot, setEnergySavePctTot] = useState(0);
    const [energySavePctAI, setEnergySavePctAI] = useState(0);
    const [energySavePctFC, setEnergySavePctFC] = useState(0);
    const radios = [
        { name: 'Day', value: 'day'},
        { name: 'Week', value: 'week' },
        { name: 'Month', value: 'month' },
    ];
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [savingType, setSavingType] = useState(null);
    const [ttOrigPower, setTTOrigPower] = useState(0);
    const [sumEnergyTot, setSumEnergyTot] = useState(0);
    const [sumEnergyAI, setSumEnergyAI] = useState(0);
    const [sumEnergyCCMS, setSumEnergyCCMS] = useState(0);
    const [sumEnergyBS, setSumEnergyBS] = useState(0);

    const handleClose = () => {
        setShowModal(false);
    }

    useEffect(() => {
        let today = new Date();
        let start = new Date().setDate(today.getDate() - 14);
        if (today.getMonth() === 0){
            start = new Date(today.getFullYear()-1,11,1) // the month is 0-indexed
        }
        else{
            start = new Date(today.getFullYear(),today.getMonth()-1,1)
        }
        if (minDate!==null && minDate > start){
            start = new Date().setDate(minDate.getDate());
        }
        // let end = new Date().setDate(today.getDate() - 1);
        let end = new Date().setDate(today.getDate());
        setDateRange([start, end]);
    }, [minDate])

    useEffect(()=>{
        let start = null, end = null;
        if (startDate === null || endDate === null){
            return;
        }
        else if (startDate !== null && endDate !== null){
            start = startDate;
            end = endDate;
        }
        let from_dt = moment(start).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(end).format("YYYY-MM-DD 23:59:59");

        if (showModal === true)
            callData(from_dt, to_dt);

    }, [dateRange])

    useEffect(() => {
        let from_dt = moment(startDate).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(endDate).format("YYYY-MM-DD 23:59:59");

        if (showModal === true)
            callData(from_dt, to_dt);
            
        if (name==='kpi_cost_saving'){
            setRadioValue('month');
        }
        
    }, [showModal])

    useEffect(() => {
        if (building.building_id){
            let savingType = HARD_CODE_DATA[building.building_id]['saving_type'];
            setSavingType(savingType);
            let min_date_str = HARD_CODE_DATA[building.building_id]['kpi_from_dt'];
            setMinDate(new Date(min_date_str));
            let today = new Date();
            // let max = new Date().setDate(today.getDate() - 1);
            let max = new Date().setDate(today.getDate());
            setMaxDate(max);
        }
    }, [building])

    useEffect(() => {
        if (ref.radioValue !== radioValue){
            reGroupData(structuredClone(data));
        }
    }, [radioValue])

    const callData = (from_dt, to_dt) => {
        let data = {};
        if (from_dt && to_dt){
            data = {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_id": building.building_id,
                "b_code": building.building_ibms_id.toLowerCase()
            }
        }
        else{
            return null;
        }

        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/saving`,
            data: data,
        })
            .then((response) => {
                let data = response.data;
                let result = []
                data.forEach((element, index) => {
                    result.push({
                        "dt": element.dt,
                        "ai_energy": element.ai_energy,
                        "bs_energy": element.bs_energy,
                        "tot_energy": element.tot_energy,
                        "freecool_energy": element.freecool_energy,
                        "energy_saving": element.energy_saving,
                        "cost_saving": element.cost_saving,
                        "co2_saving": element.co2_saving
                    })
                })
                setData(result);
                reGroupData(structuredClone(result));
            })
    }

    const reGroupData = (fullDataSets) => {
        let tot_energy_list = fullDataSets.map((element, index) => { return element.tot_energy});
        let tot_energy = tot_energy_list.reduce((x, y) => {return x + y;}, 0);

        let bs_saved_energy_list = fullDataSets.map((element, index) => { return element.bs_energy});
        let bs_saved_energy = bs_saved_energy_list.reduce((x, y) => {return x + y;}, 0);

        let compareFn = function(){};
        let formatFn = function(){};

        if (radioValue === 'day'){
            compareFn = function (a,b){
                return new Date(a).toDateString() === new Date(b).toDateString()
            }
            formatFn = function (x){return moment(x).format('D-MMM'); }
        }
        if (radioValue === 'week'){
            compareFn = function (a,b){
                return new Date(a).getWeek() === new Date(b).getWeek()
            }
            formatFn = function (x){return moment(x).format('D-MMM') + ' week'; }
        }
        if (radioValue === 'month'){
            compareFn = function (a,b){
                return new Date(a).getMonth() === new Date(b).getMonth() 
                && new Date(a).getFullYear() === new Date(b).getFullYear()
            }
            formatFn = function (x){return moment(x).format('MMM YYYY'); }
        }
        let group_by_result = [];
        fullDataSets.forEach((row, index) => { 
            let i = group_by_result.findIndex(el=>compareFn(el.dt, row.dt));
            if (i != -1){
                group_by_result[i]['cost_saving'] = row.cost_saving;
                group_by_result[i]['co2_saving'] += row.co2_saving;
                group_by_result[i]['energy_saving'] += row.energy_saving;
                group_by_result[i]['tot_energy'] += row.tot_energy;
                group_by_result[i]['ai_energy'] += row.ai_energy;
                group_by_result[i]['bs_energy'] += row.bs_energy;
                group_by_result[i]['energy_saving2'] += row.energy_saving - row.freecool_energy;
                group_by_result[i]['freecool_energy'] += row.freecool_energy;
            }
            else{
                group_by_result.push(row);
            }
        });

        if (name==="kpi_cost_saving"){
            let cost_saving_list = group_by_result.map((element, index) => { return element.cost_saving});
            setBar(cost_saving_list);
            // let sum_cost = cost_saving_list[0];
            let sum_cost = cost_saving_list.reduce((x, y) => {
                return x + y;
                }, 0);
            setSumCost(sum_cost);
        }

        if (name==="kpi_energy_saving"){
            let energy_saving_wo_fc_list = group_by_result.map((element, index) => { return element.energy_saving-element.freecool_energy});
            let freecool_energy_list = group_by_result.map((element, index) => { return element.freecool_energy});
            let line1 = group_by_result.map((element, index) => { return element.tot_energy});
            let line2 = group_by_result.map((element, index) => { return element.ai_energy});
            setBar(energy_saving_wo_fc_list);
            setBar2(freecool_energy_list);
            setLine1(line1);
            setLine2(line2);

            let sum_energy = group_by_result
                .map((element, index) => { return element.energy_saving})
                .reduce((x, y) => {return x + y;}, 0);
            setSumSavedEnergyTot(sum_energy);

            let sum_fc_energy = freecool_energy_list.reduce((x, y) => {return x + y;}, 0);
            setSumSavedEnergyFC(sum_fc_energy);
            
            let energy_pct = sum_energy/tot_energy*100;
            setEnergySavePctTot(energy_pct);

            let energy_pct_ai = sum_energy/bs_saved_energy*100;
            setEnergySavePctAI(energy_pct_ai);

            let ai_energy_comsum = group_by_result
                .map((element, index) => { return element.ai_energy})
                .reduce((x, y) => {return x + y;}, 0);
            setSumEnergyAI(ai_energy_comsum);

            let ccms_energy_comsum = group_by_result
                .map((element, index) => { return element.tot_energy - element.bs_energy})
                .reduce((x, y) => {return x + y;}, 0);
            setSumEnergyCCMS(ccms_energy_comsum);

            setSumEnergyTot(ai_energy_comsum+ccms_energy_comsum);

            let bs_energy_comsum = group_by_result
                .map((element, index) => { return element.bs_energy})
                .reduce((x, y) => {return x + y;}, 0);
            setSumEnergyBS(bs_energy_comsum);

            let energy_pct_fc = sum_fc_energy/ai_energy_comsum*100;
            setEnergySavePctFC(energy_pct_fc);
        }

        let data_labels = group_by_result.map((element, index) => {  return formatFn(element.dt) });
        setDateLabels(data_labels);
    }

    const renderEnergyChart = () => {
        let label_name = "Energy Saving";
        let asix_name_left = "Energy Saving (kWh)";
        let asix_name_right = "Actual / Baseline Energy (kWh)";
        let bscolor = "#0D6EFD";
        let actualcolor = "#59A14F";
        let fc_color = "#17acde";
        let datasets = [
            {
                label: "Energy Saving",
                borderColor: color,
                type:'bar',
                data: bar,
                backgroundColor: color,
                unit: '',
                pointBackgroundColor: color,
                // yAxisID: "y-axis-1"
            },
            {
                label: "Free Cooling",
                borderColor: fc_color,
                type:'bar',
                data: bar2,
                backgroundColor: fc_color,
                unit: '',
                pointBackgroundColor: fc_color,
                // yAxisID: "y-axis-1"
            },
            // {
            //     label: "Total Energy",
            //     borderColor: actualcolor,
            //     data: line1,
            //     type:'line',
            //     backgroundColor: actualcolor,
            //     unit: '',
            //     pointBackgroundColor: actualcolor,
            //     yAxisID: "y-axis-2"
            // },
            // {
            //     label: "AI Energy",
            //     borderColor: bscolor,
            //     data: line2,
            //     type:'line',
            //     backgroundColor: bscolor,
            //     unit: '',
            //     pointBackgroundColor: bscolor,
            //     yAxisID: "y-axis-2"
            // }
        ];

        return (
            <Line
                className='line_chart'
                data={{
                    labels: dateLabels,
                    datasets: setBarLineColor(datasets, false)
                }}
                // options={getBarLineOption('',0, asix_name_left, asix_name_right, [])}
                options={getBarOption('',0, asix_name_left, [])}
            />
        )
    }

    const renderCostChart = () => {
        let label_name = "Cost Saving";
        let asix_name = "Cost Saving $";
        let datasets = [
            {
                label: label_name,
                borderColor: color,
                type:'bar',
                data: bar,
                backgroundColor: color,
                unit: '',
                pointBackgroundColor: color,
            }
        ];

        return (
            <Line
                className='line_chart'
                data={{
                    labels: dateLabels,
                    datasets: setBarLineColor(datasets, false)
                }}
                options={getBarOption('',0, asix_name, [])}
            />
        )
    }


    const renderInputGroups = () => {

        return (
            <Row className="text-right">
                <Col md={12} lg={6} className="pe-1">
                    <div className="mb-3 input-group input-group-sm">
                        <span className="input-group-text" id="inputGroup-sizing-sm">Select Date Range</span>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                // setDateRange([null,null]);
                                setDateRange(update);
                            }}
                            // isClearable={true}
                            withPortal
                            className='form-control date_range_input'
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            placeholderText="YYYY-MM-DD - YYYY-MM-DD" 
                            dateFormat="yyyy-MM-dd"
                            maxDate={maxDate}
                            minDate={minDate}
                        />
                    </div>
                </Col>
                <Col md={12} lg={6} className='px-0'>
                    <ButtonGroup size="sm" style={{marginTop: "1px"}} className="mb-3">
                        {radios.map((radio, idx) => (
                            (name==='kpi_energy_saving' || (name==='kpi_cost_saving'&&radio.value==='month')) &&
                            <ToggleButton
                                key={`radio-dmy-filter-${platform}-${name}-${idx}`}
                                id={`radio-dmy-filter-${platform}-${name}-${idx}`}
                                type="radio"
                                variant='outline-primary'
                                name={`radio-dmy-filter-${platform}-${name}`}
                                value={radio.value}
                                checked={radioValue === radio.value}
                                onChange={(e) => {setRadioValue(e.currentTarget.value)}}
                            >
                            {radio.name} 
                        </ToggleButton>
                        ))}
                    </ButtonGroup>
                </Col>
            </Row>
        )
    }

    const renderSumCost = () => {
        let sum_cost = numberWithCommas(sumCost, 0);
        return (
            <Row style={{float: "right", display:"contents"}}>
                <div className="mb-3" style={{width: "auto"}}>
                    <div>
                        Total Cost Saved:&emsp;$ {sum_cost}
                    </div>
                </div>
            </Row>
        )
    }

    const renderSumEnergy = () => {
        let tot_saved_energy = numberWithCommas(sumSavedEnergyTot, 0);
        let saved_energy_pct = energySavePctTot > 0? numberWithCommas(energySavePctTot, 2):"---";
        let saved_energy_pct_ai = energySavePctAI > 0? numberWithCommas(energySavePctAI, 2):"---";
        let saved_energy_pct_fc = energySavePctFC > 0? numberWithCommas(energySavePctFC, 2):"---";

        let tot_consume_energy = sumEnergyTot>0? numberWithCommas(sumEnergyTot, 0):"---";
        let ai_consume_energy = sumEnergyAI>0? numberWithCommas(sumEnergyAI, 0):"---";
        let ccms_consume_energy = sumEnergyCCMS>0? numberWithCommas(sumEnergyCCMS, 0):"---";
        let bs_consume_energy = sumEnergyBS>0? numberWithCommas(sumEnergyBS, 0):"---";

        let ccms_tot = sumEnergyCCMS>0?sumEnergyCCMS:0;
        let bs_tot = sumEnergyBS>0?sumEnergyBS:0;
        let ccms_bs_tot = ccms_tot+bs_tot;
        let tot_consume_energy_bs_ccms = ccms_bs_tot>0? numberWithCommas(ccms_bs_tot, 0):"---";
        return (
            <Row className='mx-0' style={{fontSize: "small"}}>
                <Col sm={12} md={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} className="mb-2">
                    <Table striped bordered hover size="sm" className='text-center'>
                        <thead>
                            <tr style={{"borderBottomWidth": "medium"}}>
                            <th colSpan={2}>Energy Saving</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                                <td><b>Total Saved</b></td>
                                <td>{tot_saved_energy} kWh</td>
                            </tr> */}
                            <tr>
                                <td><b>% Saving (Total)</b></td>
                                <td>{saved_energy_pct} %</td>
                            </tr>
                            <tr>
                                <td><b>% Saving (AI Mode)</b></td>
                                <td>{saved_energy_pct_ai} %</td>
                            </tr>
                            <tr>
                                <td><b>% Saving (Free Cooling)</b></td>
                                <td>{saved_energy_pct_fc} %</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col sm={12} md={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} className="mb-2">
                    <Table striped bordered hover size="sm" className='text-center'>
                        <thead>
                            <tr style={{"borderBottomWidth": "medium"}}>
                            <th colSpan={4}>Energy Consumption</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}><b>Before AI Optmization</b></td>
                                <td colSpan={2}><b>After AI Optmization</b></td>
                            </tr>
                            <tr>
                                <td><b>Total</b></td>
                                <td>{tot_consume_energy_bs_ccms} kWh</td>
                                <td><b>Total</b></td>
                                <td>{tot_consume_energy} kWh</td>
                            </tr>
                            <tr>
                                <td><b>AI Mode (Baseline)</b></td>
                                <td>{bs_consume_energy} kWh</td>
                                <td><b>AI Mode (Actual)</b></td>
                                <td>{ai_consume_energy} kWh</td>
                            </tr>
                            <tr style={{"borderTopWidth": "medium"}}>
                                <td colSpan={1}><b>CCMS Mode</b></td>
                                <td colSpan={3}>{ccms_consume_energy} kWh</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    const renderContent = () =>{
        return (
            <Container className='py-1'>
                <div className='energy_kpi_chart_wrap'>
                    {renderInputGroups()}
                    <div style={{float: "right"}}>
                        {name==="kpi_cost_saving" && renderSumCost()}
                    </div>
                    <div>
                        {name==="kpi_energy_saving" && renderSumEnergy()}
                    </div>
                    <>
                        {name==="kpi_cost_saving" && renderCostChart()}
                        {name==="kpi_energy_saving" && building.building_id && renderEnergyChart()}
                    </>
                </div>
            </Container>
        )
    }

    return (
        <Modal size="xl" fullscreen="lg-down" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{(name==="kpi_cost_saving")? "Cost Saving":(name==="kpi_energy_saving")? "Energy Saving":""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderContent()}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

KPIChartAdvance.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    setShowModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
};

export default KPIChartAdvance;