import React,{ useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Table, Dropdown, Button, ProgressBar } from "react-bootstrap";
import moment from "moment";

import {
    TbSquareRoundedNumber1Filled as SeqIcon1,
    TbSquareRoundedNumber2Filled as SeqIcon2,
    TbSquareRoundedNumber3Filled as SeqIcon3,
    TbSquareRoundedNumber4Filled as SeqIcon4,
    TbSquareRoundedNumber5Filled as SeqIcon5,
    TbSquareRoundedNumber6Filled as SeqIcon6,
    TbSquareRoundedNumber7Filled as SeqIcon7,
    TbTemperatureCelsius as CelsiusIcon
} from "react-icons/tb";
import { FaTemperatureHalf as TempIcon} from "react-icons/fa6";
import { BsSpeedometer as SpeedIcon, BsThermometerSnow as FreeCoolIcon } from "react-icons/bs";
import { GiBrain as AIIcon } from "react-icons/gi";

import { API_URL, HARD_CODE_DATA } from "../../../utils/variables";
import { axiosWithXAuth, CustomToggleMore } from "../../../utils/functions";
import OptSequenceHistory from '../modal/OptSequenceHistory';
import OptDiagnosis from '../modal/OptDiagnosis';
import AlarmRecord from '../modal/AlarmRecord';

function OptSequence({platform, recordnum}) {

    const building = useSelector(root => root.data.building);
    const rtData = useSelector(root => root.data.realtimeData);
    
    const [showDiagnosisModal, setShowDiagnosisModal] = useState(false);
    const [showOptHistoryModal, setShowOptHistoryModal] = useState(false);
    const [showAlarmModal, setShowAlarmModal] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = recordnum;
    let smallfont = platform==="web"?"":"small_font";

    useEffect(() => {
        setPage(1);
        callOptSuggestions();
    }, [])

    useEffect(() => {
        let b_code = building.building_ibms_id.toLowerCase();
        let th_rtData = rtData[b_code];

        if (suggestions.length>0 && th_rtData!==undefined)
            updateSuggestions(th_rtData);
    }, [rtData, suggestions]);

    const callOptSuggestions = async () => {
        
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/optSuggestions`,
            data: {
                "b_id": building.building_id,
                "limit": 10
            }
        })
            .then((response) => {
                let data = response.data;
                let result = []
                data.forEach((element, index) => {
                    result.push({
                        "cdt": element.cdt,
                        "opt_demand": element.opt_demand,
                        "opt_st": element.opt_st,
                        "opt_pump_spd": element.opt_pump_spd,
                        "opt_sequence_list": element.opt_sequence_list,
                        "opt_pred_outtemp": element.opt_pred_outtemp,
                        "opt_pred_active_power": element.opt_pred_active_power,
                        "opt_pred_kwh": element.opt_pred_kwh,
                        "opt_pred_cop": element.opt_pred_cop,
                        "mode": element.mode,
                        "actual_power": element.actual_power,
                        "actual_cop": element.actual_cop,
                        "is_free_cool": element.is_free_cool
                    })
                })
                setSuggestions(result);
            })
    }

    const updateSuggestions = (th_rtData) => {
        if (th_rtData.suggestions===undefined || th_rtData.suggestions.length<=0) return false;

        let suggestions_copy = suggestions;

        let x = th_rtData.suggestions[0];
        let y = th_rtData.suggestions[1];
        
        let existing_y_idx = suggestions_copy.findIndex(item => item.cdt === y.cdt);
        if (existing_y_idx !== -1){
            suggestions_copy[existing_y_idx].actual_power = y.actual_power;
            suggestions_copy[existing_y_idx].actual_cop = y.actual_cop;
        }

        let is_included = suggestions_copy.find(item => item.cdt === x.cdt);
        if (is_included !== undefined) return false;

        let new_add = {
            "cdt": x.cdt,
            "opt_demand": x.opt_demand,
            "opt_st": x.opt_st,
            "opt_pump_spd": x.opt_pump_spd,
            "opt_sequence_list": x.opt_sequence_list,
            "opt_pred_outtemp": x.opt_pred_outtemp,
            "opt_pred_active_power": x.opt_pred_active_power,
            "opt_pred_kwh": x.opt_pred_kwh,
            "opt_pred_cop": x.opt_pred_cop,
            "mode": x.mode,
            "actual_power": x.actual_power,
            "actual_cop": x.actual_cop,
            "is_free_cool": x.is_free_cool
        }

        let new_array = [new_add].concat(suggestions_copy);
        setSuggestions(new_array);
    }

    const renderBubble = (name, isOn) => {
        let color = isOn ? "green" : "grey";
        let iconsize = platform==="web"&&building.chillers.length<=4?35:22;
        let icon = name === "1" ? <SeqIcon1 color={color} size={iconsize} /> :
            name === "2" ? <SeqIcon2 color={color} size={iconsize} /> :
            name === "3" ? <SeqIcon3 color={color} size={iconsize} /> :
            name === "4" ? <SeqIcon4 color={color} size={iconsize} /> :
            name === "5" ? <SeqIcon5 color={color} size={iconsize} /> :
            name === "6" ? <SeqIcon6 color={color} size={iconsize} /> :
            null;
        return (
            <span key={`bubble_${name}`}>
                {icon}
            </span>
        )
    }

    const renderBubbles = (sequence, demand) => {
        demand = parseInt(demand);
        return sequence.map((element, index) => {
            let name = element;
            let isOn = index < demand ? true : false;
            return renderBubble(name, isOn);
        })
    }

    const renderSuggestion = (suggest, index) => {
        let cdt = new Date(suggest.cdt);
        // cdt = cdt.setHours(cdt.getHours() - 8);
        let opt_dt = moment(cdt).format("DD/MM h:mm a");
        let temp_sp = suggest.opt_st !== null ? suggest.opt_st.toFixed(1): null;
        let speed_sp = suggest.opt_pump_spd !== null? suggest.opt_pump_spd.toFixed(0): null;
        let default_ch_list = [];
        if ((suggest.opt_sequence_list!==null && suggest.opt_sequence_list.length > 0) || building.rt_hardcode===0){
            default_ch_list = building.chillers.map((element, index) => (index+1).toString());
        }
        else{
            default_ch_list = HARD_CODE_DATA[building.building_id].chillers.map((element, index) => (index+1).toString());
        }
        let item_num = (page-1) * itemsPerPage + index + 1;
        return (
            <tr key={`opt_sug_${index}`}>
                <td>{item_num} 
                    {
                        suggest.mode==="AI" && 
                        <>&ensp;
                        <AIIcon size={16} color="#198754" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                    {
                        suggest.is_free_cool===1 && 
                        <>&ensp;
                        <FreeCoolIcon size={16} color="#0D6EFD" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                </td>
                <td>{opt_dt}</td>
                <td className='px-0'>
                    {suggest.opt_demand===0 && <>{renderBubbles(default_ch_list, 0)}</>}
                    {suggest.opt_demand>0 && renderBubbles(suggest.opt_sequence_list, suggest.opt_demand)}
                </td>
                <td>{temp_sp && <><TempIcon color='#0D6EFD' style={{marginTop: "-5px"}} /> {temp_sp} <CelsiusIcon style={{marginTop: "-5px"}}/></>}</td>
                <td>
                    {/* {speed_sp && <><SpeedIcon color='#034f84' style={{marginTop: "-5px"}} /> {speed_sp}</>}  */}
                    <Row className="px-0">
                        {platform==="web" && <Col xs={12} sm={2} md={2} lg={3} xl={2} className="px-0"><SpeedIcon color='#034f84' /></Col>}
                        <Col xs={10} sm={8} md={8} lg={8} xl={8} className="pt-1 px-0">
                            <ProgressBar animated now={speed_sp} label={`${speed_sp}Hz`} min={0} max={50} />
                        </Col>
                    </Row>
                </td>
            </tr>
        )
    }

    const renderSuggestionWrap = () => {
        if (suggestions.length > 0){

            let from_item = page===1? 1: (page-1) * itemsPerPage + 1;
            let to_item = from_item + itemsPerPage - 1;
            let suggestions_in_page = suggestions.filter(
                (element, index) => { 
                    return index+1 >= from_item && index+1 <= to_item
                });

            return suggestions_in_page.map((element, index) => {
                return renderSuggestion(element, index);
            })
        }
    }

    return (
        <Container className='card_bg mt-2 pt-3 pb-2'>
            <div className='card_title_wrap py-2 d-none d-sm-none d-md-block'>
                <h5><b>Chiller Control Suggestion</b></h5>
                <Dropdown className='btn_show_more'>
                    <Dropdown.Toggle id="dropdown-button-dark-example1" as={CustomToggleMore} >
                        Custom Toggle
                    </Dropdown.Toggle>
                    <Dropdown.Menu align={{md: "start"}}>
                        <Dropdown.Item eventKey="1" onClick={()=>setShowOptHistoryModal(true)}>Show More</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={()=>setShowDiagnosisModal(true)}>Diagnosis</Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={()=>setShowAlarmModal(true)}>Alarm Record</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown >
            </div>
            <Table striped border="true" hover responsive className={`mb-0 ${smallfont}`} style={{overflow: "hidden"}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date Time</th>
                        <th>
                            {platform==="web" && "Chiller Sequence"}
                            {platform==="mobile" && "Chiller Sequence"}
                        </th>
                        <th>
                            Supply Temp
                        </th>
                        <th>
                            {platform==="web" && "Pump Speed"}
                            {platform==="mobile" && "Pump Speed"}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {renderSuggestionWrap()}
                </tbody>
            </Table>
            <div className='text-left mb-2' style={{fontSize: "small"}}>
                <AIIcon size={14} color="#198754" style={{"marginTop": "-3px"}}/> AI mode&ensp;
                <FreeCoolIcon size={14} color="#0D6EFD" style={{"marginTop": "-3px"}}/> Free Cooling
            </div>
            <Row className='d-sm-block d-md-none'>
                <Col>
                    <Button variant="secondary" onClick={()=>setShowOptHistoryModal(true)}>Control History</Button>{' '}
                </Col>
                <Col>
                    <Button variant="secondary" onClick={()=>setShowDiagnosisModal(true)}> Diagnosis</Button>{' '}
                </Col>
            </Row>
            <OptSequenceHistory
                showModal={showOptHistoryModal}
                setShowModal={setShowOptHistoryModal}
                platform={platform}
                recordnum={10}
            />
            <OptDiagnosis
                showModal={showDiagnosisModal}
                setShowModal={setShowDiagnosisModal}
                platform={platform}
                recordnum={10}
            />
            <AlarmRecord
                showModal={showAlarmModal}
                setShowModal={setShowAlarmModal}
            />
        </Container>
        
    )
}

export default OptSequence;