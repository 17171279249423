import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Spinner, Button, Table  } from "react-bootstrap";
import moment from "moment";
import ExcelJs from "exceljs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { API_URL} from "../../utils/variables"
import { axiosWithXAuth } from "../../utils/functions"

import './css.scss';
import HeaderBage from '../components/header/HeaderBage';


function DownloadMain() {

    let defaultEndDate = new Date();
    let defaultStartDate = new Date();
    defaultStartDate.setDate(defaultEndDate.getDate() -7);

    // const [building, setBuilding] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [maxDate, setMaxDate] = useState(null);
    const [isLoadingA, setIsLoadingA] = useState(false);
    const [isLoadingB, setIsLoadingB] = useState(false);
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [isLoadingD, setIsLoadingD] = useState(false);
    const [isLoadingE, setIsLoadingE] = useState(false);
    const [isLoadingF, setIsLoadingF] = useState(false);

    const building = useSelector(root => root.data.building);

    useEffect(() => {
        setDateRange([defaultStartDate, defaultEndDate]);
        let today = new Date();
        setMaxDate(today);
    }, []);

    const getPointHistoryData = (from_dt, to_dt) => {
        setIsLoadingA(true);
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/pointHistory`,
            data: {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_code": building.building_ibms_id.toLowerCase(),
                "b_id": building.building_id
            }
        })
            .then((response) => {
                let filename = 'PointHistory'+ "_" + moment(startDate).format("YYYYMMDD") + "-" +  moment(endDate).format("YYYYMMDD");
                let sheetname = 'pointHistory';
                let tablename = 'pointHistoryTable';
                let columns = Object.keys(response.data[0]);
                let data = response.data.map(el=>{
                    let dt = moment(el.dt).format("YYYY-MM-DD HH:mm:ss");
                    return {...el, ...{"dt": dt}}
                });
                
                let complete = exportExcel(filename, sheetname, tablename, columns, data);
                return complete;
            })
            .then((response)=>{
                setIsLoadingA(false);
            })
            .catch((err) => {
                setIsLoadingA(false);
            })
    }

    const getOptSuggestionsData = (from_dt, to_dt) => {
        setIsLoadingB(true);
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/optSuggestions`,
            data: {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_id": building.building_id,
                "b_code": building.building_ibms_id.toLowerCase()
            }
        })
            .then((response) => {
                let data = response.data;
                let result = []
                data.forEach((element, index) => {
                    result.push({
                        "dt": element.cdt,
                        "chiller sequence": element.opt_sequence,
                        "chiller demand": element.opt_demand,
                        "supply temp setpoint": element.opt_st !== null ? element.opt_st.toFixed(1): null,
                        "pump speed": element.opt_pump_spd !== null ? element.opt_pump_spd.toFixed(0): null,
                        "predict outdoor temp": element.opt_pred_outtemp !== null ? element.opt_pred_outtemp.toFixed(2): null,
                        "predict total power (kW)": element.opt_pred_active_power !== null ? element.opt_pred_active_power.toFixed(2): null,
                        "predict cop": element.opt_pred_cop !== null ? element.opt_pred_cop.toFixed(2): null,
                    })
                })

                result.sort((a,b) => {
                    if ( a.dt < b.dt ){
                      return -1;
                    }
                    if ( a.dt > b.dt ){
                      return 1;
                    }
                    return 0;
                  } );

                let filename = 'OptControlHistory'+ "_" + moment(startDate).format("YYYYMMDD") + "-" +  moment(endDate).format("YYYYMMDD");
                let sheetname = 'optCtrlHistory';
                let tablename = 'optCtrTable';
                let columns = Object.keys(result[0]);
                let data_result = result.map(el=>{
                    let dt = moment(el.dt).format("YYYY-MM-DD HH:mm:ss");
                    return {...el, ...{"dt": dt}}
                });
                
                let complete = exportExcel(filename, sheetname, tablename, columns, data_result);
                return complete;
            })
            .then((response)=>{
                setIsLoadingB(false);
            })
            .catch((err) => {
                setIsLoadingB(false);
            })
    }

    const getPredictActualHistoryData = (from_dt, to_dt) => {
        setIsLoadingC(true);
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/getActualPredictCompare`,
            data: {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_code": building.building_ibms_id.toLowerCase()
            }
        })
            .then((response) => {
                let filename = 'PredictActualHistory'+ "_" + moment(startDate).format("YYYYMMDD") + "-" +  moment(endDate).format("YYYYMMDD");
                let sheetname = 'predictActualHistory';
                let tablename = 'predictActualHistoryTable';
                let columns = Object.keys(response.data[0]);
                let data = response.data.map(el=>{
                    let dt = moment(el.dt).format("YYYY-MM-DD HH:mm:ss");
                    return {...el, ...{"dt": dt}}
                });
                
                let complete = exportExcel(filename, sheetname, tablename, columns, data);
                return complete;
            })
            .then((response)=>{
                setIsLoadingC(false);
            })
            .catch((err) => {
                setIsLoadingC(false);
            })
    }

    const getAiBsCompareData = (from_dt, to_dt) => {
        setIsLoadingC(true);
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/getAiBsCompare`,
            data: {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_code": building.building_ibms_id.toLowerCase()
            }
        })
            .then((response) => {
                let filename = 'AiBsHistory'+ "_" + moment(startDate).format("YYYYMMDD") + "-" +  moment(endDate).format("YYYYMMDD");
                let sheetname = 'AiBsHistory';
                let tablename = 'AiBsHistoryTable';
                let columns = Object.keys(response.data[0]);
                let data = response.data.map(el=>{
                    let dt = moment(el.dt).format("YYYY-MM-DD HH:mm:ss");
                    return {...el, ...{"dt": dt}}
                });
                
                let complete = exportExcel(filename, sheetname, tablename, columns, data);
                return complete;
            })
            .then((response)=>{
                setIsLoadingD(false);
            })
            .catch((err) => {
                setIsLoadingD(false);
            })
    }

    const getAhuPointHistoryData = (from_dt, to_dt) => {
        setIsLoadingE(true);
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/ahu/pointHistory`,
            data: {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_id": building.building_id
            }
        })
            .then((response) => {
                let filename = 'PointHistory_AHU'+ "_" + moment(startDate).format("YYYYMMDD") + "-" +  moment(endDate).format("YYYYMMDD");
                let sheetname = 'PointHistoryAHU';
                let tablename = 'PointHistoryAHUTable';
                let columns = Object.keys(response.data[0]);
                let data = response.data.map(el=>{
                    let dt = moment(el.dt).format("YYYY-MM-DD HH:mm:ss");
                    return {...el, ...{"dt": dt}}
                });
                
                let complete = exportExcel(filename, sheetname, tablename, columns, data);
                return complete;
            })
            .then((response)=>{
                setIsLoadingE(false);
            })
            .catch((err) => {
                setIsLoadingE(false);
            })
    }

    const getAhuOptSuggest = (from_dt, to_dt) => {
        setIsLoadingF(true);
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/ahu/optSuggestions`,
            data: {
                "from_dt": from_dt,
                "to_dt": to_dt,
                "b_id": building.building_id
            }
        })
            .then((response) => {
                let filename = 'OptSuggest_AHU'+ "_" + moment(startDate).format("YYYYMMDD") + "-" +  moment(endDate).format("YYYYMMDD");
                let sheetname = 'OptSuggestAHU';
                let tablename = 'OptSuggestAHUTable';
                let columns = Object.keys(response.data[0]);
                let data = response.data.map(el=>{
                    let dt = moment(el.dt).format("YYYY-MM-DD HH:mm:ss");
                    return {...el, ...{"dt": dt}}
                });
                
                let complete = exportExcel(filename, sheetname, tablename, columns, data);
                return complete;
            })
            .then((response)=>{
                setIsLoadingF(false);
            })
            .catch((err) => {
                setIsLoadingF(false);
            })
    }

    const exportExcel = (filename, sheetname, tablename, columnnames, data) => {

        try{
            let column_arr = columnnames.map(el => { return {"name":el, "filterButton": true}});
            let data_arr = data.map(el => {return Object.values(el)} );
            let workbook = new ExcelJs.Workbook();
            workbook.creator = 'HSD-AI-OPT';
            let sheet = workbook.addWorksheet(sheetname);
            sheet.headerFooter.firstHeader = filename;
            sheet.pageSetup.printArea = 'A1:'+ String.fromCharCode(data_arr[0].length + 64).toUpperCase() +(data.length+1);
            sheet.pageSetup.printTitlesRow = '1:1';
            sheet.views = [
                {state: 'frozen', xSplit: 1, ySplit: 1}
              ];
            sheet.addTable({ 
                name: tablename,
                ref: 'A1',
                headerRow: true,
                totalsRow: false,
                columns: column_arr,
                rows: data_arr
                });

            for (let i = 1; i <= sheet.columnCount; i++) {
                const col = sheet.getColumn(i);
                col.width = 18;
            }

            let lastRowNum = sheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i=2; i<= lastTableRowNum; i++) {
                const row = sheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {
                    cell.alignment = {vertical: 'middle', horizontal: 'right'};
                }));
            }

            workbook.xlsx.writeBuffer().then((content) => {
                const link = document.createElement("a");
                const blob = new Blob([content], {
                    type: "application/vnd.ms-excel;charset=utf-8;"
                });
                link.download = `${filename}.xlsx`;
                link.href = URL.createObjectURL(blob);
                link.click();
                });
            
            return true;
        } catch (e) {
            console.log(e);
            return true;
        }
        
    }

    const onClickDownloadPointHistory = () => {
        let from_dt = moment(startDate).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(endDate).format("YYYY-MM-DD 23:59:59");
        getPointHistoryData(from_dt, to_dt);
    }

    const onClickDownloadOptSuggestion = () => {
        let from_dt = moment(startDate).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(endDate).format("YYYY-MM-DD 23:59:59");
        getOptSuggestionsData(from_dt, to_dt);
    }

    const onClickDownloadPredictActualHistory = () => {
        let from_dt = moment(startDate).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(endDate).format("YYYY-MM-DD 23:59:59");
        getPredictActualHistoryData(from_dt, to_dt);
    }

    const onClickDownloadAiBsHistory = () => {
        let from_dt = moment(startDate).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(endDate).format("YYYY-MM-DD 23:59:59");
        getAiBsCompareData(from_dt, to_dt);
    }

    const onClickDownloadAhuPointHistory = () => {
        let from_dt = moment(startDate).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(endDate).format("YYYY-MM-DD 23:59:59");
        getAhuPointHistoryData(from_dt, to_dt);
    }

    const onClickDownloadAhuOptSuggest = () => {
        let from_dt = moment(startDate).format("YYYY-MM-DD 00:00:00");
        let to_dt = moment(endDate).format("YYYY-MM-DD 23:59:59");
        getAhuOptSuggest(from_dt, to_dt);
    }

    const renderInputGroups = () => {
        return (
            <Row style={{float: "right"}}>
                <div className="mb-3 input-group input-group-sm" style={{width: "auto"}}>
                    <span className="input-group-text" id="inputGroup-sizing-sm">Select Date Range</span>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        isClearable={false}
                        withPortal
                        className='form-control date_range_input'
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        placeholderText="YYYY-MM-DD - YYYY-MM-DD" 
                        dateFormat="yyyy-MM-dd"
                        maxDate={maxDate}
                    />
                </div>
            </Row>
        )
    }


    const renderContent = () => {
        return (
            <div className='mb-3'>
                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>Download Center</b></h5>
                    </div>
                    <Row>
                        <Col>
                            {renderInputGroups()}
                        </Col>
                    </Row>
                    <Row>
                        <Container className='py-1' id='download_table'>
                            <Table striped bordered hover responsive style={{"verticalAlign": "baseline"}}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Details</th>
                                        <th>Download</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    building.download.includes("point_data") &&
                                    <tr>
                                        <td>
                                            Point Data History
                                        </td>
                                        <td className='text-left'>
                                            <div>
                                                Including the related plant, chillers, pumps points data that used for AI program
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                !isLoadingA && <Button variant="secondary" onClick={()=>onClickDownloadPointHistory()}>Download</Button>
                                            }
                                            {
                                                isLoadingA && 
                                                <Button variant="secondary" disabled>
                                                    <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                    <span className="visually-hidden">Loading...</span>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                }
                                {
                                    building.download.includes("opt_history") &&
                                    <tr>
                                        <td>
                                            Optimized Control History
                                        </td>
                                        <td className='text-left'>
                                            <div>
                                                Including the control sequence suggested by AI program, and the predicted total cooling and total power
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                !isLoadingB && <Button variant="secondary" onClick={()=>onClickDownloadOptSuggestion()}>Download</Button>
                                            }
                                            {
                                                isLoadingB && 
                                                <Button variant="secondary" disabled>
                                                    <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                    <span className="visually-hidden">Loading...</span>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                }
                                {
                                    building.download.includes("pred_act_compare") &&
                                    <tr>
                                        <td>
                                            Predict vs Actual History
                                        </td>
                                        <td className='text-left'>
                                            <div>
                                                Including comparison of Total Cooling Load and Total Power from prediction and actual data.
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                !isLoadingC && <Button variant="secondary" onClick={()=>onClickDownloadPredictActualHistory()}>Download</Button>
                                            }
                                            {
                                                isLoadingC && 
                                                <Button variant="secondary" disabled>
                                                    <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                    <span className="visually-hidden">Loading...</span>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                }
                                {
                                    building.download.includes("ai_bs_compare") &&
                                    <tr>
                                        <td>
                                            AI vs Base Line History
                                        </td>
                                        <td className='text-left'>
                                            <div>
                                                Including comparison of Total Cooling Load and Total Power from AI optimization data and base line prediction data.
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                !isLoadingD && <Button variant="secondary" onClick={()=>onClickDownloadAiBsHistory()}>Download</Button>
                                            }
                                            {
                                                isLoadingD && 
                                                <Button variant="secondary" disabled>
                                                    <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                    <span className="visually-hidden">Loading...</span>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                }
                                {
                                    building.download.includes("ahu_point_data") &&
                                    <tr>
                                        <td>
                                            Point Data History - AHU
                                        </td>
                                        <td className='text-left'>
                                            <div>
                                                Including the related ahu points data that used for AI program
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                !isLoadingE && <Button variant="secondary" onClick={()=>onClickDownloadAhuPointHistory()}>Download</Button>
                                            }
                                            {
                                                isLoadingE && 
                                                <Button variant="secondary" disabled>
                                                    <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                    <span className="visually-hidden">Loading...</span>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                }
                                {
                                    building.download.includes("ahu_opt_history") &&
                                    <tr>
                                        <td>
                                            Optimized Control History - AHU
                                        </td>
                                        <td className='text-left'>
                                            <div>
                                                Including the control suggested by AI program for AHU
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                !isLoadingF && <Button variant="secondary" onClick={()=>onClickDownloadAhuOptSuggest()}>Download</Button>
                                            }
                                            {
                                                isLoadingF && 
                                                <Button variant="secondary" disabled>
                                                    <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                    <span className="visually-hidden">Loading...</span>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </Table>
                        </Container>
                    </Row>
                </Container>
            </div>
        )
    }

    const renderWrap = () => {
        return (
            <div className="align-items-center justify-content-center" style={{ height: "100%" }}>
                <div className='dashboard-bg scroll_list'>
                    <Row>
                        <HeaderBage />
                    </Row>
                    <Row className='dashboard-main-content py-2 px-2' style={{minHeight: "80vh"}}>
                        {renderContent()}
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <div>
            {building && renderWrap()}
            {!building && 
                <Container className='py-4'>
                    <Spinner animation="border" variant="light" size="md" />
                </Container>
            }
        </div>
    )
}

export default DownloadMain;