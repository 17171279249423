import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import './customProgressBar.scss';

const  CustomProgressBar = ({
    propName="",
    value=0,
    platform="web",
    color="black",
    index=0
}) => {

    useEffect(()=>{
       
    },[])  

    const renderStroke = (stroke_num, idx) => {
        let opacity = 1-0.5/stroke_num*idx;
        return (
            <div 
                key={`${propName}_${idx}`} 
                style={{borderLeft: "2px solid "+color, display: "inline", marginRight: "0.5px", opacity: opacity}}
                > 
            </div>
        )
    }

    const renderGreyStroke = (idx) => {
        return (
            <div 
                key={`${propName}_grey_${idx}`} 
                style={{borderLeft: "2px solid #ddd", display: "inline", marginRight: "0.5px"}}
                >
            </div>
        )
    }

    const renderAllStrokes = () => {
        let stroke_num = Math.round(value/10);
        return new Array(10).fill(0).map((e, idx) => {
            if (idx <= stroke_num)
                return renderStroke(stroke_num, idx)
            else 
                return renderGreyStroke(idx)
        })
    }

    return(
        <div style={{display: "inline-table", marginRight: "2px", }}>
            <div className=''>
                {renderAllStrokes()}
            </div>
        </div>
    )
}

CustomProgressBar.propTypes = {
    propName: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    platform: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
};

export default CustomProgressBar;