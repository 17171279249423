import React, {useEffect, useCallback} from 'react';
import { useDispatch } from 'react-redux';
import { axiosWithXAuth } from "../utils/functions";
import { SET_BUILDING } from '../constants';

import { API_URL, BUILDING_ID} from "../utils/variables"

const withAdminAppLoader = (AppComponent) => {

    let rendered = false;
    let sendNowTime = null;

    const AppView = props => {
        const dispatch = useDispatch();
 
        const fetchBuilding = useCallback(() => {
            return new Promise((res)=>{
                axiosWithXAuth({
                    method: "get",
                    url: `${API_URL}/api/buildings`,
                    data: {}
                })
                .then((response) => response.data)
                .then((data) => {
                    let b = data.find(item => item.building_id === BUILDING_ID);
                    dispatch({ type: SET_BUILDING, building: b });
                    res(); 
                })
            })

        }, [dispatch]);

                
        const fetchData = useCallback(() => {
            fetchBuilding()
            .then(()=>{
            })
        }, [fetchBuilding]);        

        useEffect(() => {
            window.onpopstate = function(event) {
                if(event && event.state) {
                    // console.log("back: "+ event.state);
                    fetchData();
                }
            }
            if (!rendered){
                fetchData();
            }
            rendered = true;
            return () => {
                clearInterval(sendNowTime);
            }
        }, []);

        return (
            <AppComponent  {...props}/>
        )
    }

    return AppView
}

export default withAdminAppLoader