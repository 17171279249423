import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Badge, Button, Spinner, Form, FloatingLabel } from "react-bootstrap";
import { BsExclamationCircleFill as InfoIcon } from "react-icons/bs";

import { axiosWithXAuth } from '../../../utils/functions';
import { API_URL } from '../../../utils/variables';

export default function PlantSetting(){

    const building = useSelector(root => root.data.building);

    const [oldSettings, setOldSettings] = useState({});
    const [newSettings, setNewSettings] = useState({});

    const [hasUpdated, setIsUpdated] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const [inputChillerIntvl, setInputChillerIntvl] = useState();
    const [inputTempIntvl, setInputTempIntvl] = useState();
    const [inputHzIntvl, setInputHzIntvl] = useState();

    const [inputStLower, setInputStLower] = useState();
    const [inputStUpper, setInputStUpper] = useState();
    const [inputStDiff, setInputStDiff] = useState();
    const [inputDeltaTLower, setInputDeltaTLower] = useState();
    const [inputDeltaTUpper, setInputDeltaTUpper] = useState();

    const [inputHzLower, setInputHzLower] = useState();
    const [inputHzUpper, setInputHzUpper] = useState();
    const [inputHzDiff, setInputHzDiff] = useState();
    
    const [inputFrLower, setInputFrLower] = useState();
    const [inputFrUpper, setInputFrUpper] = useState();
    const [inputFrDiff, setInputFrDiff] = useState();

    const [inputClLower, setInputClLower] = useState();
    const [inputClUpper, setInputClUpper] = useState();

    const [inputRatUpper, setInputRatUpper] = useState();

    const [inputNomCl, setInputNomCl] = useState([]);
    const [inputNomPower, setInputNomPower] = useState([]);

    let sections = [
        {
            title: "Change of Interval",
            params: [
                { label: 'Chiller Sequence (min)', key: 'chillerIntvl', min: 1, max: 60, step: 1},
                { label: 'Temperature Setpoint (min)', key: 'tempIntvl', min: 1, max: 60, step: 1},
                { label: 'Pump Speed (min)', key: 'hzIntvl', min: 1, max: 60, step: 1}
            ]
        },
        {
            title: "Temperature Setpoint",
            params: [
                { label: 'Lower Limit (°C)', key: 'stLower', min: 7, max: 9, step: 0.1},
                { label: 'Upper Limit (°C)', key: 'stUpper', min: 7, max: 9, step: 0.1},
                { label: 'Maximum Change Differece (°C)', key: 'stDiff', min: 0.1, max: 1, step: 0.1},
                { label: 'DeltaT Lower Limit (°C)', key: 'deltaTLower', min: 1, max: 5, step: 0.1},
                { label: 'DeltaT Upper Limit (°C)', key: 'deltaTUpper', min: 1, max: 5, step: 0.1}
            ]
        },
        {
            title: "Pump Speed",
            params: [
                { label: 'Lower Limit (Hz)', key: 'hzLower', min: 38, max: 50, step: 1},
                { label: 'Upper Limit (Hz)', key: 'hzUpper', min: 38, max: 50, step: 1,},
                { label: 'Maximum Change Difference (Hz)', key: 'hzDiff', min: 1, max: 10, step: 1}
            ]
        },
        {
            title: "Chiller Float Rate",
            params: [
                { label: 'Lower Limit (L/s)', key: 'frLower', min: 13, max: 30, step: 0.1},
                { label: 'Upper Limit (L/s)', key: 'frUpper', min: 13, max: 30, step: 1},
                { label: 'Maximum Change Difference (L/s)', key:'frDiff', min: 1, max: 10, step: 0.1}
            ],
        },
        {
            title: "Cooling Load",
            params: [
                { label: 'Minimum Cooling Load for 1 chiller (x100%)', key: 'clLower', min: 0.1, max: 1, step: 0.01},
                { label: 'Maximum Cooling Load for 1 chiller (x100%)', key: 'clUpper', min: 0.1, max: 1, step: 0.1}
            ]
        },
        {
            title: "AHU",
            params: [
                { label: 'Return Air Temp Upper Limit (°C)', key: 'ratUpper', min: 15, max: 25, step: 0.1},
            ]
        }
    ];

    let chiller_sections = [
        { label: 'Nominal Cooling Load (kW)', key: 'nomCl', min: 10, max: 1000, step: 1},
        { label: 'Nominal Power (kW)', key: 'nomPower', min: 10, max: 1000, step: 1}
    ]

    useEffect(()=>{
        if (building){
            callSettings();
        }
    }, [building])

    const callSettings = async() => {
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/settingsPlant`,
            data: {
                "b_id": building.building_id,
            }
        })
        .then((response) => {
            let res = response.data;
            if  (res.chiller_cfg){
                res.nomCl = Object.keys(res.chiller_cfg).filter(el => el!=="max_nominal_CL"&&el!=="min_nominal_CL").map(el=> res.chiller_cfg[el].nominal_CL);
                res.nomPower = Object.keys(res.chiller_cfg).filter(el => el!=="max_nominal_CL"&&el!=="min_nominal_CL").map(el=> res.chiller_cfg[el].nominal_power);
            }

            setOldSettings(res);
            setNewSettings(res);

            setInputChillerIntvl(res.chillerIntvl);
            setInputTempIntvl(res.tempIntvl);
            setInputHzIntvl(res.hzIntvl);

            setInputStLower(res.stLower);
            setInputStUpper(res.stUpper);
            setInputStDiff(res.stDiff);
            setInputDeltaTLower(res.deltaTLower);
            setInputDeltaTUpper(res.deltaTUpper);

            setInputHzLower(res.hzLower);
            setInputHzUpper(res.hzUpper);
            setInputHzDiff(res.hzDiff);

            setInputFrLower(res.frLower);
            setInputFrUpper(res.frUpper);
            setInputFrDiff(res.frDiff);

            setInputClLower(res.clLower);
            setInputClUpper(res.clUpper);

            setInputRatUpper(res.ratUpper);

            setInputNomCl(res.nomCl);
            setInputNomPower(res.nomPower);

            setIsUpdated(false);
        })
        .catch((err)=>{
     
        })
    }

    const checkHasUpdated = (new_setting) => {
        // console.log(JSON.stringify(new_setting)===JSON.stringify(new_setting) )
        if (JSON.stringify(new_setting)===JSON.stringify(oldSettings)){
            setIsUpdated(false);
        }
        else{
            setIsUpdated(true);
        }
    }

    const onChangeSetting = (param_key, new_val, set_fn, eq_idx=null) => {
        let x = structuredClone(newSettings);
        if (eq_idx===null)
            x[param_key] = parseFloat(new_val);
        else
            x[param_key].splice(eq_idx, 1, parseFloat(new_val));

        setNewSettings(x);
        checkHasUpdated(x);

        set_fn(x[param_key]);
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true){
            event.preventDefault();
            event.stopPropagation();
            setIsSaveLoading(true);

            let chiller_cfg = newSettings.chiller_cfg;
            let max_nominal_CL = 0;
            let min_nominal_CL = 99999;
            Object.keys(chiller_cfg)
                .filter(el=>el!=="max_nominal_CL"&&el!=="min_nominal_CL")
                .map((el,idx)=>{
                    if (inputNomCl[idx]>max_nominal_CL)
                        max_nominal_CL = inputNomCl[idx];
                    if (inputNomCl[idx]<min_nominal_CL)
                        min_nominal_CL = inputNomCl[idx];
                    chiller_cfg[el]['nominal_CL'] = inputNomCl[idx];
                    chiller_cfg[el]['nominal_power'] = inputNomPower[idx];
                    chiller_cfg.max_nominal_CL = max_nominal_CL;
                    chiller_cfg.min_nominal_CL = min_nominal_CL;
                });
            
            await axiosWithXAuth({
                method: "post",
                url: `${API_URL}/api/updateSettingsPlant`,
                data: {
                    "b_id": building.building_id,
                    "cfg_content": {

                        "chillerIntvl": parseInt(inputChillerIntvl),
                        "tempIntvl": parseInt(inputTempIntvl),
                        "hzIntvl": parseInt(inputHzIntvl),

                        "stLower": parseFloat(inputStLower),
                        "stUpper": parseFloat(inputStUpper),
                        "stDiff": parseFloat(inputStDiff),
                        "deltaTLower": parseFloat(inputDeltaTLower),
                        "deltaTUpper": parseFloat(inputDeltaTUpper),
                        
                        "hzLower": parseInt(inputHzLower),
                        "hzUpper": parseInt(inputHzUpper),
                        "hzDiff": parseInt(inputHzDiff),

                        "frLower": parseFloat(inputFrLower),
                        "frUpper": parseFloat(inputFrUpper),
                        "frDiff": parseFloat(inputFrDiff),

                        "clLower": parseFloat(inputClLower),
                        "clUpper": parseFloat(inputClUpper),

                        "ratUpper": parseFloat(inputRatUpper),

                        "chiller_cfg": chiller_cfg
                    }
                }
            })
            .then((response) => {
                let res = response.data;

                form.classList.remove("was-validated");
                form.reset();
                callSettings();

                setTimeout(()=>{
                    setIsSaveLoading(false);
                }, 2000);
            })
            .catch((err) => {
                form.classList.remove("was-validated");
                form.reset();
                callSettings();
                setIsSaveLoading(false);
            })
        }
      };

    const renderInput = (param, eq_idx=null) => {
        let param_key = param.key;
        let input_label = param.label;
        let min = param.min;
        let max = param.max;
        let step = param.step;

        let param_val = null;
        let set_fn = null;

        switch (param_key) {
            case "chillerIntvl": param_val = inputChillerIntvl; set_fn = (x) => setInputChillerIntvl(x); break;
            case "tempIntvl": param_val = inputTempIntvl; set_fn = (x) => setInputTempIntvl(x); break;
            case "hzIntvl": param_val = inputHzIntvl; set_fn = (x) => setInputHzIntvl(x); break;

            case "stLower": param_val = inputStLower; set_fn = (x) => setInputStLower(x); break;
            case "stUpper": param_val = inputStUpper; set_fn = (x) => setInputStUpper(x); break;
            case "stDiff": param_val = inputStDiff; set_fn = (x) => setInputStDiff(x); break;
            case "deltaTLower": param_val = inputDeltaTLower; set_fn = (x) => setInputDeltaTLower(x); break;
            case "deltaTUpper": param_val = inputDeltaTUpper; set_fn = (x) => setInputDeltaTUpper(x); break;

            case "hzLower": param_val = inputHzLower; set_fn = (x) => setInputHzLower(x); break;
            case "hzUpper": param_val = inputHzUpper; set_fn = (x) => setInputHzUpper(x); break;
            case "hzDiff": param_val = inputHzDiff; set_fn = (x) => setInputHzDiff(x); break;

            case "frLower": param_val = inputFrLower; set_fn = (x) => setInputFrLower(x); break;
            case "frUpper": param_val = inputFrUpper; set_fn = (x) => setInputFrUpper(x); break;
            case "frDiff": param_val = inputFrDiff; set_fn = (x) => setInputFrDiff(x); break;

            case "clLower": param_val = inputClLower; set_fn = (x) => setInputClLower(x); break;
            case "clUpper": param_val = inputClUpper; set_fn = (x) => setInputClUpper(x); break;

            case "ratUpper": param_val = inputRatUpper; set_fn = (x) => setInputRatUpper(x); break;

            case "nomCl": param_val = inputNomCl[eq_idx]; set_fn = (x) => setInputNomCl(x); break;
            case "nomPower": param_val = inputNomPower[eq_idx]; set_fn = (x) => setInputNomPower(x); break;

            default: return;
        }

        if (eq_idx === null){
            return(
                <Col xs={12} md={6} xl={4} key={`input_${param_key}`}>
                    <Form.Group controlId={`${param_key}_validationCustom`}>
                        <FloatingLabel controlId={`${param_key}_floatingInput`} label={input_label} className="mb-3" >
                            <Form.Control 
                                disabled={isSaveLoading}
                                required 
                                type="number" 
                                min={min}
                                max={max}
                                step={step}
                                onChange={(e)=>onChangeSetting(param_key, e.target.value, set_fn)}
                                value={param_val || 0} />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            )
        }
        else {
            let param_key_by_eq = param_key + eq_idx;
            return(
                <Col xs={12} md={6} xl={4} key={`input_${param_key_by_eq}`}>
                    <Form.Group controlId={`${param_key_by_eq}_validationCustom`}>
                        <FloatingLabel controlId={`${param_key_by_eq}_floatingInput`} label={input_label} className="mb-3" >
                            <Form.Control 
                                disabled={isSaveLoading}
                                required 
                                type="number" 
                                min={min}
                                max={max}
                                step={step}
                                onChange={(e)=>onChangeSetting(param_key, e.target.value, set_fn, eq_idx)}
                                value={param_val || 0} />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            )
        }
    }

    const renderSection = (section, eq_idx=null) => {
        return (
            <div className='color_container mx-2 mb-4' key={`section_${section.title}`}>
                <div className="color_grid">
                    <div className='title'> {section.title}</div>
                </div>
                <Container className='px-2 py-2'>
                    <Row>
                    {
                        section.params.map(param =>
                            renderInput(param, eq_idx)
                        )
                    }
                    </Row>
                </Container>
            </div>
        )
    }

    const renderChillers = (chillers) => {
        return chillers.map((chiller,idx) => {
            let section = {
                title: chiller,
                params: chiller_sections
            };
            return renderSection(section, idx)
        })
    }

    return (
        <>
            <div className='config_header'>Plant Configuration</div>
            <div className='pb-3'></div>
            <Container>
                <Form validated={validated} onSubmit={handleSubmit}>
                    {
                        sections.map(section =>
                            renderSection(section)
                        )
                    }
                    {
                        building && renderChillers(building.chillers)
                    }
                    <Row>
                        <Col>
                        {hasUpdated && !isSaveLoading && <Button variant="primary" type="submit">Save</Button>}
                        {!hasUpdated && !isSaveLoading && <Button variant="secondary" disabled>Save</Button>}
                        {isSaveLoading && 
                            <Button variant="secondary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span className="visually-hidden">Loading...</span>
                            </Button>
                        }
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}