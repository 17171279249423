import React,{ useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Table, Dropdown, Button, ProgressBar, ButtonGroup, DropdownButton } from "react-bootstrap";
import moment from "moment";

import {TbTemperatureCelsius as CelsiusIcon} from "react-icons/tb";
import { FaTemperatureHalf as TempIcon} from "react-icons/fa6";
import { BsSpeedometer as SpeedIcon, BsThermometerSnow as FreeCoolIcon } from "react-icons/bs";
import { GiBrain as AIIcon } from "react-icons/gi";
import { PiFanFill as FanIcon } from "react-icons/pi";
import { FaPercentage  as PercentIcon } from "react-icons/fa";

import { API_URL } from "../../../utils/variables";
import { axiosWithXAuth, CustomToggleMore } from "../../../utils/functions";
import AHUSuggestHistory from '../modal/AHUSuggestHistory';
import RadialBarChart from '../chart/RadialBarChart';

function AHUSuggestion({platform, recordnum}) {

    const building = useSelector(root => root.data.building);
    const rtData = useSelector(root => root.data.realtimeData);
    
    const [showOptHistoryModal, setShowOptHistoryModal] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = recordnum;
    let smallfont = platform==="web"?"":"small_font";

    const [ahuIdxSelected, setAhuIdxSelected] = useState(0);

    useEffect(() => {
        setPage(1);
        callOptSuggestions();
    }, [])

    useEffect(() => {
        let b_code = building.building_ibms_id.toLowerCase();
        let th_rtData = rtData[b_code];

        if (suggestions.length>0 && th_rtData!==undefined)
            updateSuggestions(th_rtData);
    }, [rtData]);

    const callOptSuggestions = async () => {
        
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/optAhuSuggestions`,
            data: {
                "b_id": building.building_id,
                "limit": 30
            }
        })
            .then((response) => {
                let data = response.data;
                let result = []
                data.forEach((element, index) => {
                    result.push({
                        "cdt": element.cdt,
                        "name": element.name,
                        "opt_ch_st": element.opt_ch_st,
                        "opt_sat": element.opt_sat,
                        "opt_fdamp": element.opt_fdamp,
                        "opt_rdamp": element.opt_rdamp,
                        "opt_vlv": element.opt_vlv,
                        "opt_spd": element.opt_spd,
                        "mode": element.mode,
                        "is_free_cool": element.is_free_cool
                    })
                })
                
                setSuggestions(result);
            })
    }

    const updateSuggestions = (th_rtData) => {
        if (th_rtData.suggestions_ahu===undefined || th_rtData.suggestions_ahu.length<=0) return false;

        let suggestions_copy = suggestions;

        let x = th_rtData.suggestions_ahu[0];
        let y = th_rtData.suggestions_ahu[1];
        let z = th_rtData.suggestions_ahu[2];

        let new_add = [];
        [x,y,z].forEach((element) => {
            let is_included = suggestions_copy.find(item => item.cdt === element.cdt);
            if (is_included === undefined){
                new_add.push({
                    "cdt": element.cdt,
                    "name": element.name,
                    "opt_ch_st": element.opt_ch_st,
                    "opt_sat": element.opt_sat,
                    "opt_fdamp": element.opt_fdamp,
                    "opt_rdamp": element.opt_rdamp,
                    "opt_vlv": element.opt_vlv,
                    "opt_spd": element.opt_spd,
                    "mode": element.mode,
                    "is_free_cool": element.is_free_cool
                });
            }
        });
        
        if (new_add.length>0){
            let new_array = new_add.concat(suggestions_copy);
            setSuggestions(new_array);
        }
    }

    const renderSuggestion = (suggest, index) => {
        let cdt = new Date(suggest.cdt);
        let opt_dt = moment(cdt).format("DD/MM h:mm a");
        let opt_dt2 = moment(cdt).format("MMDD_hhmm");
        let name = suggest.name.slice(0, 2);

        let sat = suggest.opt_sat !== null ? suggest.opt_sat.toFixed(1): null;
        let fdamp = suggest.opt_fdamp !== null? parseFloat(suggest.opt_fdamp.toFixed(0)): null;
        let rdamp = suggest.opt_rdamp !== null? parseFloat(suggest.opt_rdamp.toFixed(0)): null;
        let vlv = suggest.opt_vlv !== null? parseFloat(suggest.opt_vlv.toFixed(0)): null;
        let spd = suggest.opt_spd !== null? suggest.opt_spd.toFixed(0): null;

        let item_num = (page-1) * itemsPerPage + index + 1;
        return (
            <tr key={`ahu_sug_${index}`}>
                <td>{item_num}
                    {
                        suggest.mode==="AI" && 
                        <>&ensp;
                        <AIIcon size={16} color="#198754" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                    {
                        suggest.is_free_cool===1 && 
                        <>&ensp;
                        <FreeCoolIcon size={16} color="#0D6EFD" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                </td>
                <td>{opt_dt}</td>
                <td>{name}</td>
                <td>{sat && <><TempIcon color='#0D6EFD' style={{marginTop: "-5px"}} /> {sat} <CelsiusIcon /></>}</td>
                <td className='px-0 py-0'>
                    {/* {rdamp} <PercentIcon size={13} style={{marginTop: "-5px"}}/> */}
                    <RadialBarChart 
                        chartTitle='RA Damper'
                        chartId={`rdamp_${index}_${platform}`}
                        chartHeight={80}
                        divMaxHeight={30}
                        divMarginTop={-5}
                        value={rdamp}
                        platform={platform}
                        color="#008000"
                        colorTo="#006b00"
                        chartValueEnabled={true}
                    />
                </td>
                <td className='px-0 py-0'>
                    {/* {fdamp} <PercentIcon size={13} style={{marginTop: "-5px"}}/> */}
                    <RadialBarChart 
                        chartTitle='FA Damper'
                        chartId={`fdamp_${index}_${platform}`}
                        chartHeight={80}
                        divMaxHeight={30}
                        divMarginTop={-5}
                        value={fdamp}
                        platform={platform}
                        color="#e65c00"
                        colorTo="#c75000"
                        chartValueEnabled={true}
                    />
                </td>
                <td className='px-0 py-0'>
                    {/* {vlv} <PercentIcon size={13} style={{marginTop: "-5px"}}/> */}
                    <RadialBarChart 
                        chartTitle='Valve'
                        chartId={`vlv_${index}_${platform}`}
                        chartHeight={80}
                        divMaxHeight={30}
                        divMarginTop={-5}
                        value={vlv}
                        platform={platform}
                        color="#035996"
                        colorTo="#023e69"
                        chartValueEnabled={true}
                    />
                </td>
                <td>
                    {/* {spd && <><FanIcon color='#034f84' style={{marginTop: "-5px"}}/> {spd}</>} */}
                    <Row className="px-0">
                        {platform==="web" && <Col xs={12} sm={2} md={2} lg={3} xl={2} className="px-0"><FanIcon color='#034f84' /></Col>}
                        <Col xs={10} sm={8} md={8} lg={8} xl={8} className="pt-1 px-0">
                            <ProgressBar animated now={spd} label={`${spd}Hz`} min={0} max={50} />
                        </Col>
                    </Row>
                </td>
            </tr>
        )
    }

    const renderSuggestionWrap = () => {
        if (suggestions.length > 0){

            let from_item = page===1? 1: (page-1) * itemsPerPage + 1;
            let to_item = from_item + itemsPerPage - 1;
            let suggestions_in_page = 
                suggestions
                .filter(e=>e.name===building.ahus[ahuIdxSelected])
                .filter(
                    (element, index) => { 
                        return index+1 >= from_item && index+1 <= to_item
                    });

            return suggestions_in_page.map((element, index) => {
                return renderSuggestion(element, index);
            })
        }
    }

    const onClickAhu = (idx) => {
        setAhuIdxSelected(idx);
    }

    const renderAHUbtn = (ahu, idx, platform) => {
        let style_class = idx===ahuIdxSelected? "selected":"";
        if (platform === "web")
            return (
                <Col xs={1} md={1} lg={1} className={`equip_btn ${style_class}`} onClick={()=>{onClickAhu(idx)}}>
                    <div>
                        {building.ahu_display_names[idx]}
                    </div>
                </Col>
        )
        else {
            return (
                <Dropdown.Item key={`ahu_dropdown_${idx}`} eventKey={idx} onClick={()=>{onClickAhu(idx)}}>{building.ahu_display_names[idx]}</Dropdown.Item>
            )
        }
    }

    const renderAHUlist = (platform) => {
        if (!building.ahus)
            return (<div></div>)

        return building.ahus.map((e, idx)=>{
            return renderAHUbtn(e, idx, platform)
        })
    }

    return (
        <Container className='card_bg mt-2 pt-3 pb-2'>
            <div className='card_title_wrap py-2 d-none d-sm-none d-md-block'>
                <h5><b>AHU Control Suggestion</b></h5>
                <Dropdown className='btn_show_more'>
                    <Dropdown.Toggle id="dropdown-button-dark-example1" as={CustomToggleMore} >
                        Custom Toggle
                    </Dropdown.Toggle>
                    <Dropdown.Menu align={{md: "start"}}>
                        <Dropdown.Item eventKey="1" onClick={()=>setShowOptHistoryModal(true)}>Show More</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown >
                <ButtonGroup className='header_btn_gp' style={{position:"absolute", top:0, right:"3rem"}}>
                    <DropdownButton as={ButtonGroup} title={building.ahu_display_names[ahuIdxSelected]} id="ahu-dropdown">
                        {
                            renderAHUlist("mobile")
                        }
                    </DropdownButton>
                </ButtonGroup>
            </div>
            <Table striped border="true" hover responsive className={`mb-0 ${smallfont}`} style={{overflow: "hidden"}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date Time</th>
                        <th>Floor</th>
                        <th>SAT</th>
                        <th>RA Damper</th>
                        <th>FA Damper</th>
                        <th>CHW Valve</th>
                        <th>Fan Speed</th>
                    </tr>
                </thead>
                <tbody>
                    {renderSuggestionWrap()}
                </tbody>
            </Table>
            <div className='text-left mb-2' style={{fontSize: "small"}}>
                <AIIcon size={14} color="#198754" style={{"marginTop": "-3px"}}/> AI mode&ensp;
                <FreeCoolIcon size={14} color="#0D6EFD" style={{"marginTop": "-3px"}}/> Free Cooling
            </div>
            <Row className='d-sm-block d-md-none'>
                <Col>
                    <Button variant="secondary" onClick={()=>setShowOptHistoryModal(true)}>Control History</Button>{' '}
                </Col>
                
            </Row>
            <Row className='d-sm-block d-md-none mt-2'>
                <Col>
                    <ButtonGroup className='header_btn_gp'>
                        <DropdownButton as={ButtonGroup} title={building.ahu_display_names[ahuIdxSelected]} id="ahu-dropdown">
                            {
                                renderAHUlist("mobile")
                            }
                        </DropdownButton>
                    </ButtonGroup>
                </Col>
            </Row>
            <AHUSuggestHistory
                showModal={showOptHistoryModal}
                setShowModal={setShowOptHistoryModal}
                platform={platform}
                recordnum={8}
                ahuSelectedIdx={ahuIdxSelected}
            />
        </Container>
        
    )
}

export default AHUSuggestion;