import { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner, InputGroup, Form, Tab, Nav } from "react-bootstrap";
import { useSelector } from 'react-redux';

import { API_URL } from "../../utils/variables"
import { axiosWithXAuth } from "../../utils/functions"

import './css.scss';
// import HeaderBage from '../components/contentWrap/HeaderBage';
import HeaderBage from '../components/header/HeaderBage';


function SiteSettingMain() {

    const [siteConfig, setSiteConfig] = useState(null);

    const building = useSelector(root => root.data.building);

    useEffect(() => {
        if (building !== null){
            getConfig();
        }
    }, [building]);

    const getConfig = () => {
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/optConfig`,
            data: {
                "b_code": building.building_ibms_id.toLowerCase(),
                "b_id": building.building_id
            }
        })
            .then((response) => {
                setSiteConfig(response.data);
            })
            .catch((err) => {

            })
    }

    const renderInputGroup = (label, value, desc, unit) => {
        return (
            <Col key={`input_${label}`} xs={12} sm={6} md={6} lg={6}>
                <Row>
                    <InputGroup size="sm" >
                        <Col className='pe-0' >
                            <InputGroup.Text style={{"borderRadius": "8px 0 0 8px"}}>{label}</InputGroup.Text>
                        </Col>
                        <Col className='ps-0'>
                            <Form.Control type="text" value={value+" "+unit} disabled style={{"borderRadius": "0 8px 8px 0"}}/>
                        </Col>
                    </InputGroup> 
                </Row>
                <Row>
                    <Form.Text muted className='text-left'>{desc}</Form.Text>
                </Row>
            </Col>
        )
    }

    const renderCateGeneral = (config) => {
        return Object.keys(config).map((key, idx)=>{
            let desc = "";
            let value = config[key];
            let unit = "";
            switch (key){
                case "no_of_chillers": desc = "Number of chillers in site"; break;
                case "Hz_interval": 
                    desc = "Interval for updating pump speed"; unit="/min"; break;
                case "Temp_interval": 
                    desc = "Interval for updating chilled water supply setpoint"; unit="/min"; break;
                case "CH_interval": 
                    desc = "Interval for updating chiller sequence & demand"; unit="/min"; break;
                default:
                    return;
            }
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateFlowRate = (config) => {
        return Object.keys(config).map((key, idx)=>{
            let desc = "";
            let value = config[key];
            let unit = "";
            switch (key){
                case "flow_of_chillers": 
                    desc = "Upper limit of chilled water flow rate of chillers"; unit="L/s"; break;
                case "flow_of_ch_lower": 
                    desc = "Lower limit of chilled water flow rate of chillers"; value = value*100; unit="%"; break;
                case "flow_default_rate": 
                    desc = "Default flow rate of chiller"; value = value*100; unit="%"; break;
                case "ch_flow_diff_checking": 
                    desc = "Maximum flow rate of chiller changes in each interval"; unit="L/s"; break;
                default:
                    return;
            }
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCatePower = (config) => {
        return Object.keys(config).map((key, idx)=>{
            let desc = "";
            let value = config[key];
            let unit = "";
            switch (key){
                case "min_Power": 
                    desc = "Minimum power of a running chiller (to consider a chiller is turned on)"; unit="kW"; break;
                case "power_threshold_chwp": 
                    desc = "Threshold to consider a chiller pump is turned on"; unit="kW"; break;
                case "default_ch_power": 
                    desc = "Default power of chiller for AI model"; unit="kW"; break;
                case "chiller_cfg":
                    desc = "Nominal power for each chiller";
                    unit="kW"; 
                    return Object.keys(config["chiller_cfg"]).map((key2, idx)=>{
                        if (key2==="max_nominal_CL"||key2==="min_nominal_CL")
                            return;
                        key = "nominal_power - " + key2
                        value = config["chiller_cfg"][key2]["nominal_power"];
                        return renderInputGroup(key,value,desc,unit)
                    })
                default:
                    return;
            }
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateTemp = (config) => {
        return Object.keys(config).map((key, idx)=>{
            let desc = "";
            let value = config[key];
            let unit = "";
            switch (key){
                case "T_alarm_R": 
                    desc = "High return temperature alarm of plant"; unit="°C"; break;
                case "T_alarm_S": 
                    desc = "High supply temperature alarm of plant"; unit="°C"; break;
                case "Ts_lower_glimit": 
                    desc = "Lower limit of chilled water supply temperature"; unit="°C"; break;
                case "Ts_upper_glimit": 
                    desc = "Upper limit of chilled water supply temperature"; unit="°C"; break;
                case "Tr_lower_glimit": 
                    desc = "Lower limit of chilled water return temperature"; unit="°C"; break;
                case "Tr_upper_glimit": 
                    desc = "Upper limit of chilled water return temperature"; unit="°C"; break;
                case "DeltaT_lower_limit": 
                    desc = "Lower limit of chilled water temperature difference"; unit="°C"; break;
                case "DeltaT_upper_limit": 
                    desc = "Upper limit of chilled water temperature difference"; unit="°C"; break;
                case "Temp_change_range": 
                    desc = "Maximum temperature changes in each interval"; unit="°C"; break;
                default:
                    return;
            }
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateHz = (config) => {
        return Object.keys(config).map((key, idx)=>{
            let desc = "";
            let value = config[key];
            let unit = "";
            switch (key){
                case "default_Hz": 
                    desc = "Default VSD frequency of chilled water pumps"; unit="Hz"; break;
                case "Hz_upper_glimit": 
                    desc = "Upper Limit of VSD frequency of chilled water pumps"; unit="Hz"; break;
                case "Hz_lower_glimit": 
                    desc = "Lower Limit of VSD frequency of chilled water pumps"; unit="Hz"; break;
                case "dHz": 
                    desc = "Allowed changes in Hz for each Hz reduction"; unit="Hz"; break;
                default:
                    return;
            }
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateDiffPress = (config) => {
        return Object.keys(config).map((key, idx)=>{
            let desc = "";
            let value = config[key];
            let unit = "";
            switch (key){
                case "Max_dp": 
                    desc = "Maximum allowed differential pressure of plant"; unit="kPa"; break;
                case "Min_dp": 
                    desc = "Minimum allowed differential pressure of plant"; unit="kPa"; break;
                default:
                    return;
            }
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateCoolload = (config) => {
        return Object.keys(config).map((key, idx)=>{
            let desc = "";
            let value = config[key];
            let unit = "";
            switch (key){
                case "Min_partload": 
                    desc = "Minimum partload of chiller"; value = value*100; unit="%"; break;
                case "Max_coolload": 
                    desc = "Maximum cooling load of chiller"; value = value*100; unit="%"; break;
                case "chiller_cfg":
                    desc = "Nominal cooling load for each chiller";
                    unit="kW"; 
                    return Object.keys(config["chiller_cfg"]).map((key2, idx)=>{
                        if (key2==="max_nominal_CL"||key2==="min_nominal_CL")
                            return;
                        key = "nominal_CL - " + key2
                        value = config["chiller_cfg"][key2]["nominal_CL"];
                        return renderInputGroup(key,value,desc,unit)
                    })
                default:
                    return;
            }
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateFreeCool = (config) => {
        return Object.keys(config).map((key, idx)=>{
            let desc = "";
            let value = config[key];
            let unit = "";
            switch (key){
                case "Free_cool_sat_sp": 
                    desc = "Supply air temperature setpoint"; unit="°C"; break;
                case "Free_cool_outtemp": 
                    desc = "Resume normal operation from free cooling when outdoor temperature >= x"; unit="°C"; break;
                case "Free_cool_partload": 
                    desc = "Perform free cooling if actual total cooling load < x"; value = value*100; unit="%"; break;
                default:
                    return;
            }
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateAhuIntvl = (config) => {
        return Object.keys(config).map((key, idx)=>{
            let desc = "";
            let value = config[key];
            let unit = "";
            switch (key){
                case "intvl_sat": 
                    desc = "Interval for updating supply air temperature"; unit="/min"; break;
                case "intvl_safr": 
                    desc = "Interval for updating supply air flow rate or fan speed"; unit="/min"; break;
                case "intvl_chwfr": 
                    desc = "Interval for updating chilled water flow rate or valve position"; unit="/min"; break;
                case "intvl_fdamp": 
                    desc = "Interval for updating fresh air damper position"; unit="/min"; break;
                case "intvl_rdamp": 
                    desc = "Interval for updating return air damper position"; unit="/min"; break;
                default:
                    return;
            }
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateAhuSa = (config) => {
        return Object.keys(config).map((param_name, idx)=>{
            let desc = "";
            let value = null;
            let unit = "";
            let key = "";
            switch (param_name){
                case "safr_lo_limit":
                    desc = "Lower limit of supply air float rate"; unit="L/s"; break;
                case "safr_up_limit":
                    desc = "Upper limit of supply air float rate"; unit="L/s"; break;
                case "sat_lo_limit":
                    desc = "Lower limit of supply air temp"; unit="°C"; break;
                case "sat_up_limit":
                    desc = "Upper limit of supply air temp"; unit="°C"; break;
                default:
                    return;
            }
            value = config[param_name];
            key = param_name;
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateAhuRa = (config) => {
        return Object.keys(config).map((param_name, idx)=>{
            let desc = "";
            let value = null;
            let unit = "";
            let key = "";
            switch (param_name){
                case "co2_up_limit":
                    desc = "Upper limit of CO2"; unit="ppm"; break;
                case "rarh_lo_limit":
                    desc = "Lower limit of return air humidity"; unit="%"; break;
                case "rarh_up_limit":
                    desc = "Upper limit of return air humidity"; unit="%"; break;
                case "rat_lo_limit":
                    desc = "Lower limit of return air temp"; unit="°C"; break;
                case "rat_up_limit":
                    desc = "Upper limit of return air temp"; unit="°C"; break;
                default:
                    return;
            }
            value = config[param_name];
            key = param_name;
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateAhuFan = (config) => {
        return Object.keys(config).map((param_name, idx)=>{
            let desc = "";
            let value = null;
            let unit = "";
            let key = "";
            switch (param_name){
                case "hz_lo_limit":
                    desc = "Lower limit of fan speed"; unit="Hz"; break;
                case "hz_up_limit":
                    desc = "Upper limit of fan speed"; unit="Hz"; break;
                case "hz_diff":
                    desc = "Max. change difference of fan speed"; unit="Hz"; break;
                default:
                    return;
            }
            value = config[param_name];
            key = param_name;
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateAhuChw = (config) => {
        return Object.keys(config).map((param_name, idx)=>{
            let desc = "";
            let value = null;
            let unit = "";
            let key = "";
            switch (param_name){
                case "chwfr_lo_limit":
                    desc = "Lower limit of chilled water float rate"; unit="L/s"; break;
                case "chwfr_up_limit":
                    desc = "Upper limit of chilled water float rate"; unit="L/s"; break;
                case "chwot_lo_limit":
                    desc = "Lower limit of chilled water outlet temp"; unit="°C"; break;
                case "chwot_up_limit":
                    desc = "Upper limit of chilled water outlet temp"; unit="°C"; break;
                case "chwfr_diff":
                    desc = "Max. change difference of float rate"; unit="L/s"; break;
                default:
                    return;
            }
            value = config[param_name];
            key = param_name;
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderCateAhuPosition = (config) => {
        return Object.keys(config).map((param_name, idx)=>{
            let desc = "";
            let value = null;
            let unit = "";
            let key = "";
            switch (param_name){
                case "fadamp_lo_limit":
                    desc = "Lower limit of fresh air damper position"; unit="%"; break;
                case "fadamp_up_limit":
                    desc = "Upper limit of fresh air damper position"; unit="%"; break;
                case "radamp_lo_limit":
                    desc = "Lower limit of return air damper position"; unit="%"; break;
                case "radamp_up_limit":
                    desc = "Upper limit of return air damper position"; unit="%"; break;
                case "vlv_lo_limit":
                    desc = "Lower limit of chilled water valve position"; unit="%"; break;
                case "vlv_up_limit":
                    desc = "Upper limit of chilled water valve position"; unit="%"; break;
                case "fdamp_diff":
                    desc = "Max. change difference of fresh air damper"; unit="%"; break;
                case "rdamp_diff":
                    desc = "Max. change difference of return air damper"; unit="%"; break;
                default:
                    return;
            }
            value = config[param_name];
            key = param_name;
            return renderInputGroup(key,value,desc,unit)
        })
    }

    const renderAhus = (config_per_ahu, ahu_name, accord_num) => {
        return (
            <Tab.Pane eventKey={`ahu_${accord_num}`} className='px-4'>
                {/* <Container className='equip_name_title mt-2 py-2 px-2'>
                    <h4 className='my-0'><b>{ahu_name}</b></h4>
                </Container> */}
                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>AHU Supply Air</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateAhuSa(config_per_ahu)}
                    </Row>
                </Container>

                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>AHU Return Air</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateAhuRa(config_per_ahu)}
                    </Row>
                </Container>

                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>AHU Fan Speed</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateAhuFan(config_per_ahu)}
                    </Row>
                </Container>

                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>AHU Chilled Water</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateAhuChw(config_per_ahu)}
                    </Row>
                </Container>

                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>AHU Valve / Damper Position</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateAhuPosition(config_per_ahu)}
                    </Row>
                </Container>
            </Tab.Pane>
        )
    }

    const renderNavChiller = () => {
        return (
            <>
                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>Chiller Control Interval</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateGeneral(siteConfig.ch_cfg)}
                    </Row>
                </Container>

                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>Chilled Water Flow Rate</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateFlowRate(siteConfig.ch_cfg)}
                    </Row>
                </Container>

                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>Power</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCatePower(siteConfig.ch_cfg)}
                    </Row>
                </Container>

                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>Chilled Water Temperature</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateTemp(siteConfig.ch_cfg)}
                    </Row>
                </Container>

                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>Chilled Water Pump</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateHz(siteConfig.ch_cfg)}
                    </Row>
                </Container>

                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>Differential Pressure</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateDiffPress(siteConfig.ch_cfg)}
                    </Row>
                </Container>

                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>Cooling Load</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateCoolload(siteConfig.ch_cfg)}
                    </Row>
                </Container>
            </>
        )
    }

    const renderNavFreeCool = () => {
        return (
            <>
                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>Free Cooling</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateFreeCool(siteConfig.fc_cfg)}
                    </Row>
                </Container>
            </>
        )
    }

    const renderNavAhu = () => {
        return (
            <>
                <Container className='card_bg mt-2 py-3'>
                    <div className='card_title_wrap py-2'>
                        <h5><b>AHU Control Interval</b></h5>
                    </div>
                    <Row>
                        {siteConfig && renderCateAhuIntvl(siteConfig.ahu_cfg)}
                    </Row>
                </Container>
                
                <Container className='mt-2 py-2 px-0'>
                    <Tab.Container
                        defaultActiveKey="ahu_0"
                        id="nav_ahu_config"
                        >
                        <Nav variant="pills" className="tabs_sub_tab flex-row justify-content-center">
                            {siteConfig && 
                                Object.keys(siteConfig.ahu_cfg.data_range).map((ahu_name, idx) => {
                                    return (
                                        <Nav.Item>
                                            <Nav.Link eventKey={`ahu_${idx}`}>{ahu_name}</Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }
                        </Nav>
                        <Tab.Content>
                            {siteConfig && 
                                Object.keys(siteConfig.ahu_cfg.data_range).map((ahu_name, idx) => {
                                    return renderAhus(siteConfig.ahu_cfg.data_range[ahu_name], ahu_name, idx)
                                })
                            }
                        </Tab.Content>
                    </Tab.Container>
                </Container>
            </>
        )
    }

    const renderCategories = () => {
        return (
            <Tab.Container id="nav_config" defaultActiveKey="chiller" >
                <Nav variant="pills" className="flex-row justify-content-center tabs_sub_tab">
                    <Nav.Item>
                        <Nav.Link eventKey="chiller">Chiller</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="fc">Free Cooling</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="ahu">AHU</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content >
                    <Tab.Pane eventKey="chiller">
                        {renderNavChiller()}
                    </Tab.Pane>
                    <Tab.Pane eventKey="fc">
                        {renderNavFreeCool()}
                    </Tab.Pane>
                    <Tab.Pane eventKey="ahu">
                        {renderNavAhu()}
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        )
    }

    const renderContent = () => {
        return (
            <div className="align-items-center justify-content-center" style={{ height: "100%" }}>
                <div className='dashboard-bg scroll_list'>
                    <Row>
                        <HeaderBage />
                    </Row>
                    <Row className='dashboard-main-content py-2 px-2'>
                        {renderCategories()}
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <div>
            {building && renderContent()}
            {!building && 
                <Container className='py-4'>
                    <Spinner animation="border" variant="light" size="md" />
                </Container>
            }
        </div>
    )
}

export default SiteSettingMain;