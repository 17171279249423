import { Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TbLogout as LogoutIcon } from "react-icons/tb";

import "./css.scss";
import SiteInfo from "./SiteInfo";
import AdminCategory from "./Category";
import SubPage from "./SubPage";
import { axiosWithXAuth } from '../../utils/functions';
import { API_URL } from '../../utils/variables';

export default function AdminMain(){
    const navigate = useNavigate();

    const callLogout = () => {
        axiosWithXAuth({
            method: "get",
            url: `${API_URL}/api/logout`,
            data: {}
        })
        .then((response) => {
            window.localStorage.removeItem("xauth");
            // window.location.href = PATH_PREFIX+"/login";
            navigate('/adminlogin'); 
        })
    }

    return (
        <Container className="basic_wrap py-3 px-3">
            <div style={{position: "relative", "cursor": "pointer"}}>
                <div style={{position: "absolute", right: 0}} onClick={()=>callLogout()}>
                    <b>Logout</b> <LogoutIcon size={25}/>
                </div>
            </div>
            {/* <SiteInfo/> */}
            <AdminCategory/>
            <hr/>
            <SubPage/>
        </Container>
    )
}