import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Badge, Button, Spinner, Form, FloatingLabel } from "react-bootstrap";
import { BsExclamationCircleFill as InfoIcon } from "react-icons/bs";

import { axiosWithXAuth } from '../../../utils/functions';
import { API_URL } from '../../../utils/variables';

export default function AhuSetting(){

    const building = useSelector(root => root.data.building);

    const [oldSettings, setOldSettings] = useState({});
    const [newSettings, setNewSettings] = useState({});

    const [hasUpdated, setIsUpdated] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    
    const [inputChStDiff, setInputChStDiff] = useState();

    const [inputSafrLower, setInputSafrLower] = useState([]);
    const [inputSafrUpper, setInputSafrUpper] = useState([]);
    const [inputChwfrLower, setInputChwfrLower] = useState([]);
    const [inputChwfrUpper, setInputChwfrUpper] = useState([]);
    const [inputChwotLower, setInputChwotLower] = useState([]);
    const [inputChwotUpper, setInputChwotUpper] = useState([]);
    const [inputHzLower, setInputHzLower] = useState([]);
    const [inputHzUpper, setInputHzUpper] = useState([]);
    const [inputSatLower, setInputSatLower] = useState([]);
    const [inputSatUpper, setInputSatUpper] = useState([]);
    const [inputRatLower, setInputRatLower] = useState([]);
    const [inputRatUpper, setInputRatUpper] = useState([]);
    const [inputRarhLower, setInputRarhLower] = useState([]);
    const [inputRarhUpper, setInputRarhUpper] = useState([]);
    const [inputFadsLower, setInputFadsLower] = useState([]);
    const [inputFadsUpper, setInputFadsUpper] = useState([]);
    const [inputRadsLower, setInputRadsLower] = useState([]);
    const [inputRadsUpper, setInputRadsUpper] = useState([]);
    const [inputVlvLower, setInputVlvLower] = useState([]);
    const [inputVlvUpper, setInputVlvUpper] = useState([]);
    const [inputCo2Upper, setInputCo2Upper] = useState([]);

    const [inputHzDiff, setInputHzDiff] = useState([]);
    const [inputChwfrDiff, setInputChwfrDiff] = useState([]);
    const [inputRdampDiff, setInputRdampDiff] = useState([]);
    const [inputFdampDiff, setInputFdampDiff] = useState([]);
    const [inputVlvDiff, setInputVlvDiff] = useState([]);

    const [inputPsoHzDiff, setInputPsoHzDiff] = useState([]);
    const [inputPsoChwfrDiff, setInputPsoChwfrDiff] = useState([]);
    const [inputPsoRdampDiff, setInputPsoRdampDiff] = useState([]);
    const [inputPsoFdampDiff, setInputPsoFdampDiff] = useState([]);

    const [inputIntvlSat, setInputIntvlSat] = useState();
    const [inputIntvlSafr, setInputIntvlSafr] = useState();
    const [inputIntvlChwfr, setInputIntvlChwfr] = useState();
    const [inputIntvlFdamp, setInputIntvlFdamp] = useState();
    const [inputIntvlRdamp, setInputIntvlRdamp] = useState();

    let sections = [
        {
            title: "Change of Interval",
            params: [
                { label: 'Supply Air Temperature (min)', key: 'intvlSat', min: 1, max: 60, step: 1, type: 'number'},
                { label: 'Supply Air Flow Rate (min)', key: 'intvlSafr', min: 1, max: 60, step: 1, type: 'number'},
                { label: 'Chilled Water Flow Rate (min)', key: 'intvlChwfr', min: 1, max: 60, step: 1, type: 'number'},
                { label: 'Fresh Air Damper (min)', key: 'intvlFdamp', min: 1, max: 60, step: 1, type: 'number'},
                { label: 'Return Air Damper (min)', key: 'intvlRdamp', min: 1, max: 60, step: 1, type: 'number'},
            ]
        },
        {
            title: "Maximum Change Difference",
            params: [
                { label: 'Chilled Water Supply Temp (°C)', key: 'chStDiff', min: 0.1, max: 1, step: 0.1, type: 'number'},
            ]
        },
    ];

    let ahu_sections = [
        {
            title: "Supply Air",
            params: [
                { label: 'Lower Limit (°C)', key: 'satLower', min: 5, max: 25, step: 0.1, type: 'number'},
                { label: 'Upper Limit (°C)', key: 'satUpper', min: 5, max: 25, step: 0.1, type: 'number'},
                { label: 'Flow Rate Lower Limit (L/s)', key: 'safrLower', min: 0, max: 9999, step: 100, type: 'number'},
                { label: 'Flow Rate Upper Limit (L/s)', key: 'safrUpper', min: 0, max: 9999, step: 100, type: 'number'},
            ]
        },
        {
            title: "Return Air",
            params: [
                { label: 'Lower Limit (°C)', key: 'ratLower', min: 10, max: 25, step: 0.1, type: 'number'},
                { label: 'Upper Limit (°C)', key: 'ratUpper', min: 10, max: 25, step: 0.1, type: 'number'},
                { label: 'Humidity Lower Limit (%)', key: 'rarhLower', min: 10, max: 100, step: 1, type: 'number'},
                { label: 'Humidity Upper Limit (%)', key: 'rarhUpper', min: 10, max: 100, step: 1, type: 'number'},
                { label: 'CO2 (ppm)', key: 'co2Upper', min: 500, max: 2000, step: 100, type: 'number'},
            ]
        },
        {
            title: "Fan Speed",
            params: [
                { label: 'Lower Limit (Hz)', key: 'hzLower', min: 30, max: 50, step: 1, type: 'number'},
                { label: 'Upper Limit (Hz)', key: 'hzUpper', min: 30, max: 50, step: 1, type: 'number'},
            ]
        },
        {
            title: "Chilled Water / Valve",
            params: [
                { label: 'Flow Rate Lower Limit (L/s)', key: 'chwfrLower', min: 0, max: 50, step: 0.05, type: 'number'},
                { label: 'Flow Rate Upper Limit (L/s)', key: 'chwfrUpper', min: 0, max: 50, step: 0.05, type: 'number'},
                { label: 'Outlet Temp Lower Limit (°C)', key: 'chwotLower', min: 5, max: 20, step: 0.1, type: 'number'},
                { label: 'Outlet Temp Upper Limit (°C)', key: 'chwotUpper', min: 5, max: 20, step: 0.1, type: 'number'},
            ]
        },
        {
            title: "Damper / Valve",
            params: [
                { label: 'FA Damper Lower Limit (%)', key: 'fadsLower', min: 0, max: 100, step: 1, type: 'number'},
                { label: 'FA Damper Upper Limit (%)', key: 'fadsUpper', min: 0, max: 100, step: 1, type: 'number'},
                { label: 'RA Damper Lower Limit (%)', key: 'radsLower', min: 0, max: 100, step: 1, type: 'number'},
                { label: 'RA Damper Upper Limit (%)', key: 'radsUpper', min: 0, max: 100, step: 1, type: 'number'},
                { label: 'Valve Lower Limit (%)', key: 'vlvLower', min: 0, max: 100, step: 1, type: 'number'},
                { label: 'Valve Upper Limit (%)', key: 'vlvUpper', min: 0, max: 100, step: 1, type: 'number'},
            ]
        },
        {
            title: "Maximum Change Difference (for PSO)",
            params: [
                { label: 'Fan Speed (Hz)', key: 'diffHzPso', min: 0, max: 20, step: 1, type: 'number'},
                { label: 'Chilled Water Flow Rate (L/s)', key: 'diffChwfrPso', min: 0, max: 20, step: 0.05, type: 'number'},
                { label: 'FA Damper Position (%)', key: 'diffFdampPso', min: 0, max: 100, step: 1, type: 'number'},
                { label: 'RA Damper Position (%)', key: 'diffRdampPso', min: 0, max: 100, step: 1, type: 'number'},
            ]
        },
        {
            title: "Maximum Change Difference (for abnormal checking)",
            params: [
                { label: 'Fan Speed (Hz)', key: 'diffHz', min: 0, max: 20, step: 1, type: 'number'},
                { label: 'Chilled Water Flow Rate (L/s)', key: 'diffChwfr', min: 0, max: 20, step: 0.05, type: 'number'},
                { label: 'FA Damper Position (%)', key: 'diffFdamp', min: 0, max: 100, step: 1, type: 'number'},
                { label: 'RA Damper Position (%)', key: 'diffRdamp', min: 0, max: 100, step: 1, type: 'number'},
                { label: 'Valve Position (%)', key: 'diffVlv', min: 0, max: 100, step: 1, type: 'number'},
            ]
        },
    ]

    useEffect(()=>{
        if (building){
            callSettings();
        }
    }, [building])

    const callSettings = async() => {
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/settingsAhu`,
            data: {
                "b_id": building.building_id,
            }
        })
        .then((response) => {
            let res = response.data;
            if  (res.data_range){
                res.safrLower = Object.keys(res.data_range).map(el=> res.data_range[el].safr_lo_limit);
                res.safrUpper = Object.keys(res.data_range).map(el=> res.data_range[el].safr_up_limit);
                res.chwfrLower = Object.keys(res.data_range).map(el=> res.data_range[el].chwfr_lo_limit);
                res.chwfrUpper = Object.keys(res.data_range).map(el=> res.data_range[el].chwfr_up_limit);
                res.chwotLower = Object.keys(res.data_range).map(el=> res.data_range[el].chwot_lo_limit);
                res.chwotUpper = Object.keys(res.data_range).map(el=> res.data_range[el].chwot_up_limit);
                res.co2Upper = Object.keys(res.data_range).map(el=> res.data_range[el].co2_up_limit);
                res.fadsLower = Object.keys(res.data_range).map(el=> res.data_range[el].fadamp_lo_limit);
                res.fadsUpper = Object.keys(res.data_range).map(el=> res.data_range[el].fadamp_up_limit);
                res.hzLower = Object.keys(res.data_range).map(el=> res.data_range[el].hz_lo_limit);
                res.hzUpper = Object.keys(res.data_range).map(el=> res.data_range[el].hz_up_limit);
                res.radsLower = Object.keys(res.data_range).map(el=> res.data_range[el].radamp_lo_limit);
                res.radsUpper = Object.keys(res.data_range).map(el=> res.data_range[el].radamp_up_limit);
                res.rarhLower = Object.keys(res.data_range).map(el=> res.data_range[el].rarh_lo_limit);
                res.rarhUpper = Object.keys(res.data_range).map(el=> res.data_range[el].rarh_up_limit);
                res.ratLower = Object.keys(res.data_range).map(el=> res.data_range[el].rat_lo_limit);
                res.ratUpper = Object.keys(res.data_range).map(el=> res.data_range[el].rat_up_limit);
                res.satLower = Object.keys(res.data_range).map(el=> res.data_range[el].sat_lo_limit);
                res.satUpper = Object.keys(res.data_range).map(el=> res.data_range[el].sat_up_limit);
                res.vlvLower = Object.keys(res.data_range).map(el=> res.data_range[el].vlv_lo_limit);
                res.vlvUpper = Object.keys(res.data_range).map(el=> res.data_range[el].vlv_up_limit);

                res.diffChwfr = Object.keys(res.data_range).map(el=> res.data_range[el].chwfr_diff);
                res.diffHz = Object.keys(res.data_range).map(el=> res.data_range[el].hz_diff);
                res.diffFdamp = Object.keys(res.data_range).map(el=> res.data_range[el].fdamp_diff);
                res.diffRdamp = Object.keys(res.data_range).map(el=> res.data_range[el].rdamp_diff);
                res.diffVlv = Object.keys(res.data_range).map(el=> res.data_range[el].vlv_diff);

                res.diffChwfrPso = Object.keys(res.data_range).map(el=> res.data_range[el].pso_chwfr_diff);
                res.diffHzPso = Object.keys(res.data_range).map(el=> res.data_range[el].pso_hz_diff);
                res.diffFdampPso = Object.keys(res.data_range).map(el=> res.data_range[el].pso_fdamp_diff);
                res.diffRdampPso = Object.keys(res.data_range).map(el=> res.data_range[el].pso_rdamp_diff);
            }

            setOldSettings(res);
            setNewSettings(res);
            
            setInputChStDiff(res.chStDiff);

            setInputSafrLower(res.safrLower);
            setInputSafrUpper(res.safrUpper);
            setInputChwfrLower(res.chwfrLower);
            setInputChwfrUpper(res.chwfrUpper);
            setInputChwotLower(res.chwotLower);
            setInputChwotUpper(res.chwotUpper);
            setInputHzLower(res.hzLower);
            setInputHzUpper(res.hzUpper);
            setInputSatLower(res.satLower);
            setInputSatUpper(res.satUpper);
            setInputRatLower(res.ratLower);
            setInputRatUpper(res.ratUpper);
            setInputRarhLower(res.rarhLower);
            setInputRarhUpper(res.rarhUpper);
            setInputFadsLower(res.fadsLower);
            setInputFadsUpper(res.fadsUpper);
            setInputRadsLower(res.radsLower);
            setInputRadsUpper(res.radsUpper);
            setInputVlvLower(res.vlvLower);
            setInputVlvUpper(res.vlvUpper);
            setInputCo2Upper(res.co2Upper);

            setInputHzDiff(res.diffHz);
            setInputChwfrDiff(res.diffChwfr);
            setInputFdampDiff(res.diffFdamp);
            setInputRdampDiff(res.diffRdamp);
            setInputVlvDiff(res.diffVlv);

            setInputPsoHzDiff(res.diffHzPso);
            setInputPsoChwfrDiff(res.diffChwfrPso);
            setInputPsoFdampDiff(res.diffFdampPso);
            setInputPsoRdampDiff(res.diffRdampPso);

            setInputIntvlSat(res.intvlSat);
            setInputIntvlSafr(res.intvlSafr);
            setInputIntvlChwfr(res.intvlChwfr);
            setInputIntvlFdamp(res.intvlFdamp);
            setInputIntvlRdamp(res.intvlRdamp);

            setIsUpdated(false);
        })
        .catch((err)=>{
     
        })
    }

    const checkHasUpdated = (new_setting) => {
        // console.log(JSON.stringify(new_setting)===JSON.stringify(oldSettings) )
        if (JSON.stringify(new_setting)===JSON.stringify(oldSettings)){
            setIsUpdated(false);
        }
        else{
            setIsUpdated(true);
        }
    }

    const onChangeSetting = (param_key, new_val, set_fn, type, eq_idx=null) => {
        let x = structuredClone(newSettings);

        if (type === "number"){
            if (eq_idx===null)
                x[param_key] = parseFloat(new_val);
            else
                x[param_key].splice(eq_idx, 1, parseFloat(new_val));
        }
        else
            x[param_key] = new_val==="true"? true: false;

        setNewSettings(x);
        checkHasUpdated(x);

        set_fn(x[param_key]);
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true){
            event.preventDefault();
            event.stopPropagation();
            setIsSaveLoading(true);

            let data_range = newSettings.data_range;
            Object.keys(data_range)
                .map((el,idx)=>{
                    data_range[el]['safr_lo_limit'] = parseFloat(inputSafrLower[idx]);
                    data_range[el]['safr_up_limit'] = parseFloat(inputSafrUpper[idx]);
                    data_range[el]['chwfr_lo_limit'] = parseFloat(inputChwfrLower[idx]);
                    data_range[el]['chwfr_up_limit'] = parseFloat(inputChwfrUpper[idx]);
                    data_range[el]['chwot_lo_limit'] = parseFloat(inputChwotLower[idx]);
                    data_range[el]['chwot_up_limit'] = parseFloat(inputChwotUpper[idx]);
                    data_range[el]['co2_up_limit'] = parseFloat(inputCo2Upper[idx]);
                    data_range[el]['fadamp_lo_limit'] = parseFloat(inputFadsLower[idx]);
                    data_range[el]['fadamp_up_limit'] = parseFloat(inputFadsUpper[idx]);
                    data_range[el]['hz_lo_limit'] = parseFloat(inputHzLower[idx]);
                    data_range[el]['hz_up_limit'] = parseFloat(inputHzUpper[idx]);
                    data_range[el]['radamp_lo_limit'] = parseFloat(inputRadsLower[idx]);
                    data_range[el]['radamp_up_limit'] = parseFloat(inputRadsUpper[idx]);
                    data_range[el]['rarh_lo_limit'] = parseFloat(inputRarhLower[idx]);
                    data_range[el]['rarh_up_limit'] = parseFloat(inputRarhUpper[idx]);
                    data_range[el]['rat_lo_limit'] = parseFloat(inputRatLower[idx]);
                    data_range[el]['rat_up_limit'] = parseFloat(inputRatUpper[idx]);
                    data_range[el]['sat_lo_limit'] = parseFloat(inputSatLower[idx]);
                    data_range[el]['sat_up_limit'] = parseFloat(inputSatUpper[idx]);
                    data_range[el]['vlv_lo_limit'] = parseFloat(inputVlvLower[idx]);
                    data_range[el]['vlv_up_limit'] = parseFloat(inputVlvUpper[idx]);

                    data_range[el]['chwfr_diff'] = parseFloat(inputChwfrDiff[idx]);
                    data_range[el]['hz_diff'] = parseInt(inputHzDiff[idx]);
                    data_range[el]['fdamp_diff'] = parseInt(inputFdampDiff[idx]);
                    data_range[el]['rdamp_diff'] = parseInt(inputRdampDiff[idx]);
                    data_range[el]['vlv_diff'] = parseInt(inputVlvDiff[idx]);

                    data_range[el]['pso_chwfr_diff'] = parseFloat(inputPsoChwfrDiff[idx]);
                    data_range[el]['pso_hz_diff'] = parseInt(inputPsoHzDiff[idx]);
                    data_range[el]['pso_fdamp_diff'] = parseInt(inputPsoFdampDiff[idx]);
                    data_range[el]['pso_rdamp_diff'] = parseInt(inputPsoRdampDiff[idx]);
                });
            
            await axiosWithXAuth({
                method: "post",
                url: `${API_URL}/api/updateSettingsAhu`,
                data: {
                    "b_id": building.building_id,
                    "cfg_content": {
                        "chStDiff": parseFloat(inputChStDiff),

                        "intvlSat": parseInt(inputIntvlSat),
                        "intvlSafr": parseInt(inputIntvlSafr),
                        "intvlChwfr": parseInt(inputIntvlChwfr),
                        "intvlFdamp": parseInt(inputIntvlFdamp),
                        "intvlRdamp": parseInt(inputIntvlRdamp),

                        "data_range": data_range
                    }
                }
            })
            .then((response) => {
                let res = response.data;

                form.classList.remove("was-validated");
                form.reset();
                callSettings();

                setTimeout(()=>{
                    setIsSaveLoading(false);
                }, 2000);
            })
            .catch((err) => {
                form.classList.remove("was-validated");
                form.reset();
                callSettings();
                setIsSaveLoading(false);
            })
        }
      };

    const renderInput = (param, eq_idx=null) => {
        let param_key = param.key;
        let input_label = param.label;
        let min = param.min;
        let max = param.max;
        let step = param.step;
        let type = param.type || "number";

        let param_val = null;
        let set_fn = null;

        switch (param_key) {
            
            case "chStDiff": param_val = inputChStDiff; set_fn = (x) => setInputChStDiff(x); break;

            case "safrLower": param_val = inputSafrLower[eq_idx]; set_fn = (x) => setInputSafrLower(x); break;
            case "safrUpper": param_val = inputSafrUpper[eq_idx]; set_fn = (x) => setInputSafrUpper(x); break;
            case "chwfrLower": param_val = inputChwfrLower[eq_idx]; set_fn = (x) => setInputChwfrLower(x); break;
            case "chwfrUpper": param_val = inputChwfrUpper[eq_idx]; set_fn = (x) => setInputChwfrUpper(x); break;
            case "chwotLower": param_val = inputChwotLower[eq_idx]; set_fn = (x) => setInputChwotLower(x); break;
            case "chwotUpper": param_val = inputChwotUpper[eq_idx]; set_fn = (x) => setInputChwotUpper(x); break;
            case "hzLower": param_val = inputHzLower[eq_idx]; set_fn = (x) => setInputHzLower(x); break;
            case "hzUpper": param_val = inputHzUpper[eq_idx]; set_fn = (x) => setInputHzUpper(x); break;
            case "satLower": param_val = inputSatLower[eq_idx]; set_fn = (x) => setInputSatLower(x); break;
            case "satUpper": param_val = inputSatUpper[eq_idx]; set_fn = (x) => setInputSatUpper(x); break;
            case "ratLower": param_val = inputRatLower[eq_idx]; set_fn = (x) => setInputRatLower(x); break;
            case "ratUpper": param_val = inputRatUpper[eq_idx]; set_fn = (x) => setInputRatUpper(x); break;
            case "rarhLower": param_val = inputRarhLower[eq_idx]; set_fn = (x) => setInputRarhLower(x); break;
            case "rarhUpper": param_val = inputRarhUpper[eq_idx]; set_fn = (x) => setInputRarhUpper(x); break;
            case "fadsLower": param_val = inputFadsLower[eq_idx]; set_fn = (x) => setInputFadsLower(x); break;
            case "fadsUpper": param_val = inputFadsUpper[eq_idx]; set_fn = (x) => setInputFadsUpper(x); break;
            case "radsLower": param_val = inputRadsLower[eq_idx]; set_fn = (x) => setInputRadsLower(x); break;
            case "radsUpper": param_val = inputRadsUpper[eq_idx]; set_fn = (x) => setInputRadsUpper(x); break;
            case "vlvLower": param_val = inputVlvLower[eq_idx]; set_fn = (x) => setInputVlvLower(x); break;
            case "vlvUpper": param_val = inputVlvUpper[eq_idx]; set_fn = (x) => setInputVlvUpper(x); break;
            case "co2Upper": param_val = inputCo2Upper[eq_idx]; set_fn = (x) => setInputCo2Upper(x); break;

            case "diffHz": param_val = inputHzDiff[eq_idx]; set_fn = (x) => setInputHzDiff(x); break;
            case "diffChwfr": param_val = inputChwfrDiff[eq_idx]; set_fn = (x) => setInputChwfrDiff(x); break;
            case "diffFdamp": param_val = inputFdampDiff[eq_idx]; set_fn = (x) => setInputFdampDiff(x); break;
            case "diffRdamp": param_val = inputRdampDiff[eq_idx]; set_fn = (x) => setInputRdampDiff(x); break;
            case "diffVlv": param_val = inputVlvDiff[eq_idx]; set_fn = (x) => setInputVlvDiff(x); break;

            case "diffHzPso": param_val = inputPsoHzDiff[eq_idx]; set_fn = (x) => setInputPsoHzDiff(x); break;
            case "diffChwfrPso": param_val = inputPsoChwfrDiff[eq_idx]; set_fn = (x) => setInputPsoChwfrDiff(x); break;
            case "diffFdampPso": param_val = inputPsoFdampDiff[eq_idx]; set_fn = (x) => setInputPsoFdampDiff(x); break;
            case "diffRdampPso": param_val = inputPsoRdampDiff[eq_idx]; set_fn = (x) => setInputPsoRdampDiff(x); break;

            case "intvlSat": param_val = inputIntvlSat; set_fn = (x) => setInputIntvlSat(x); break;
            case "intvlSafr": param_val = inputIntvlSafr; set_fn = (x) => setInputIntvlSafr(x); break;
            case "intvlChwfr": param_val = inputIntvlChwfr; set_fn = (x) => setInputIntvlChwfr(x); break;
            case "intvlFdamp": param_val = inputIntvlFdamp; set_fn = (x) => setInputIntvlFdamp(x); break;
            case "intvlRdamp": param_val = inputIntvlRdamp; set_fn = (x) => setInputIntvlRdamp(x); break;
            
            default: return;
        }

        if (type==="number" && eq_idx===null) 

            return(
                <Col xs={12} md={6} xl={4} key={`input_${param_key}`}>
                    <Form.Group controlId={`${param_key}_validationCustom`}>
                        <FloatingLabel controlId={`${param_key}_floatingInput`} label={input_label} className="mb-3" >
                            <Form.Control 
                                disabled={isSaveLoading}
                                required 
                                type="number" 
                                min={min}
                                max={max}
                                step={step}
                                onChange={(e)=>onChangeSetting(param_key, e.target.value, set_fn, "number")}
                                value={param_val || 0} />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            )
        else if (type==="number" && eq_idx!==null) {
            let param_key_by_eq = param_key + eq_idx;
            return(
                <Col xs={12} md={6} xl={4} key={`input_${param_key_by_eq}`}>
                    <Form.Group controlId={`${param_key_by_eq}_validationCustom`}>
                        <FloatingLabel controlId={`${param_key_by_eq}_floatingInput`} label={input_label} className="mb-3" >
                            <Form.Control 
                                disabled={isSaveLoading}
                                required 
                                type="number" 
                                min={min}
                                max={max}
                                step={step}
                                onChange={(e)=>onChangeSetting(param_key, e.target.value, set_fn, "number", eq_idx)}
                                value={param_val || 0} />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            )
        }
        else{
            return (
                <Col xs={12} md={6} xl={4} key={`input_${param_key}`}>
                    <Form.Group controlId={`${param_key}_validationCustom`}>
                        <FloatingLabel controlId={`${param_key}_floatingInput`} label={input_label} className="mb-3" >
                            <Form.Select onChange={(e)=>onChangeSetting(param_key, e.target.value, set_fn, "boolean")} value={param_val || false} disabled={isSaveLoading}>
                                <option value={false}>No</option>
                                <option value={true}>Yes</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            )
        }
    }

    const renderSection = (section) => {
        return (
            <div className='color_container mx-2 mb-4' key={`section_${section.title}`}>
                <div className="color_grid">
                    <div className='title'> {section.title}</div>
                </div>
                <Container className='px-2 py-2'>
                    <Row> 
                    {
                        section.params.map(param =>
                            renderInput(param,null)
                        )
                    }
                    </Row>
                </Container>
            </div>
        )
    }

    const renderAhuSection = (ahu_name, eq_idx=null) => {
        return (
            <div className='color_container mx-2 mb-4' key={`section_${ahu_name}`}>
                <div className="color_grid equip_name">
                    <div className='title'> {ahu_name}</div>
                </div>
                <Container className='px-2 py-2'>
                    <Row>
                    {
                        ahu_sections.map(ahu_section => {
                            let sub_section = {
                                title: ahu_section.title,
                                params: ahu_section.params
                            };
                            return renderSubSection(ahu_name, sub_section,eq_idx)
                        })
                    }    
                    </Row>
                </Container>
            </div>
        )
    }

    const renderSubSection = (ahu_name, sub_section, eq_idx) => {
        return (
            <Container key={`sub_section_${ahu_name}_${sub_section.title}`}>
                <div className='color_container mx-4 my-2 px-0' >
                    <div className="color_grid">
                        <div className='title'> {sub_section.title}</div>
                    </div>
                    <Container className='px-2 py-2'>
                        <Row>
                        {
                            sub_section.params.map(param =>
                                renderInput(param,eq_idx)
                            )
                        }
                        </Row>
                    </Container>
                </div>
            </Container>
        )
    }

    const renderAhus = (ahus) => {
        return ahus.map((ahu_name,idx) => {
            return renderAhuSection(ahu_name, idx)
        })
    }

    return (
        <>
            <div className='config_header'>AHU Configuration</div>
            <div className='pb-3'></div>
            <Container>
                <Form validated={validated} onSubmit={handleSubmit}>
                    {
                        sections.map(section =>
                            renderSection(section)
                        )
                    }
                    {
                        building && renderAhus(building.ahus)
                    }
                    <Row>
                        <Col>
                        {hasUpdated && !isSaveLoading && <Button variant="primary" type="submit">Save</Button>}
                        {!hasUpdated && !isSaveLoading && <Button variant="secondary" disabled>Save</Button>}
                        {isSaveLoading && 
                            <Button variant="secondary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span className="visually-hidden">Loading...</span>
                            </Button>
                        }
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}