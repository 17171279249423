import { useNavigate } from 'react-router-dom';

function Home(){
    const navigate = useNavigate();

    if (window.localStorage.getItem("xauth")!==null){
        navigate('/dashboard/main');
    }
    else if (window.location.href.indexOf("admin") > -1){
        navigate('/adminlogin');
    }
    else{
        navigate('/login');
    }

    return (
        <p>Home</p>
    )
}

export default Home;