import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { 
    RiAlarmWarningLine as ALARM_ICON} from 'react-icons/ri';

import { API_URL } from  "../../../utils/variables";
import { axiosWithXAuth } from "../../../utils/functions";
import AlarmRecord from '../modal/AlarmRecord';

function AlertPrompt({platform, recordnum}) {
    // var { building, rtData } = useSelector(
    //     ({ core, data, content }) => ({
    //         building: data.building,
    //         rtData: data.realtimeData
    //     })
    // )

    const building = useSelector(root => root.data.building);
    const rtData = useSelector(root => root.data.realtimeData);
    
    const [show, setShow] = useState(null);
    const [showAlarmModal, setShowAlarmModal] = useState(false);
    const [connect, setConnect] = useState(null);

    useEffect(() => {
        callAlarmRecord();
        callConnectStatus();
    }, [])

    useEffect(() => {
        let b_code = building.building_ibms_id.toLowerCase();
        let th_rtData = rtData[b_code];
        if (th_rtData!==undefined){
            updateAlarm(th_rtData);
            updateConnectStatus(th_rtData);
        }
    }, [rtData]);

    const callAlarmRecord = () => {
        
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/alarms`,
            data: {b_id: building.building_id}
        })
            .then((response) => {
                let data = response.data;
                let result = []
                data.forEach((element, index) => {
                    result.push({
                        "type": element.type,
                        "status": element.status,
                        "on_dt": element.on_dt,
                        "off_dt": element.off_dt
                    })
                })
                let online_records = result.filter(el => el.status===1 && el.type===1);
                if (online_records.length > 0){
                    
                    setShow(true);
                    // let last_record_dt = new Date(online_records[0]["on_dt"]);
                    // let now = new Date();
                    // if (last_record_dt.getDate() === now.getDate()){
                    //     setShow(true);
                    // }
                }
                else{
                    setShow(false);
                }
            })
    }

    const callConnectStatus = () => {
        
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/connectStatus`,
            data: {
                b_id: building.building_id
            }
        })
            .then((response) => {
                let data = response.data;
                setConnect(data);
            })
    }

    const updateAlarm = (th_rtData) => {
        if (th_rtData.alarms===undefined || th_rtData.alarms.length<=0) return false;

        let online_records = th_rtData.alarms.filter(el => el.status===1 && el.type===1);
        if (online_records.length > 0){
            setShow(true);
        }
        else{
            setShow(false);
        }
    }

    const updateConnectStatus = (th_rtData) => {
        if (th_rtData.ai!==undefined && th_rtData.ai!==null) {
            setConnect(th_rtData.ai.connect_status);
        }
    }

    return (
        <>
        <div className='py-0 px-0 mx-0 float-left d-none d-sm-none d-md-block'>
            <Alert show={show} variant="warning" onClose={() => setShow(false)} dismissible>
                <Row>
                    <Col xs={12} sm={12} md={2} lg={2} xl={1}>
                        <h4 className='my-0'>Alert!</h4>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={10} xl={9} className='text-left py-1'>
                        <p className='my-0'>Last optimized control suggestion was over 20 minutes ago. Please consider to change over to CCMS mode and tackle the problem.</p>
                    </Col>
                </Row>
                
            </Alert>
        </div>
        <Container className='card_bg kpi_card mt-2 pb-3 d-sm-block d-md-none'>
            <Row>
                <h5 className="kpi_bage mobile_kpi_bage py-3 my-0"  style={{"backgroundColor": "black", "position": "relative"}}>
                    <span className="ms-4" style={{"position":"absolute", "left": "0"}}>
                        <ALARM_ICON size={23}/>
                    </span>
                    <b>AI Connection Status</b>
                </h5>
            </Row>
            <Row className='mt-3'>
                {
                    connect===null && <h5 className='mb-0' ><b>---</b></h5>
                }
                {
                    connect===false && <h5 className='mb-0' style={{"color": "red"}}><b>Disconnected</b></h5>
                }
                {
                    connect===true && <h5 className='mb-0' style={{"color": "#198754"}}><b>Connected</b></h5>
                }
            </Row>
            <hr/>
            <Row className='d-sm-block d-md-none'>
                <Col>
                    <Button variant="secondary" onClick={()=>setShowAlarmModal(true)}>Alarm History</Button>{' '}
                </Col>
            </Row>
        </Container>
        <AlarmRecord
                showModal={showAlarmModal}
                setShowModal={setShowAlarmModal}
                platform={platform}
                recordnum={recordnum}
        />
        </>
    )
}

export default AlertPrompt;