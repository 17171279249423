import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Modal, Button, Table } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";

import { FaTemperatureHalf as TempIcon } from "react-icons/fa6";
import { BsSpeedometer as SpeedIcon, BsThermometerSnow as FreeCoolIcon } from "react-icons/bs";
import { GiBrain as AIIcon } from "react-icons/gi";
import { PiFanFill as FanIcon } from "react-icons/pi";

import { API_URL} from "../../../utils/variables";
import PaginationComponent from '../../components/pagination/Pagination';
import { axiosWithXAuth } from "../../../utils/functions";

const  AHUSuggestHistory = ({
    showModal = false,
    setShowModal,
    platform = "web",
    recordnum = 10,
    ahuSelectedIdx = 0
}) => {

    const handleClose = () => setShowModal(false);

    const building = useSelector(root => root.data.building);

    const [suggestions, setSuggestions] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = recordnum;

    useEffect(() => {
        setPage(1);
        if (showModal){
            callOptSuggestions();
        }
    }, [showModal])

    useEffect(() => {
        if (page<1)
            setPage(1);
    }, [page])

    const callOptSuggestions = async () => {
        
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/optAhuSuggestions`,
            data: {
                "b_id": building.building_id,
                "limit": 400
            }
        })
        .then((response) => {
            let data = response.data;
            let result = []
            data.forEach((element, index) => {
                result.push({
                    "cdt": element.cdt,
                    "name": element.name,
                    "opt_ch_st": element.opt_ch_st,
                    "opt_sat": element.opt_sat,
                    "opt_fdamp": element.opt_fdamp,
                    "opt_rdamp": element.opt_rdamp,
                    "opt_vlv": element.opt_vlv,
                    "opt_spd": element.opt_spd,
                    "opt_pred_chwot": element.opt_pred_chwot,
                    "opt_co2_rate": element.opt_co2_rate,
                    "opt_sen_space": element.opt_sen_space,
                    "opt_tt_cl": element.opt_tt_cl,
                    "opt_tt_kw": element.opt_tt_kw,
                    "mode": element.mode,
                    "actual_power": element.actual_power,
                    "actual_co2_rate": element.actual_co2_rate,
                    "actual_sen_space": element.actual_sen_space,
                    "actual_tt_cl": element.actual_tt_cl,
                    "is_free_cool": element.is_free_cool
                })
            })
            result = result.filter(e=>e.name===building.ahus[ahuSelectedIdx]);
            setSuggestions(result);
        })
    }

    const renderSuggestion = (suggest, index) => {
        let cdt = new Date(suggest.cdt);
        let opt_dt = moment(cdt).format("DD/MM h:mm a");
        let name = suggest.name.slice(0, 2);

        let sat = suggest.opt_sat !== null ? suggest.opt_sat.toFixed(1): null;
        let fdamp = suggest.opt_fdamp !== null? suggest.opt_fdamp.toFixed(0): null;
        let rdamp = suggest.opt_rdamp !== null? suggest.opt_rdamp.toFixed(0): null;
        let vlv = suggest.opt_vlv !== null? suggest.opt_vlv.toFixed(0): null;
        let spd = suggest.opt_spd !== null? suggest.opt_spd.toFixed(1): null;
        let pre_co2_rate = suggest.opt_co2_rate !== null? suggest.opt_co2_rate.toFixed(4): null;
        let pre_sen_space = suggest.opt_sen_space !== null? suggest.opt_sen_space.toFixed(0): null;
        let pre_tt_cl = suggest.opt_tt_cl !== null? suggest.opt_tt_cl.toFixed(0): null;
        let pre_power = suggest.opt_tt_kw !== null? suggest.opt_tt_kw.toFixed(0): null;
        let actual_power = suggest.actual_power !== null? suggest.actual_power.toFixed(0): null;
        let actual_co2_rate = suggest.actual_co2_rate !== null? suggest.actual_co2_rate.toFixed(4): null;
        let actual_sen_space = suggest.actual_sen_space !== null? suggest.actual_sen_space.toFixed(0): null;
        let actual_tt_cl = suggest.actual_tt_cl !== null? suggest.actual_tt_cl.toFixed(0): null;

        let item_num = (page-1) * itemsPerPage + index + 1;
        return (
            <tr key={`ahu_sug_${index}`} style={{textAlign: "center"}}>
                <td>{item_num}
                    {
                        suggest.mode==="AI" && 
                        <>&ensp;
                        <AIIcon size={16} color="#198754" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                    {
                        suggest.is_free_cool===1 && 
                        <>&ensp;
                        <FreeCoolIcon size={16} color="#0D6EFD" style={{"marginTop": "-3px"}}/>
                        </>
                    }
                </td>
                <td>{opt_dt}</td>
                <td>{name}</td>
                <td>{sat && <><TempIcon color='#0D6EFD' style={{marginTop: "-3px"}} /> {sat}</>}</td>
                <td>{rdamp}</td>
                <td>{fdamp}</td>
                <td>{vlv}</td>
                <td>{spd && <><FanIcon color='#034f84' style={{marginTop: "-3px"}}/> {spd}</>}</td>
                <td style={{"borderLeft": "1px solid #cdd0d4"}}>{pre_co2_rate} </td>
                <td>{pre_power}</td>
                <td>{pre_tt_cl}</td>
                <td>{pre_sen_space}</td>
                <td style={{"borderLeft": "1px solid #cdd0d4"}}>{actual_co2_rate} </td>
                <td>{actual_power}</td>
                <td>{actual_tt_cl}</td>
                <td>{actual_sen_space}</td>
            </tr>
        )
    }

    const renderSuggestionWrap = () => {
        if (suggestions.length > 0){

            let from_item = page===1? 1: (page-1) * itemsPerPage + 1;
            let to_item = from_item + itemsPerPage - 1;
            let suggestions_in_page = suggestions.filter(
                (element, index) => { 
                    return index+1 >= from_item && index+1 <= to_item
                });

            return suggestions_in_page.map((element, index) => {
                return renderSuggestion(element, index);
            })
        }
    }

    const renderContent = () =>{
        let smallfont = platform==="web"?"":"small_font";
        return (
            <Container className='py-1'>
                <Table striped border="true" hover responsive className={`mb-0 ${smallfont}`}>
                <thead>
                        <tr>
                            <th rowSpan={2}>#</th>
                            <th rowSpan={2}>Datetime</th>
                            <th rowSpan={2}>Floor</th>
                            <th rowSpan={2}>SAT(°C)</th>
                            <th rowSpan={2}>RA. Damper (%)</th>
                            <th rowSpan={2}>FA. Damper (%)</th>
                            <th rowSpan={2}>CHW Valve (%)</th>
                            <th rowSpan={2}>Fan Speed (Hz)</th>
                            <th colSpan={4} style={{"textAlign": "center", "borderLeft": "1px solid #cdd0d4"}}>Prediction</th>
                            <th colSpan={4} style={{"textAlign": "center", "borderLeft": "1px solid #cdd0d4"}}>Actual</th>
                        </tr>
                        <tr>
                            <th style={{"borderLeft": "1px solid #cdd0d4"}}>CO<sub>2</sub> Rate (L/s)</th>
                            <th>Total Power (kW)</th>
                            <th>Total Coil Cooling Demand (kW)</th>
                            <th>Sensible Space Cooling Demand (kW)</th>
                            <th style={{"borderLeft": "1px solid #cdd0d4"}}>CO<sub>2</sub> Rate (L/s)</th>
                            <th>Total Power (kW)</th>
                            <th>Total Coil Cooling Demand (kW)</th>
                            <th>Sensible Space Cooling Demand (kW)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderSuggestionWrap()}
                    </tbody>
                </Table>
                <div className='text-left mb-2' style={{fontSize: "small"}}>
                    <AIIcon size={14} color="#198754" style={{"marginTop": "-3px"}}/> AI mode&ensp;
                    <FreeCoolIcon size={14} color="#0D6EFD" style={{"marginTop": "-3px"}}/> Free Cooling
                </div>
                <div className="myPagination myPag-b d-flex justify-content-center">
                    {
                        <PaginationComponent
                            itemsCount={suggestions.length}
                            itemsPerPage={itemsPerPage}
                            currentPage={page}
                            setCurrentPage={setPage}
                            alwaysShown={false}
                        />
                    }
                </div>
            </Container>
        )
    }

    return (
        <Modal size="xl" fullscreen="lg-down" show={showModal} onHide={handleClose} id="opt_ctrl_history">
            <Modal.Header closeButton>
            <Modal.Title>AHU Control History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderContent()}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

AHUSuggestHistory.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    ahuSelectedIdx: PropTypes.number.isRequired
};

export default AHUSuggestHistory;