import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col} from "react-bootstrap";
import { HiHome as HomeIcon } from "react-icons/hi";

import { PAGES } from "../../../constants";
import "./css.scss";

function MainLeftNavbar() {    
    const navigate = useNavigate();

    const building = useSelector(root => root.data.building);
    const currentPage = useSelector(root => root.core.page);

    const [mainPageOn, setMainPagrOn] = useState("");
    const [chillerPageOn, setChillerPageOn] = useState("");
    const [ahuPageOn, setAhuPageOn] = useState("");

    useEffect(() => {
        setMainPagrOn(currentPage === PAGES.MAIN? "page_on": "" );
        setChillerPageOn(currentPage === PAGES.MAIN_CH? "page_on": "" );
        setAhuPageOn(currentPage === PAGES.MAIN_AHU? "page_on": "" );
    }, [currentPage])

    const clickPage = (p) => {
        if (p===PAGES.MAIN)
            navigate('/dashboard/main');

        if (p===PAGES.MAIN_CH)
            navigate('/dashboard/chiller');

        if (p===PAGES.MAIN_AHU)
            navigate('/dashboard/ahu/'+building.ahu_display_names[0]); 
    }

    if (building)
        return (
            <div className='px-0'>
                <div className='left-menu-item py-4 ps-2' onClick={() => clickPage(PAGES.MAIN)}>
                    <HomeIcon size={28} className={`main-image ${mainPageOn}`}/>
                </div>
                <div className='left-menu-item py-4 ps-2' onClick={() => clickPage(PAGES.MAIN_CH)}>
                    <div className={`chiller-image icon-image ${chillerPageOn}`}>

                    </div>
                </div>
                {   building.show_ahu_page===1 && 
                    <div className='left-menu-item py-4 ps-2' onClick={() => clickPage(PAGES.MAIN_AHU)}>
                        <div className={`ahu-image icon-image ${ahuPageOn}`}>
                            
                        </div>
                    </div>
                }
            </div>
        )
    else return (<></>)
}

export default MainLeftNavbar;