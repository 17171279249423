
import { Outlet } from "react-router-dom";

import withAppLoader from "../../../hoc/withAppLoader";
import TopNavbar from "../navbar/TopNavbar";

function RootLayout () {

    return (
        <div style={{zIndex: "1", position: "relative"}}>
            <TopNavbar/>
            <Outlet/>
        </div>
    )
}

export default withAppLoader(RootLayout,{});