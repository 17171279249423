import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Badge, Button, Spinner, Form, FloatingLabel } from "react-bootstrap";
import { BsExclamationCircleFill as InfoIcon } from "react-icons/bs";

import { axiosWithXAuth } from '../../../utils/functions';
import { API_URL } from '../../../utils/variables';

export default function FcSetting(){

    const building = useSelector(root => root.data.building);

    const [oldSettings, setOldSettings] = useState({});
    const [newSettings, setNewSettings] = useState({});

    const [hasUpdated, setIsUpdated] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const [inputSatSp, setInputSatSp] = useState();
    const [inputOuttempLimit, setInputOuttempLimit] = useState();
    const [inputClLimit, setInputClLimit] = useState();
    const [inputChStartSt, setInputChStartSt] = useState();

    const [inputIsOverride, setInputIsOverride] = useState();
    const [inputOverrideVal, setInputOverrideVal] = useState();

    let sections = [
        {
            title: "Free Cooling Rules",
            params: [
                { label: 'Supply Air Temperature Setpoint (°C)', key: 'satSp', min: 10, max: 20, step: 0.1, type: 'number'},
                { label: 'Outdoor Temperature Limit (°C)', key: 'outtempLimit', min: 10, max: 20, step: 0.1, type: 'number'},
                { label: 'Cooling Load Limit (x100%)', key: 'clLimit', min: 0.1, max: 1, step: 0.1, type: 'number'},
                { label: 'Chiller Supply Temp after Free Cooling (°C)', key: 'chStartSt', min: 7, max: 9, step: 0.1, type: 'number'}
            ]
        },
        {
            title: "Manual Override",
            params: [
                { label: 'Is Manual Override?', key: 'isOverride', type: 'boolean'},
                { label: 'Free Cooling Value', key: 'overrideVal', min: 0, max: 1, step: 1, type: 'number'},
            ]
        },
    ];

    useEffect(()=>{
        if (building){
            callSettings();
        }
    }, [building])

    const callSettings = async() => {
        await axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/settingsFreeCool`,
            data: {
                "b_id": building.building_id,
            }
        })
        .then((response) => {
            let res = response.data;
            setOldSettings(res);
            setNewSettings(res);

            setInputSatSp(res.satSp);
            setInputOuttempLimit(res.outtempLimit);
            setInputClLimit(res.clLimit);
            setInputChStartSt(res.chStartSt);

            setInputIsOverride(res.isOverride);
            setInputOverrideVal(res.overrideVal);

            setIsUpdated(false);
        })
        .catch((err)=>{
     
        })
    }

    const checkHasUpdated = (new_setting) => {
        // console.log(JSON.stringify(new_setting)===JSON.stringify(oldSettings) )
        if (JSON.stringify(new_setting)===JSON.stringify(oldSettings)){
            setIsUpdated(false);
        }
        else{
            setIsUpdated(true);
        }
    }

    const onChangeSetting = (param_key, new_val, set_fn, type) => {
        let x = structuredClone(newSettings);

        if (type === "number")
            x[param_key] = parseFloat(new_val);
        else
            x[param_key] = new_val==="true"? true: false;

        setNewSettings(x);
        checkHasUpdated(x);

        set_fn(x[param_key]);
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true){
            event.preventDefault();
            event.stopPropagation();
            setIsSaveLoading(true);
            
            await axiosWithXAuth({
                method: "post",
                url: `${API_URL}/api/updateSettingsFreeCool`,
                data: {
                    "b_id": building.building_id,
                    "cfg_content": {
                        "satSp": parseFloat(inputSatSp),
                        "outtempLimit": parseFloat(inputOuttempLimit),
                        "clLimit": parseFloat(inputClLimit),
                        "chStartSt": parseFloat(inputChStartSt),
                        
                        "isOverride": inputIsOverride===true?true:false,
                        "overrideVal": parseInt(inputOverrideVal)
                    }
                }
            })
            .then((response) => {
                let res = response.data;

                form.classList.remove("was-validated");
                form.reset();
                callSettings();

                setTimeout(()=>{
                    setIsSaveLoading(false);
                }, 2000);
            })
            .catch((err) => {
                form.classList.remove("was-validated");
                form.reset();
                callSettings();
                setIsSaveLoading(false);
            })
        }
      };

    const renderInput = (param) => {
        let param_key = param.key;
        let input_label = param.label;
        let min = param.min;
        let max = param.max;
        let step = param.step;
        let type = param.type || "number";

        let param_val = null;
        let set_fn = null;

        switch (param_key) {
            case "satSp": param_val = inputSatSp; set_fn = (x) => setInputSatSp(x); break;
            case "outtempLimit": param_val = inputOuttempLimit; set_fn = (x) => setInputOuttempLimit(x); break;
            case "clLimit": param_val = inputClLimit; set_fn = (x) => setInputClLimit(x); break;
            case "chStartSt": param_val = inputChStartSt; set_fn = (x) => setInputChStartSt(x); break;

            case "isOverride": param_val = inputIsOverride; set_fn = (x) => setInputIsOverride(x); break;
            case "overrideVal": param_val = inputOverrideVal; set_fn = (x) => setInputOverrideVal(x); break;

            default: return;
        }

        if (type==="number")

            return(
                <Col xs={12} md={6} xl={4} key={`input_${param_key}`}>
                    <Form.Group controlId={`${param_key}_validationCustom`}>
                        <FloatingLabel controlId={`${param_key}_floatingInput`} label={input_label} className="mb-3" >
                            <Form.Control 
                                disabled={isSaveLoading}
                                required 
                                type="number" 
                                min={min}
                                max={max}
                                step={step}
                                onChange={(e)=>onChangeSetting(param_key, e.target.value, set_fn, "number")}
                                value={param_val || 0} />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            )
        else{
            return (
                <Col xs={12} md={6} xl={4} key={`input_${param_key}`}>
                    <Form.Group controlId={`${param_key}_validationCustom`}>
                        <FloatingLabel controlId={`${param_key}_floatingInput`} label={input_label} className="mb-3" >
                            <Form.Select onChange={(e)=>onChangeSetting(param_key, e.target.value, set_fn, "boolean")} value={param_val || false} disabled={isSaveLoading}>
                                <option value={false}>No</option>
                                <option value={true}>Yes</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            )
        }
    }

    const renderSection = (section) => {
        return (
            <div className='color_container mx-2 mb-4' key={`section_${section.title}`}>
                <div className="color_grid">
                    <div className='title'> {section.title}</div>
                </div>
                <Container className='px-2 py-2'>
                    <Row>
                    {
                        section.params.map(param =>
                            renderInput(param)
                        )
                    }
                    </Row>
                </Container>
            </div>
        )
    }

    return (
        <>
            <div className='config_header'>Free Cooling Settings</div>
            <div className='pb-3'></div>
            <Container>
                <Form validated={validated} onSubmit={handleSubmit}>
                    {
                        sections.map(section =>
                            renderSection(section)
                        )
                    }
                    <Row>
                        <Col>
                        {hasUpdated && !isSaveLoading && <Button variant="primary" type="submit">Save</Button>}
                        {!hasUpdated && !isSaveLoading && <Button variant="secondary" disabled>Save</Button>}
                        {isSaveLoading && 
                            <Button variant="secondary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span className="visually-hidden">Loading...</span>
                            </Button>
                        }
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}