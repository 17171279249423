import { dataConstant } from "../constants";

const initState = {
    building: null,
    realtimeData: {}
};

const dataReducer = (state = initState, action) => {
    switch (action.type) {
        case dataConstant.type.SET_BUILDING:
            return {
                ...state,
                building: action.building
            };
        case dataConstant.type.SET_REAL_TIME_DATA:
            return {
                ...state,
                realtimeData: action.realtimeData
            };
        default:
            return state;
    }
};

export default dataReducer;