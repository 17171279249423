import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Navbar, Nav, NavDropdown, Offcanvas} from "react-bootstrap";

import { API_URL, IS_HOSPITAL, PATH_PREFIX } from "../../../utils/variables";
import { axiosWithXAuth } from "../../../utils/functions";

import "./css.scss";

function TopNavbar() {
    const navigate = useNavigate();
    let expand = false;

    // var { building } = useSelector(
    //     ({ core, data, content }) => ({
    //         building: data.building
    //     })
    // )

    const building = useSelector(root => root.data.building);

    const [offcanvasShow, setOffcanvasShow] = useState(false);
    const [defaultAhuName, setDefaultAhuName] = useState("");

    useEffect(() => {
        if (building && building.ahu_display_names && building.ahu_display_names.length>0)
            setDefaultAhuName(building.ahu_display_names[0]);
    },[building])

    const callLogout = () => {
        axiosWithXAuth({
            method: "get",
            url: `${API_URL}/api/logout`,
            data: {}
        })
        .then((response) => {
            window.localStorage.removeItem("xauth");
            // window.location.href = PATH_PREFIX+"/login";
            navigate('/login'); 
        })
    }

    if (building)
        return (
            <Navbar key={expand} expand={expand} className="top-navbar bg-body-tertiary"  bg="dark" data-bs-theme="dark">
                <Container fluid className="mx-2" style={{"maxWidth":"100%"}}>
                    <Navbar.Brand href={`${PATH_PREFIX}/dashboard/main`} className="text-left">Optimization Dashboard</Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="px-2" onClick={()=>setOffcanvasShow(true)}/>
                    {/* <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home">Home</Nav.Link>                
                        </Nav>
                    </Navbar.Collapse> */}
                    <Offcanvas
                        show={offcanvasShow}
                        id={`offcanvasNavbar`}
                        // aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        onHide={()=>setOffcanvasShow(false)}
                        placement="end"
                        className="text-bg-dark"
                        >
                        <Offcanvas.Header>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                Optimization Dashboard
                            </Offcanvas.Title>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvasNavbar" aria-label="Close" onClick={()=>setOffcanvasShow(false)}></button>
                            <hr/>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav.Link href={`${PATH_PREFIX}/dashboard/main`}>Home</Nav.Link>
                                <Nav.Link href={`${PATH_PREFIX}/dashboard/chiller`}>Chiller</Nav.Link>
                                {   
                                    building.show_ahu_page===1 &&
                                    <Nav.Link href={`${PATH_PREFIX}/dashboard/ahu/${defaultAhuName}`}>AHU</Nav.Link>
                                }
                                {
                                    !IS_HOSPITAL && <Nav.Link href={`${PATH_PREFIX}/site/setting`}>Site Configuration</Nav.Link>
                                }
                                <Nav.Link href={`${PATH_PREFIX}/site/download`}>Download Center</Nav.Link>
                                <Nav.Link href="" onClick={() => callLogout()}>Logout</Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Container>
            </Navbar>
        )
    else
        return (<></>)
}

export default TopNavbar;