const domain = window.location.hostname;
console.log(domain)

let domain_wo_port = process.env.REACT_APP_DOMAIN_WO_PORT.split(",");
let domain_w_port = process.env.REACT_APP_DOMAIN_W_PORT.split(",");

export const PATH_PREFIX = process.env.REACT_APP_PATH_PREFIX;
export const API_URL = 
    domain_wo_port.includes(domain)? 
        "https://"+domain+PATH_PREFIX: 
    domain_w_port.includes(domain)? 
        "http://"+domain+":"+process.env.REACT_APP_API_PORT+PATH_PREFIX:
        "";
export const WS_URL = 
    domain_wo_port.includes(domain)? 
        "wss://"+domain+PATH_PREFIX+process.env.REACT_APP_WS_PATH: 
    domain_w_port.includes(domain)? 
        "ws://"+domain+":"+process.env.REACT_APP_WS_PORT+PATH_PREFIX+process.env.REACT_APP_WS_PATH:
        "";

console.log(API_URL)
console.log(WS_URL)

export const BUILDING_ID = parseInt(process.env.REACT_APP_BUILDING_ID);
export const BUILDING_CODE = process.env.REACT_APP_BUILDING_CODE.toLowerCase();
export const IS_HOSPITAL = process.env.REACT_APP_HOSPITAL === "TRUE"? true: false;
export const USE_HOSPITAL_COLOR = process.env.REACT_APP_USE_HOSPITAL_COLOR === "TRUE"? true: false;

export const HARD_CODE_DATA = {
    25: {
        chart_from_dt: '2023-09-11 00:00:00',
        chart_to_dt: '2023-09-12 23:59:59',
        pump_speed_min: 0,
        pump_speed_max: 50,
        kpi_from_dt: '2023-10-01 00:00:00',
        saving_type: "AI",  //AI or PRED
    },
    1001: {
        tt_run: '3,960',
        kpi_from_dt: '2023-03-01 00:00:00',
        chillers: ['CH1','CH2','CH3','CH4','CH5'],
        realtime: {
            ai: {
                mode: "AI mode",
                connect_status: true
            },
            chillers: {
                CH1:{
                    cool_cap: 443,
                    cool_load: 0,
                    cool_load_ratio: 0,
                    fr: 21,
                    rt: 11,
                    st: 8.18,
                    status: "inactive"
                },
                CH2:{
                    cool_cap: 443,
                    cool_load: 0,
                    cool_load_ratio: 0,
                    fr: 21,
                    rt: 11,
                    st: 8.18,
                    status: "inactive"
                },
                CH3:{
                    cool_cap: 443,
                    cool_load: 0,
                    cool_load_ratio: 0,
                    fr: 21,
                    rt: 11,
                    st: 7.0,
                    status: "inactive"
                },
                CH4:{
                    cool_cap: 443,
                    cool_load: 280,
                    cool_load_ratio: 78,
                    fr: 21,
                    rt: 11,
                    st: 8.18,
                    status: "active"
                },
                CH5:{
                    cool_cap: 443,
                    cool_load: 280,
                    cool_load_ratio: 74,
                    fr: 21,
                    rt: 11,
                    st: 8.18,
                    status: "active"
                }
            },
            pumps: {
                "CHWP1": {speed: 37,status: "active"},
                "CHWP2": {speed: 0,status: "inactive"},
                "CHWP3": {speed: 37,status: "active"},
                "CHWP4": {speed: 0,status: "inactive"},
                "CHWP5": {speed: 0,status: "inactive"},
                "CHWP6": {speed: 0,status: "inactive"},
            },
            plant:{st: 7.21, rt: 11.90},
            dt: new Date()
        },
        pumps: ['CHWP1','CHWP2','CHWP3','CHWP4','CHWP5','CHWP6'],
        pump_speed_min: 0,
        pump_speed_max: 50,
        // chart_from_dt: '2023-08-23 00:00:00',
        // chart_to_dt: '2023-08-24 23:59:59',
        chart_from_dt: '2023-05-01 00:00:00',
        saving_type: "AI",  //AI or PRED
    },
    1002: {
        tt_run: '1,550',
        kpi_from_dt: '2023-01-01 00:00:00',
        chillers: ['CH1','CH2','CH3','CH4','CH5','CH6'],
        realtime: {
            ai: {
                mode: "AI mode",
                connect_status: true
            },
            chillers: {
                CH1:{
                    cool_cap: 443,
                    cool_load: 0,
                    cool_load_ratio: 75,
                    fr: 21,
                    rt: 11,
                    st: 8.18,
                    status: "active"
                },
                CH2:{
                    cool_cap: 443,
                    cool_load: 0,
                    cool_load_ratio: 69,
                    fr: 21,
                    rt: 11,
                    st: 8.18,
                    status: "active"
                },
                CH3:{
                    cool_cap: 443,
                    cool_load: 0,
                    cool_load_ratio: 63,
                    fr: 21,
                    rt: 11,
                    st: 8.18,
                    status: "active"
                },
                CH4:{
                    cool_cap: 443,
                    cool_load: 0,
                    cool_load_ratio: 0,
                    fr: 21,
                    rt: 11,
                    st: 8.18,
                    status: "inactive"
                },
                CH5:{
                    cool_cap: 443,
                    cool_load: 280,
                    cool_load_ratio: 74,
                    fr: 21,
                    rt: 11,
                    st: 8.18,
                    status: "active"
                },
                CH6:{
                    cool_cap: 443,
                    cool_load: 280,
                    cool_load_ratio: 74,
                    fr: 21,
                    rt: 11,
                    st: 8.18,
                    status: "active"
                }
            },
            pumps: {
                "CHWP1": {speed: 0,status: "inactive"},
                "CHWP2": {speed: 0,status: "inactive"},
                "CHWP3": {speed: 37,status: "active"},
                "CHWP4": {speed: 37,status: "active"},
                "CHWP5": {speed: 37,status: "active"},
                "CHWP6": {speed: 37,status: "active"},
                "CHWP7": {speed: 37,status: "active"},
            },
            plant:{st: 8.7, rt: 12.90},
            dt: new Date()
        },
        pumps: ['CHWP1','CHWP2','CHWP3','CHWP4','CHWP5','CHWP6'],
        pump_speed_min: 0,
        pump_speed_max: 50,
        chart_from_dt: '2023-09-01 00:00:00',
        chart_to_dt: '2023-09-02 23:59:59',
        saving_type: "AI",  //AI or PRED
    }
}