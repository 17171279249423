import React, {useEffect, useCallback, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { axiosWithXAuth } from "../utils/functions";
import { SET_BUILDING, SET_REAL_TIME_DATA } from '../constants';

import { API_URL, WS_URL, BUILDING_ID, PATH_PREFIX, BUILDING_CODE } from "../utils/variables"

const withAppLoader = (AppComponent) => {

    let wsConnCount = 0;    
    let rendered = false;
    let sendNowTime = null;

    const sleep = async (seconds) => {
        await new Promise(resolve => {
            return setTimeout(resolve, seconds*1000)
        });
    };
   
    const AppView = props => {
        const dispatch = useDispatch();

        // const usePrevious = (value) => {
        //     const ref = useRef();
        //     useEffect(() => {
        //       ref.current = value;
        //     });
        //     return ref.current;
        // }
        
        // const {t, i18n} = useTranslation();
        // let { c_code, b_code, level, tray_code } = useParams();
 
        const fetchBuilding = useCallback(() => {
            return new Promise((res)=>{
                axiosWithXAuth({
                    method: "get",
                    url: `${API_URL}/api/buildings`,
                    data: {}
                })
                .then((response) => response.data)
                .then((data) => {
                    let b = data.find(item => item.building_id === BUILDING_ID);
                    dispatch({ type: SET_BUILDING, building: b });
                    res(); 
                })
            })

        }, [dispatch]);

        const connectWS = () => {

            let ws = new WebSocket(WS_URL);
            
            const getMessage = async () => {
                await sleep(3);
                const xauth = window.localStorage.getItem('xauth');
                ws.send(xauth);
            }
            getMessage();
            
            sendNowTime = setInterval(()=>{
                getMessage();
            },1000*60*1); //1-min
          
            ws.onopen = () => {
                console.log('open ws connection');
            }
            
            ws.onmessage = event => {
                var object = JSON.parse(event.data);
                if (object.ws_conn===false){
                    window.localStorage.removeItem("xauth");
                    window.location.href = PATH_PREFIX+"/login";
                }
                else{
                    dispatch({ type: SET_REAL_TIME_DATA, realtimeData: object });
                }
            }
            ws.onclose = () => {
                clearInterval(sendNowTime);
                console.log('close ws connection');
                wsConnCount = wsConnCount-1;
                // console.log("wsConnCount=",wsConnCount);
                connectWS();
                wsConnCount = wsConnCount+1;

            }
        }

        const callRealTimePlant = async (b_code) => {
            await axiosWithXAuth({
                method: "post",
                url: `${API_URL}/api/realTimePlant`,
                data: {
                    b_code: b_code
                }
            })
            .then((response) => {
                let rtd = response.data;
                dispatch({ type: SET_REAL_TIME_DATA, realtimeData: rtd });
            })
        }

        const fetchData = useCallback(() => {
            fetchBuilding()
            .then(()=>{
                callRealTimePlant(BUILDING_CODE);
                sendNowTime = setInterval(()=>{
                    callRealTimePlant(BUILDING_CODE);
                },1000*60*1);
            })
        }, [fetchBuilding]);        

        useEffect(() => {
            window.onpopstate = function(event) {
                if(event && event.state) {
                    console.log("back: "+ event.state);
                    // c_code = event.state.hasOwnProperty('c_code')?event.state.c_code:undefined;
                    // b_code = event.state.hasOwnProperty('b_code')?event.state.b_code:undefined;
                    // level = event.state.hasOwnProperty('level')?event.state.level:undefined;
                    fetchData();
                }
            }
            if (!rendered){
                fetchData();
            }
            rendered = true;
            // if (wsConnCount===0 && DATA_REALTIME_HARDCODE===false){
            //     wsConnCount = wsConnCount+1;
            //     connectWS();
            // }
            return () => {
                clearInterval(sendNowTime);
            }
        }, []);

        return (
            <AppComponent  {...props}/>
        )
    }

    return AppView
}

export default withAppLoader