import axios from 'axios';
import React from 'react';
import { CiCircleMore as MoreIcon } from "react-icons/ci";
import { Container, Row, Col, Placeholder} from "react-bootstrap";

import { PATH_PREFIX } from "./variables";

export const axiosWithXAuth = (() => {
  const checkExistXAuth = () => {
    const xauth = window.localStorage.getItem('xauth');
    if (typeof xauth === 'undefined' || xauth === null) {
      return false;
    }
    return true;
  }

  const main = (config) => {
    if (checkExistXAuth()) {
      if (typeof config.headers === 'undefined') {
        config.headers = {};
      }

      const xauth = window.localStorage.getItem('xauth');
      config.headers.xauth = xauth;

      return new Promise((resolve, reject) => {
        axios(config).then((response) => {
          if (typeof response.headers.xauth !== 'undefined') {
            window.localStorage.setItem("xauth", response.headers.xauth);
          }
          return resolve(response);
        }).catch((error) => {
          if (typeof error.response !== 'undefined'
            && typeof error.response.status !== 'undefined'
            && error.response.status === 403) {
              setTimeout(function () {
                window.location.href = PATH_PREFIX+"/login";
              }, 2000);
              
          }
          reject(error);
        });
      });
    }
    else{
      return new Promise((resolve, reject) => {
        reject("Token verification fail");
        setTimeout(function () {
          if (window.location.href.indexOf("admin") > -1)
            window.location.href = PATH_PREFIX+"/adminlogin";
          else
            window.location.href = PATH_PREFIX+"/login";
        }, 1000);
      });
    }
  }

  const get = (...params) => {
    let config = {};
    if (params.length >= 1) {
      config.url = params[0];
    }
    if (params.length >= 2) {
      config = { ...config, ...params[1] };
    }
    return main(config);
  }
  const post = (...params) => {
    let config = {};
    if (params.length >= 1) {
      config.url = params[0];
    }
    if (params.length >= 2) {
      config = { ...config, ...params[1] };
    }
    return main(config);
  }

  main.get = get;
  main.post = post;

  return main;

})();

export const numberWithCommas = (x, decimal) => {
  // var value = value > 1000 ?x.toFixed(1):x.toString()

  // var value = decimal ? x.toFixed(decimal) : x.toString()
  ///return Number.parseFloat(x).toFixed(0);
  var temp_value =
    x > 1000 || x < -1000
      ? Number.parseFloat(x).toFixed(0).toString()
      : Number.parseFloat(x).toFixed(1).toString();
  if (typeof decimal == "number") {
    temp_value = Number.parseFloat(x).toFixed(decimal).toString();
  }

  if (x === 0) {
    return 0;
  }

  if (x < 2 && x > -2) {
    temp_value = Number.parseFloat(x).toFixed(2);
  }

  if (Number.isInteger(x) && (x < 1000 && x > -1000)) {
    return x;
  }
  
  return temp_value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const CustomToggleMore = React.forwardRef(({ children, onClick }, ref) => (
  <MoreIcon 
      // ref={ref} 
      onClick={(e) => {
          e.preventDefault();
          onClick(e);
          }} 
      size='25'
      style={{"cursor": "pointer"}}
  />
));

export const RenderHide = (name, render_num, col_width) => {

  const rendreRow = (i) => {
    return (
      <Row key={`${name}_${i}`} className="px-2 my-3" style={{ "width": "100%" }}>
          <Placeholder animation="glow">
              <Placeholder xs={col_width} />
          </Placeholder>
      </Row>
    )
  }

  const renderWrap = () => {
    let arr = Array.from(Array(render_num).keys());
    return arr.map((i)=>{
      return rendreRow(i);
    })
  }

  return (
      <Container>
        {
          renderWrap()  
        }
      </Container>
  )
}