import { BrowserRouter, Routes, Route, Outlet, Navigate, createBrowserRouter, RouterProvider } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css";

import { USE_HOSPITAL_COLOR, PATH_PREFIX } from "./utils/variables";
import Home from "./view/components/Home";
import Login from "./view/login/LoginForm";
import NoPage from "./view/nopage/index";
import RootLayout from "./view/components/contentWrap/RootLayout";
import DashboardLayout from "./view/components/contentWrap/DashboardLayout";
import DashboardMain from "./view/dashboard/pageMain/DashboardMain";
import DashboardChiller from "./view/dashboard/pageChiller/DashboardChiller";
import DashboardAhu from "./view/dashboard/pageAhu/DashboardAhu";
import SiteSettingMain from "./view/siteSetting/SiteSettingMain";
import DownloadMain from "./view/download/DownloadMain";
import LoginAdmin from "./view/login/LoginFormAdmin";
import AdminMain from "./view/admin/Main";
import AdminLayout from "./view/components/contentWrap/AdminLayout";

function App() {

  const RenderContent = () => {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename={PATH_PREFIX}>
            <Routes>
              <Route path={`/login`} element={<Login />} />
              <Route path={`/adminlogin`} element={<LoginAdmin />} />

              <Route element={<DashboardLayout/>} path='/dashboard'>
                  <Route path='main' element={<DashboardMain />} />
                  <Route path='chiller' element={<DashboardChiller />} />
                  <Route path='ahu/:ahuName' element={<DashboardAhu />} />
                  <Route path='*' element={<Navigate to="/dashboard/main" />} />
              </Route>

              <Route element={<RootLayout/>} path='/site'>
                  <Route path={`setting`} element={<SiteSettingMain/>} />
                  <Route path={`download`} element={<DownloadMain/>} />
                  <Route path="*" element={<Navigate to="/login" />} />
              </Route>

              <Route element={<AdminLayout/>} path='/admin'>
                  <Route path={`main`} element={<AdminMain/>} />
                  <Route path="*" element={<Navigate to="/adminlogin" />} />
              </Route>

              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </BrowserRouter>
          <Row style={{position: "fixed", bottom: "0", color: "white", width: "100%", zIndex: "0"}}>
            <Col xs={12} sm={12} md={12} className="mx-auto mt-4">
                Copyright © 2024 EMSD All rights reserved
            </Col>
          </Row>
        </PersistGate>
      </Provider>
    )
  }
  
  if (USE_HOSPITAL_COLOR)
    return (
      <div className="App app_hospital">
        {RenderContent()}
    </div>
    );
  else
    return (
      <div className="App">
        {RenderContent()}
    </div>
    );
}

export default App;
