import { useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Badge } from "react-bootstrap";
import { PAGES, SET_PAGE } from "../../constants";

export default function AdminCategory(){

    const dispatch = useDispatch();

    const categories = [
        { btn_name: "Chiller Operation", page_name: PAGES.ADMIN_OPER }, 
        { btn_name: "Plant Configuration", page_name: PAGES.ADMIN_CH }, 
        { btn_name: "Free Cooling Rules", page_name: PAGES.ADMIN_FC },
        { btn_name: "AHU Configuration", page_name: PAGES.ADMIN_AHU }
    ]
    const [currentCate, setCurrentCate] = useState(undefined);

    const onClickCate = (page_name) => {
        setCurrentCate(page_name);
        dispatch({ type: SET_PAGE, page: page_name});
    }

    const renderCategory = (cate) => {
        let on_select = cate.page_name === currentCate? 'on_select':'';
        return (
            <Badge 
                className={`my_cate_btn ${on_select}`}
                key={`btn_${cate.btn_name}`} 
                pill 
                onClick={()=>onClickCate(cate.page_name)}>
                {cate.btn_name}
            </Badge>
        )
    }

    return (
        <Container className="info_wrap">
            <div className="info_main_text text-center">
                Choose a category
            </div>
            <div className="mt-2">
                {
                    categories.map(cate => {
                        return renderCategory(cate);
                    })
                }
            </div>
        </Container>
    )
}