import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Badge } from "react-bootstrap";

import { PAGES } from '../../constants';

import OperationSetting from './operation/OperationSetting';
import PlantSetting from './plant/PlantSetting';
import FcSetting from './freecool/FcSetting';
import AhuSetting from './ahu/AhuSetting';

export default function SubPage(){
    const currentPage = useSelector(root => root.core.page);

    return (
        <div className='sub_page'>
            {currentPage===PAGES.ADMIN_OPER && <OperationSetting/>}
            {currentPage===PAGES.ADMIN_CH && <PlantSetting/>}
            {currentPage===PAGES.ADMIN_FC && <FcSetting/>}
            {currentPage===PAGES.ADMIN_AHU && <AhuSetting/>}
        </div>
    )
}