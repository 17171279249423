export const SET_BUILDING = "SET_BUILDING"
export const SET_REAL_TIME_DATA = "SET_REAL_TIME_DATA"
export const SET_PAGE = "SET_PAGE"
export const SET_DT_RANGE = "SET_DT_RANGE"
export const SET_AHU = "SET_AHU"

export const dataConstant = {
    type: {
        SET_BUILDING: "SET_BUILDING",
        SET_REAL_TIME_DATA: "SET_REAL_TIME_DATA",
        SET_PAGE: "SET_PAGE",
        SET_DT_RANGE: "SET_DT_RANGE",
        SET_AHU: "SET_AHU"
    }
};