import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Modal, Button, Table } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";

import { API_URL } from  "../../../utils/variables";
import { axiosWithXAuth } from "../../../utils/functions";
import PaginationComponent from '../../components/pagination/Pagination';

const  AlarmRecord = ({
    showModal = false,
    setShowModal,
    platform = "web",
    recordnum = 10
}) => {

    const handleClose = () => setShowModal(false);

    // var { building, chillerList, rtData } = useSelector(
    //     ({ core, data, content }) => ({
    //         building: data.building,
    //         chillerList: data.chillerList,
    //         rtData: data.realtimeData
    //     })
    // )
    const building = useSelector(root => root.data.building);
    const rtData = useSelector(root => root.data.realtimeData);

    const [alarmRecords, setAlarmRecords] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = recordnum;

    useEffect(() => {
        if (showModal){
            setPage(1);
            callAlarmRecord();
        }
    }, [showModal, building])

    useEffect(() => {
        if (page<1)
            setPage(1);
    }, [page])

    useEffect(() => {
        if (building){
            let b_code = building.building_ibms_id.toLowerCase();
            if (rtData.hasOwnProperty(b_code))
                updateAlarm(rtData[b_code]);
        }
    }, [rtData,building]);

    const callAlarmRecord = () => {
        
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/alarms`,
            data: {b_id: building.building_id}
        })
            .then((response) => {
                let data = response.data;
                let result = []
                data.forEach((element, index) => {
                    result.push({
                        "cdt": element.cdt,
                        "alarm_type": element.type,
                    })
                })
                setAlarmRecords(result);
            })
    }

    const updateAlarm = (th_rtData) => {
        if (th_rtData.alarms===undefined || th_rtData.alarms.length<=0) return false;

        let x = th_rtData.alarms[0];
        let is_included = alarmRecords.find(item => item.cdt === x.cdt);

        if (is_included !== undefined) return false;

        let new_add = {
            "cdt": x.cdt,
            "alarm_type": x.type,
        }

        let new_array = [new_add].concat(alarmRecords);
        setAlarmRecords(new_array);
    }

    const renderRecord = (record, index) => {
        let cdt = new Date(record.cdt);
        // cdt = cdt.setHours(cdt.getHours() - 8);
        let record_dt = moment(cdt).format("YYYY-MM-DD h:mm a");
        let message = "";
        let category = "";
        if (record.alarm_type === 1){
            category = "Alert";
            message = "Last optimized control suggestion was over 20 minutes ago. AI system is disconnected.";
        }
        let item_num = (page-1) * itemsPerPage + index + 1;
        return (
            <tr key={`alm_item_${index}`}>
                <td>{item_num}</td>
                <td>{record_dt}</td>
                <td>{category}</td>
                <td>{message}</td>
            </tr>
        )
    }

    const renderRecordWrap = () => {
        if (alarmRecords.length > 0){

            let from_item = page===1? 1: (page-1) * itemsPerPage + 1;
            let to_item = from_item + itemsPerPage - 1;
            let alarms_in_page = alarmRecords.filter(
                (element, index) => { 
                    return index+1 >= from_item && index+1 <= to_item
                });

            return alarms_in_page.map((element, index) => {
                return renderRecord(element, index);
            })
        }
    }

    const renderContent = () =>{
        let smallfont = platform==="web"?"":"small_font";
        return (
            <Container className='py-1' id='alarm_table'>
                <Table striped border="true" hover responsive className={`${smallfont}`}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Datetime</th>
                            <th>Category</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRecordWrap()}
                    </tbody>
                </Table>
                <div className="myPagination myPag-b d-flex justify-content-center">
                    {
                        <PaginationComponent
                            itemsCount={alarmRecords.length}
                            itemsPerPage={itemsPerPage}
                            currentPage={page}
                            setCurrentPage={setPage}
                            alwaysShown={false}
                        />
                    }
                </div>
            </Container>
        )
    }

    return (
        <Modal size="xl" fullscreen="lg-down" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Alarm Record History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderContent()}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

AlarmRecord.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
};

export default AlarmRecord;